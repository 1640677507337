import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Filter } from '../components/calendar/Filter';
import { EquipmentsDays } from '../components/calendar/EquipmentsDays';
import { EquipmentsWeeks } from '../components/calendar/EquipmentsWeeks';
import { EquipmentsYear } from '../components/calendar/EquipmentsYear';
import { ApartmentsDays } from '../components/calendar/ApartmentsDays';
import { ApartmentsWeeks } from '../components/calendar/ApartmentsWeeks';
import { ApartmentsYear } from '../components/calendar/ApartmentsYear';
import { Detail } from '../components/calendar/Detail';
import { ContentContainer } from '../components/common/ContentContainer';
import { SubHeader } from '../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../components/common/subheader/SubHeaderLeft';
import { t } from 'i18next';
import { book } from '../navigation/book';
import { Title } from '../components/common/subheader/Title';

export const CalendarPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get('type');
    const link = searchParams.get('link');

    useEffect(() => {
        if (!link || !type) {
            setSearchParams('?link=apartments&type=days');
        }
    }, [type, link]);

    let tableJSX;
    if (link === 'equipments' && type === 'days') {
        tableJSX = <EquipmentsDays />;
    }
    if (link === 'equipments' && type === 'weeks') {
        tableJSX = <EquipmentsWeeks />;
    }
    if (link === 'equipments' && type === 'year') {
        tableJSX = <EquipmentsYear />;
    }
    if (link === 'apartments' && type === 'days') {
        tableJSX = <ApartmentsDays />;
    }
    if (link === 'apartments' && type === 'weeks') {
        tableJSX = <ApartmentsWeeks />;
    }
    if (link === 'apartments' && type === 'year') {
        tableJSX = <ApartmentsYear />;
    }

    if (!type || !link) {
        return null;
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Title>{t(book.calendar.key)}</Title>
                </SubHeaderLeft>
            </SubHeader>
            <ContentContainer>
                <div className="flex flex-col w-full">
                    <Filter link={link} type={type} />
                    {tableJSX}
                    <Detail />
                </div>
            </ContentContainer>
        </>
    );
};
