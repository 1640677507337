import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Filter } from '../components/applications/Filter';
import { Table } from '../components/applications/Table';
import { Detail } from '../components/applications/Detail';
import { AdditionalFilter } from '../components/applications/AdditionalFilter';
import { clear_filter, set_application, applicationsFetch, sort as sort_state, filter as filter_state } from '../redux/slices/applicationsSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../redux/slices/loginSlice';

import { book } from '../navigation/book';

export const ApplicationsPage = () => {
    const [clearFilter, setClearFilter] = useState(false);

    const firstRender = useRef(true);

    const dispatch = useDispatch();

    const [cookie] = useCookies();
    const lang = cookie.i18next;

    const location = useLocation();
    const sort = useSelector(sort_state);
    const filter = useSelector(filter_state);
    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);

    useEffect(() => {
        if (location.pathname.includes(book.applications.link)) {
            dispatch(set_application(null));
        }
    }, []);

    useEffect(() => {
        if (!clearFilter) {
            // dispatch(clear_filter());
            dispatch(applicationsFetch({ username, auth_key_calendar, filter, sort, lang }));
            setClearFilter(true);
        }
    }, [dispatch, clearFilter, filter]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        dispatch(applicationsFetch({ username, auth_key_calendar, filter, sort, lang }));
    }, [filter]);

    return (
        <>
            <Filter />

            <Table clearFilter={clearFilter} />

            <Detail />

            <AdditionalFilter />
        </>
    );
};
