import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    bms_servers_list as bms_servers_list_state,
    bms_venues_list as bms_venues_list_state,
    getBMSServersList,
    getBMSUserVenuesList,
    venues_fetching as venues_fetching_state,
    clear_system_list,
    bms_servers_state as bms_servers_state_state,
} from '../../../redux/slices/bmsSlice';
import { useCheckRole } from '../../../hooks/useCheckRole';

import { Loader } from '../../common/Loader';

export const VenueList = ({ setVenueId, setIsOpen }) => {
    const dispatch = useDispatch();

    const bms_venues_list = useSelector(bms_venues_list_state);
    const venues_fetching = useSelector(venues_fetching_state);
    const bms_servers_list = useSelector(bms_servers_list_state);
    const bms_servers_state = useSelector(bms_servers_state_state);

    const isObjectEdit = useCheckRole('bms', 'bms.object.view');

    useEffect(() => {
        dispatch(clear_system_list());
        if (bms_venues_list.length === 0) {
            dispatch(getBMSUserVenuesList());
        }
    }, [bms_venues_list.length, dispatch]);

    useEffect(() => {
        if (bms_servers_list.length === 0) {
            dispatch(getBMSServersList());
        }
    }, [bms_servers_list.length, dispatch]);

    const handlerVenueEditClick = id => {
        setVenueId(id);
        setIsOpen(true);
    };

    const getVenueColor = id => {
        // 1 - DISABLED
        // 2 - ENABLED
        // 3 - CONNECTION ERROR
        // 4 - CONNECTED
        // 5 - HAVE ALERTS
        let statusVenue = [];
        for (let i = 0; i < bms_servers_state.length; i++) {
            const statusServer = bms_servers_state[i].cur_state;
            const objectList = bms_servers_state[i].objects_list;
            objectList.map(obj => (obj.id === id ? statusVenue.push(statusServer) : obj));
        }

        if (statusVenue.includes(3)) {
            return 'bg-gray-400 text-white hover:bg-gray-300 hover:border-gray-600';
        } else if (statusVenue.includes(5)) {
            return 'bg-red-600 text-white hover:bg-red-400 hover:border-red-600';
        } else if (statusVenue.includes(4) || statusVenue.includes(2)) {
            return 'bg-green-600 text-white hover:bg-green-400 hover:border-green-600';
        } else {
            return 'bg-white border-gray-10 hover:border-blue hover:bg-blue-10';
        }
    };

    if (venues_fetching) {
        return (
            <div className="flex items-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            {bms_venues_list.length !== 0 ? (
                bms_venues_list.map(el => (
                    <div className="relative" key={`bms${el.id}`}>
                        <Link
                            to={`/bms/venues/${el.id}/systems`}
                            className={`${getVenueColor(el.id)} flex flex-col justify-center items-center overflow-hidden w-52 h-28 p-2 border-2 rounded-md`}
                        >
                            {el.title && <div className="uppercase text-sm font-bold text-center line-clamp-2">{el.title}</div>}
                        </Link>

                        {isObjectEdit && (
                            <button className="absolute top-2 right-2 cursor-pointer" onClick={() => handlerVenueEditClick(el.id)}>
                                <svg width="18" height="18" viewBox="0 0 18 18" className=" hover:fill-red-400 fill-current">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M15.75 15C16.1642 15 16.5 15.3358 16.5 15.75C16.5 16.1642 16.1642 16.5 15.75 16.5H2.25C1.83579 16.5 1.5 16.1642 1.5 15.75C1.5 15.3358 1.83579 15 2.25 15H15.75ZM4.71967 9.96967L12.9697 1.71967C13.24 1.44931 13.6655 1.42851 13.9597 1.65728L14.0303 1.71967L16.2803 3.96967C16.5507 4.24003 16.5715 4.66546 16.3427 4.95967L16.2803 5.03033L8.03033 13.2803C7.91312 13.3975 7.76111 13.4719 7.59858 13.4935L7.5 13.5H5.25C4.86537 13.5 4.54837 13.2105 4.50505 12.8375L4.5 12.75V10.5C4.5 10.3342 4.55487 10.1741 4.65456 10.044L4.71967 9.96967L12.9697 1.71967L4.71967 9.96967ZM13.5 3.31067L6 10.8107V12H7.18934L14.6893 4.50001L13.5 3.31067Z"
                                    />
                                </svg>
                            </button>
                        )}
                    </div>
                ))
            ) : (
                <div className="flex flex-col justify-center items-center overflow-hidden w-64 h-28 p-2">Нет доступных объектов</div>
            )}
        </>
    );
};
