import { ButtonRectangular } from '../../../common/btn/ButtonRectangular';

export const EditServerPopupButton = ({ disabled, onClick }) => {
    return (
        <ButtonRectangular className="w-40 mt-8 justify-center" disabled={disabled} onClick={onClick}>
            <svg width="20" height="20" viewBox="0 0 20 20" className="fill-current">
                <path d="M7.95825 15.0001L3.20825 10.2501L4.39575 9.06258L7.95825 12.6251L15.6041 4.97925L16.7916 6.16675L7.95825 15.0001Z" />
            </svg>
            Сохранить
        </ButtonRectangular>
    );
};
