import { ButtonRectangular } from '../../common/btn/ButtonRectangular';

export const CreateTaskPaneButton = ({ setTaskPaneIsOpen }) => {
    return (
        <ButtonRectangular onClick={() => setTaskPaneIsOpen(true)} className="bg-blue border-blue hover:bg-blue-700">
            <svg width="10" height="10" viewBox="0 0 10 10" className="fill-current">
                <path d="M4.45825 9.79179V5.54179H0.208252V4.4585H4.45825V0.208496H5.54154V4.4585H9.79154V5.54179H5.54154V9.79179H4.45825Z" />
            </svg>
            Создать задачу
        </ButtonRectangular>
    );
};
