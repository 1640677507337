import { useDispatch, useSelector } from 'react-redux';

import { useCheckRole } from '../../../../../hooks/useCheckRole';
import { removeBMSDocNode, update_bms_document_node, documentState as documentState_state } from '../../../../../redux/slices/bmsSlice';

import { PopupContextMenu } from '../../../../common/popup/PopupContextMenu';

export const EditNodeContextMenu = ({ isOpen, setIsOpen, nodeId, buttonRef, node, editNodeHandler }) => {
    const dispatch = useDispatch();
    const documentState = useSelector(documentState_state);

    const isDocumentNodeEdit = useCheckRole('bms', 'bms.document_nodes.edit');
    const isDocumentNodeDelete = useCheckRole('bms', 'bms.document_nodes.del');

    const handlerTopLayout = () => {
        const maxZIdx = documentState.nodes.reduce((acc, cur) => (acc > cur.position?.zIndex ? acc : cur.position.zIndex), 0);
        dispatch(update_bms_document_node({ ...node, position: { ...node.position, zIndex: maxZIdx ? maxZIdx + 1 : 1 } }));

        setIsOpen(false);
    };

    const handlerBottomLayout = () => {
        const minZIdx = documentState.nodes.reduce((acc, cur) => (acc < cur.position?.zIndex ? acc : cur.position.zIndex), 0);
        dispatch(update_bms_document_node({ ...node, position: { ...node.position, zIndex: minZIdx ? minZIdx - 1 : -1 } }));

        setIsOpen(false);
    };

    const handlerEditNode = () => {
        setIsOpen(false);
        editNodeHandler(node);
    };

    const handlerRemoveNode = () => {
        dispatch(removeBMSDocNode({ cn_id: nodeId }));
    };

    return (
        <PopupContextMenu className="w-auto" isOpen={isOpen} setIsOpen={setIsOpen} buttonRef={buttonRef}>
            {isDocumentNodeEdit && (
                <>
                    <div className=" border-b w-48">
                        <button onClick={handlerTopLayout} className="w-full text-left text-xs mb-1 cursor-pointer hover:bg-slate-200 pl-2 p-1 rounded-md">
                            <span>Поместить на передний план</span>
                        </button>
                        <button onClick={handlerBottomLayout} className="w-full text-left text-xs mb-1 cursor-pointer hover:bg-slate-200 pl-2 p-1 rounded-md">
                            <span>Поместить на задний план</span>
                        </button>
                    </div>
                    <button onClick={handlerEditNode} className="w-full text-left text-xs cursor-pointer  hover:bg-slate-200 pl-2 p-1 mt-1 rounded-md">
                        <span>Редактировать</span>
                    </button>
                </>
            )}

            {isDocumentNodeDelete && (
                <button onClick={handlerRemoveNode} className="w-full text-left text-xs cursor-pointer hover:bg-slate-200 pl-2 p-1 rounded-md">
                    <span>Удалить</span>
                </button>
            )}
        </PopupContextMenu>
    );
};
