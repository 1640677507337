import { Link } from 'react-router-dom';

export const BackButton = ({ link }) => {
    return (
        <Link to={link} className="cursor-pointer">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-4">
                <path d="M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z" fill="#1890FF" />
            </svg>
        </Link>
    );
};
