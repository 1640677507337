import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clear_types as clear_types_state, clear_types_fetching as clear_types_fetching_state, getClearTypes } from '../../../redux/slices/hospitalitySlice';
import { MultiSelect } from '../../common/widgets/MultiSelect';

export const ClearTypesFilter = ({ setFilter, value }) => {
    const dispatch = useDispatch();

    const clear_types = useSelector(clear_types_state);
    const clear_types_fetching = useSelector(clear_types_fetching_state);

    const [clearTypes, setClearTypes] = useState([]);

    useEffect(() => {
        if (!clear_types.length) {
            dispatch(getClearTypes());
        }
    }, [dispatch]);

    useEffect(() => {
        if (clear_types && clear_types.length) {
            setClearTypes(
                clear_types.map(status => ({
                    value: status.id,
                    label: status.name,
                }))
            );
        }
    }, [clear_types]);

    return (
        <MultiSelect
            options={clearTypes}
            placeholder="Вид уборки"
            isLoading={clear_types_fetching}
            isDisabled={clear_types_fetching}
            callback={val => setFilter({ type: 'clearTypes', val })}
            value={value}
        />
    );
};
