import { t } from 'i18next';
import { useParams } from 'react-router-dom';

import { book } from '../../navigation/book';
import { useCheckRole } from '../../hooks/useCheckRole';

import { SubHeader } from '../../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../../components/common/subheader/SubHeaderLeft';
import { ContentContainer } from '../../components/common/ContentContainer';
import { Title } from '../../components/common/subheader/Title';
import { VenueName } from '../../components/bms/systems/VenueName';
import { SystemName } from '../../components/bms/systems/SystemName';
import { DocumentCreate } from '../../components/bms/document/create/DocumentCreate';
import { NoAccessPage } from '../../components/common/noAccess/NoAccessPage';
import { BackButton } from '../../components/common/subheader/BackButton';

export const BMSCreateDocPage = () => {
    const { venueId, systemId } = useParams();
    const isDocumentAdd = useCheckRole('bms', 'bms.document.add');

    if (!isDocumentAdd) {
        return <NoAccessPage />;
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <BackButton link={`/bms/venues/${venueId}/system/${systemId}/documents`} />
                    <Title>{t(book.bms_create_doc.key)}</Title>
                </SubHeaderLeft>
            </SubHeader>
            <ContentContainer>
                <VenueName />
                <SystemName />
                <DocumentCreate />
            </ContentContainer>
        </>
    );
};
