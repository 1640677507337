import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useCookies } from 'react-cookie';
import { t } from 'i18next';

import {
    data as data_state,
    dataFetch,
    fetching as fetching_state,
    filter as filter_state,
    set_detail_open,
    task as task_state,
    taskFetch,
} from '../../redux/slices/calendarSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { Loader } from '../common/Loader';

export const EquipmentsWeeks = () => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const filter = useSelector(filter_state);
    const data = useSelector(data_state);
    const fetching = useSelector(fetching_state);
    const task_detail = useSelector(task_state);
    const [cookies, setCookie] = useCookies();

    const lang = cookies.i18next;

    useEffect(() => {
        if (username && auth_key_calendar && !fetching) {
            dispatch(dataFetch({ username, auth_key_calendar, filter: { ...filter, month: null, task_category_id: 9, calendar_type: 3 }, lang }));
        }
    }, [dispatch, username, auth_key_calendar, filter, lang]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [fetching]);

    const openDetail = async (target, task) => {
        const params = {
            detail_id: task.detail_id,
            date_work: null,
            start_week: task.virtual ? task.start_week : null,
            filter_year: task.virtual ? +task.year : null,
            hash: task.hash,
            target,
        };

        await dispatch(taskFetch({ username, auth_key_calendar, params, lang }));
        await dispatch(set_detail_open(true));
    };

    //weeks
    const getDateOfWeek = (w, y) => {
        const simple = new Date(Date.UTC(y, 0, 1 + (w - 1) * 7));
        const dow = simple.getUTCDay();
        const weekStart = simple;
        if (dow <= 4) {
            weekStart.setUTCDate(simple.getUTCDate() - simple.getUTCDay() + 1);
        } else {
            weekStart.setUTCDate(simple.getUTCDate() + 8 - simple.getUTCDay());
        }

        const endOfWeek = new Date(weekStart.getTime());
        endOfWeek.setUTCDate(endOfWeek.getUTCDate() + 6); // six days
        endOfWeek.setUTCHours(23);
        endOfWeek.setUTCMinutes(59);
        endOfWeek.setUTCSeconds(59);

        return weekStart.toLocaleDateString('ru-RU') + ' - ' + endOfWeek.toLocaleDateString('ru-RU');
    };

    const width_full = data && data.week_in_year_count ? `${16 + 4 * data.week_in_year_count + 0.125}rem` : `100%`;
    const width_right = data && data.week_in_year_count ? `${4 * data.week_in_year_count}rem` : `100%`;

    return (
        <>
            <div className="w-full overflow-x-auto">
                <div className="flex flex-col rounded-lg bg-white border border-gray-10" style={{ width: width_full }}>
                    {/*first row*/}
                    <div className="w-full flex flex-row">
                        <div className="w-64 flex-shrink-0 sticky py-3 left-0 bg-white border-r border-r-gray border-b border-b-gray-10 rounded-tl-lg flex items-center justify-center">
                            <div className="flex items-center justify-center rounded-lg p-0.5 bg-gray-100">
                                <Link className="text-sm font-normal px-2 py-1 rounded-lg hover:text-gray-20" to={`?link=apartments&type=weeks`}>
                                    {t('CALENDAR_ROOM')}
                                </Link>
                                <Link className="text-sm font-normal px-2 py-1 rounded-lg bg-blue text-white pointer-events-none" to="#">
                                    {t('CALENDAR_EQUIPMENT')}
                                </Link>
                            </div>
                        </div>
                        <div className="flex flex-row border-b border-b-gray-10" style={{ width: width_right }}>
                            {data &&
                                data.week_in_year_count &&
                                [...Array(data.week_in_year_count)].map((item, index) => (
                                    <div
                                        data-tip={getDateOfWeek(index + 1, filter.year)}
                                        data-place="bottom"
                                        data-type="info"
                                        className="w-16 flex flex-col items-center justify-center border-r border-r-gray-10 last:border-r-0 flex-shrink-0"
                                        key={`${index + 1}week`}
                                    >
                                        <span>{index + 1}</span>
                                        <span>{t('CALENDAR_DETAILS_WEEK_SHORT')}</span>
                                    </div>
                                ))}
                        </div>
                    </div>

                    {/*calendar*/}
                    <div className="w-full">
                        {(!data || (!filter.venue && !filter.building)) && !fetching && (
                            <p className="text-sm flex w-full h-20 px-4 items-center">{t('CALENDAR_GET_STARTED')}</p>
                        )}

                        {fetching && (
                            <div className="w-screen h-20 flex items-center justify-center">
                                <Loader />
                            </div>
                        )}

                        {data && !data.result.length && !fetching && (
                            <p className="text-sm flex w-full h-20 px-4 items-center">{t('CALENDAR_NOTHING_FOR_REQUEST')}</p>
                        )}

                        {data &&
                            data.result &&
                            !fetching &&
                            filter.venue &&
                            filter.building &&
                            data.result.map(direction => (
                                <React.Fragment key={`${direction.item}${direction.id}`}>
                                    <div className="w-full flex flex-row">
                                        <div
                                            className="w-64 flex-shrink-0 sticky left-0 bg-gray-100 flex items-center border-r border-r-gray border-b border-b-gray-10 p-4"
                                            data-tip={direction.item}
                                        >
                                            <span className="text-sm line-clamp-2">{direction.item}</span>
                                        </div>
                                        <div className="flex flex-row border-b border-b-gray-10 bg-gray-100" style={{ width: width_right }}></div>
                                    </div>

                                    {direction.device &&
                                        direction.device.map(device => (
                                            <React.Fragment key={`${device.item}${device.id}`}>
                                                <div className="w-full flex flex-row">
                                                    <div
                                                        className="w-64 flex-shrink-0 sticky z-10 left-0 bg-white flex items-center border-r border-r-gray border-b border-b-gray-10 p-4"
                                                        data-tip={device.item}
                                                    >
                                                        <span className="text-sm line-clamp-2">{device.item}</span>
                                                    </div>
                                                    <div className="flex flex-row border-b border-b-gray-10 z-[9]" style={{ width: width_right }}>
                                                        {device.tasks_formatted &&
                                                            device.tasks_formatted.map((tasks, index) => (
                                                                <div
                                                                    className="flex flex-col items-center justify-center flex-grow basis-0 border-r border-r-gray-10 last:border-r-0"
                                                                    key={`${device.item}${device.id}${index}`}
                                                                >
                                                                    {tasks.map((task, index) => (
                                                                        <div
                                                                            className={`w-full flex-grow basis-0 flex items-center py-1 justify-center select-none transition ${
                                                                                task.detail_id ? 'cursor-pointer hover:bg-gray-10' : ''
                                                                            } relative z-10 text-xs border-b border-b-gray-10 last:border-b-0 ${
                                                                                !task.work_active && task_detail.hash !== task.hash ? 'bg-gray-40' : ''
                                                                            } ${task.status_id === 5 && task_detail.hash !== task.hash ? 'bg-green-300' : ''} ${
                                                                                task.overdue
                                                                                    ? 'after:content-[""] after:bg-red-600 after:w-1.5 after:h-1.5 after:rounded-full after:absolute after:top-[calc(50%+0.4rem)]'
                                                                                    : ''
                                                                            } ${
                                                                                task_detail.hash === task.hash
                                                                                    ? 'bg-blue text-white hover:bg-blue hover:cursor-pointer'
                                                                                    : ''
                                                                            }`}
                                                                            key={task.hash || `no_item${device.id}${index}`}
                                                                            onClick={e => openDetail(e.target, task)}
                                                                            data-tip={`${task.start_week} ${t('CALENDAR_DETAILS_WEEK_SHORT')}. (${getDateOfWeek(
                                                                                task.start_week,
                                                                                filter.year
                                                                            )})`}
                                                                            data-place="top"
                                                                            data-type="info"
                                                                            ref={task_detail.hash === task.hash ? ref : null}
                                                                        >
                                                                            {task.period_work_to_name || task.period_type_short_name}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            ))}
                    </div>
                </div>
                <ReactTooltip effect="solid" place="right" className="max-w-prose" />
            </div>
        </>
    );
};
