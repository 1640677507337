import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Popup } from '../../../../common/popup/Popup';
import { PopupTitle } from './PopupTitle';
import { ButtonRectangular } from '../../../../common/btn/ButtonRectangular';
import { ButtonControl } from './controlTypes/ButtonControl';
import { ListControl } from './controlTypes/ListControl';
import { url } from '../../../../../api/config';
import { toastOptions } from '../../../../../api/functions';

import { nodes_list as nodes_list_state, addBMSDocNode, getBMSNodesList } from '../../../../../redux/slices/bmsSlice';

export const CreateControlPopup = ({ setIsOpen, control, setControls, docId }) => {
    const dispatch = useDispatch();

    const nodes_list = useSelector(nodes_list_state);

    const [controlTitle, setControlTitle] = useState(control.title);
    const [values, setValues] = useState([]);
    const [writePoint, setWritePoint] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [nodeOptions, setNodeOptions] = useState([]);

    useEffect(() => {
        if (!nodes_list.length) {
            dispatch(getBMSNodesList({ docId }));
        }
    }, [dispatch]);

    useEffect(() => {
        if (nodes_list.length) {
            const node = nodes_list.filter(node => node.access === 'RW' || node.access === 'W');
            setNodeOptions(
                node.map(node => {
                    return { value: node.id, label: node.name, datatype: node.datatype };
                })
            );
        }
    }, [nodes_list]);

    useEffect(() => {
        if (writePoint && values.length !== 0 && controlTitle.length !== 0) {
            if (control.lib_data.subtype === 'button') {
                values[0].value !== '' ? setIsDisabled(false) : setIsDisabled(true);
                return;
            }
            if (values.hasOwnProperty('true')) {
                const value = Object.entries(values).map(el => {
                    return {
                        value: el[0],
                        label: el[1],
                    };
                });
                value[0].label.length !== 0 && value[1].label.length !== 0 ? setIsDisabled(false) : setIsDisabled(true);
            } else {
                for (const val of values) {
                    if (val.value.length === 0 || val.label.length === 0) {
                        return setIsDisabled(true);
                    } else {
                        setIsDisabled(false);
                    }
                }
            }
        } else {
            setIsDisabled(true);
        }
    }, [values, writePoint, controlTitle]);

    const clickHandler = async () => {
        const correctValues = values.hasOwnProperty('true')
            ? Object.entries(values).map(el => {
                  return {
                      value: el[0],
                      label: el[1],
                  };
              })
            : values;

        if (!values.hasOwnProperty('true')) {
            const valuesArr = values.map(el => el.value);
            const labelsArr = values.map(el => el.label);
            if (new Set(valuesArr).size !== valuesArr.length || new Set(labelsArr).size !== labelsArr.length) {
                return toast.error('Нельзя добавить два или более одинаковых значений или названий', toastOptions);
            }
        } else {
            const valuesArr = Object.values(values);
            if (valuesArr[0] === valuesArr[1]) {
                return toast.error('Названия точек должны быть разными', toastOptions);
            }
        }

        const data = {
            title: controlTitle,
            values: correctValues,
            write_point: writePoint,
        };
        const nodeObj = {
            doc_id: docId,
            node_id: control.id,
            data: JSON.stringify(data),
        };

        const { cn_id } = await dispatch(addBMSDocNode({ nodeObj }));

        setControls(prev => [
            ...prev,
            {
                ...control,
                cn_id,
                title: controlTitle,
                data: { ...control.lib_data, write_point: writePoint, values: correctValues, title: controlTitle },
                lib_data: { ...control.lib_data, write_point: writePoint, values: correctValues, title: controlTitle },
            },
        ]);
        setIsOpen(false);
    };
    return (
        <Popup isOpen={true} setIsOpen={setIsOpen} className="w-[750px]">
            <PopupTitle title={controlTitle} setControlTitle={setControlTitle} />

            <div className="flex mt-6 items-center justify-center">
                {control.lib_data.default_pic && <img src={`${url}${control.lib_data.default_pic}`} alt={control.title} className="w-12 h-12 mr-5" />}
                {control.lib_data.subtype === 'button' && <ButtonControl nodeOptions={nodeOptions} setValues={setValues} setWritePoint={setWritePoint} />}
                {control.lib_data.subtype === 'list' && <ListControl nodeOptions={nodeOptions} setValues={setValues} setWritePoint={setWritePoint} />}
            </div>

            <ButtonRectangular disabled={isDisabled} className="w-40 ml-auto mr-auto mt-6 justify-center" onClick={clickHandler}>
                Применить
            </ButtonRectangular>
        </Popup>
    );
};
