import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

import { application as application_state } from '../../../redux/slices/applicationsSlice';

import ReactCarousel from '../../common/ReactCarousel';

import helpers from '../detail/helpers';
import { ApplicationPhotoCreator } from './ApplicationPhotoCreator';
import { ApplicationPhotoExecutor } from './ApplicationPhotoExecutor';

export const ApplicationFiles = ({ photo }) => {
    const [createrPhoto, setCreatorPhoto] = useState(true);
    const [executorPhoto, setExecutorPhoto] = useState(false);
    const [newPhoto, setNewPhoto] = useState({});
    const [uploadPhoto, setUploadPhoto] = useState({});

    const application = useSelector(application_state);

    const admFiles = helpers.getImgFiles(application.adm_files);

    useEffect(() => {
        photo(newPhoto);
    }, [newPhoto]);

    const getSelectFile = e => {
        if (e.target.getAttribute('name') === 'creator') {
            setUploadPhoto({ preview: URL.createObjectURL(e.target.files[0]) });
            setNewPhoto({ ...newPhoto, ...{ creatorPhoto: e.target.files } });
        }
        if (e.target.getAttribute('name') === 'executor') {
            setNewPhoto({ ...newPhoto, ...{ executorPhoto: e.target.files } });
        }
    };

    return (
        <div className="mb-6">
            <div className="rounded-xl bg-white h-[24rem] mb-6">
                <div className="flex text-center text-base font-semibold cursor-pointer">
                    <div
                        className={`w-1/2 py-3 items-center flex justify-center  ${
                            createrPhoto ? 'text-status_text-new opacity-100 border-b-4 border-status_text-new' : 'opacity-50 border-b-4 border-white'
                        } `}
                        onClick={() => {
                            setCreatorPhoto(true);
                            setExecutorPhoto(false);
                        }}
                    >
                        {t('APPLICATIONS_FORM_APPLICANT')}

                        <div>
                            <input type="file" className="hidden" id="inputCreatorPhoto" name="creator" onChange={e => getSelectFile(e)} />
                            <label htmlFor="inputCreatorPhoto" className="cursor-pointer">
                                <svg width="12" height="12" viewBox="0 0 12 12" className={`ml-2.5  ${createrPhoto ? 'fill-status_text-new' : 'fill-status_text_deffered'}`}>
                                    <path d="M5.16602 11.8334V6.83342H0.166016V5.16675H5.16602V0.166748H6.83268V5.16675H11.8327V6.83342H6.83268V11.8334H5.16602Z" />
                                </svg>
                            </label>
                        </div>
                    </div>
                    <div
                        className={`w-1/2 py-3 items-center flex justify-center ${
                            executorPhoto ? 'text-status_text-new opacity-100 border-b-4 border-status_text-new' : 'opacity-50 border-b-4 border-white'
                        }`}
                        onClick={() => {
                            setExecutorPhoto(true);
                            setCreatorPhoto(false);
                        }}
                    >
                        {t('APPLICATIONS_EXECUTOR')}
                        <div>
                            <input type="file" className="hidden" id="inputExecutorPhoto" name="executor" onChange={e => getSelectFile(e)} />
                            <label htmlFor="inputExecutorPhoto" className="cursor-pointer">
                                <svg width="12" height="12" viewBox="0 0 12 12" className={`ml-2.5  ${executorPhoto ? 'fill-status_text-new' : 'fill-status_text_deffered'}`}>
                                    <path d="M5.16602 11.8334V6.83342H0.166016V5.16675H5.16602V0.166748H6.83268V5.16675H11.8327V6.83342H6.83268V11.8334H5.16602Z" />
                                </svg>
                            </label>
                        </div>
                    </div>
                </div>
                {createrPhoto ? <ApplicationPhotoCreator uploadPhoto={uploadPhoto} /> : <ApplicationPhotoExecutor />}
            </div>
            <div className="rounded-xl bg-white min-h-[8rem]">
                <h2 className="p-4 uppercase font-semibold text-sm border-b ">{t('APPLICATIONS_FILES')}</h2>
                {createrPhoto ? (
                    <div className="flex">
                        {application.attached_files.length !== 0 || admFiles !== null ? (
                            <ReactCarousel
                                content={application.attached_files.length !== 0 ? application.attached_files : admFiles.otherFiles}
                                options={{
                                    infinite: false,
                                    Dots: false,
                                }}
                            />
                        ) : (
                            <div className="text-center w-full text-base font-semibold opacity-50 mt-5">{t('APPLICATIONS_ATTACHED_FILES')}</div>
                        )}
                    </div>
                ) : (
                    <div className="text-center w-full text-base font-semibold opacity-50 mt-5">{t('APPLICATIONS_ATTACHED_FILES')}</div>
                )}
            </div>
        </div>
    );
};
