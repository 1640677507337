import { useEffect, useRef } from 'react';

export const CleanerMenuPopup = ({ children, isOpen }) => {
    const menuRef = useRef(null);

    useEffect(() => {
        if (isOpen && menuRef && menuRef.current.getBoundingClientRect().right > window.innerWidth) {
            menuRef.current.style.top = '2.5rem';
            menuRef.current.style.right = '0';
            menuRef.current.style.left = 'auto';
            menuRef.current.style.marginLeft = '0';
        }
    }, [isOpen]);

    return (
        <div
            className={`${isOpen ? 'flex' : 'hidden'} flex-col absolute top-0 left-full ml-1 bg-white w-48 shadow-3xl rounded text-sm text-black z-40`}
            ref={menuRef}
        >
            {children}
        </div>
    );
};
