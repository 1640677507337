import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Popup } from '../../common/popup/Popup';
import { ButtonRectangular } from '../../common/btn/ButtonRectangular';
import { SimpleSelect } from '../../common/widgets/SimpleSelect';

import { not_active_system as not_active_system_state, addBMSSystemInVenue } from '../../../redux/slices/bmsSlice';

export const CreateSystemPopup = ({ isOpen, setIsOpen }) => {
    const dispatch = useDispatch();
    const not_active_system = useSelector(not_active_system_state);

    const { venueId } = useParams();

    const [system, setSystem] = useState(null);

    useEffect(() => {
        setIsOpen(isOpen);

        if (!isOpen) {
            setSystem('');
        }
    }, [setIsOpen, isOpen]);

    const system_options = not_active_system.map(system => {
        return { label: system.title, value: system.id };
    });

    const handleCreateSystem = () => {
        dispatch(addBMSSystemInVenue({ object_id: venueId, direction_id: system.value }));
        setIsOpen(false);
        setSystem('');
    };

    return (
        <>
            <Popup isOpen={isOpen} setIsOpen={setIsOpen} className="w-[450px] h-80">
                <div className="text-center text-lg">Добавление новой системы</div>
                <div className="flex justify-around mt-8 mb-36">
                    <SimpleSelect
                        closeMenuOnSelect={true}
                        options={system_options}
                        placeholder="Выберите системы"
                        value={system}
                        callback={val => setSystem(val)}
                        className="w-80"
                    />
                </div>
                <ButtonRectangular className="w-40 ml-auto mr-auto" disabled={system === '' || system === null} onClick={handleCreateSystem}>
                    <svg width="20" height="20" viewBox="0 0 20 20" className="fill-current">
                        <path d="M7.95825 15.0001L3.20825 10.2501L4.39575 9.06258L7.95825 12.6251L15.6041 4.97925L16.7916 6.16675L7.95825 15.0001Z" />
                    </svg>
                    Применить
                </ButtonRectangular>
            </Popup>
        </>
    );
};
