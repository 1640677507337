import { useDispatch, useSelector } from 'react-redux';

import { object as object_state, objects as objects_state, set_object } from '../../../redux/slices/hospitalitySlice';
import { PopupFixed } from '../../common/popup/PopupFixed';
import { Radio } from '../../common/widgets/Radio';

export const ChangeHotelPopup = ({ isOpen, setIsOpen, buttonRef }) => {
    const dispatch = useDispatch();

    const objects = useSelector(objects_state);
    const object = useSelector(object_state);

    const clickHandler = id => {
        dispatch(set_object(objects.find(obj => obj.id === id)));
        setIsOpen(false);
    };

    return (
        <PopupFixed isOpen={isOpen} setIsOpen={setIsOpen} buttonRef={buttonRef} className="w-68">
            <div className="flex flex-col gap-2 my-4">
                {object &&
                    objects &&
                    objects.map(obj => (
                        <Radio
                            key={obj.id}
                            name="object"
                            value={obj.id}
                            title={obj.title}
                            checked={obj.id === object.id}
                            onClick={() => clickHandler(obj.id)}
                        />
                    ))}
            </div>
        </PopupFixed>
    );
};
