import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../api/functions';
import {
    actApplySupervisorsAll,
    apply_cleaners_fetching as apply_cleaners_fetching_state,
    workers as workers_state,
} from '../../../redux/slices/hospitalitySlice';
import { ButtonRectangular } from '../../common/btn/ButtonRectangular';

export const ApplyTasksButton = () => {
    const dispatch = useDispatch();

    const workers = useSelector(workers_state);
    const apply_cleaners_fetching = useSelector(apply_cleaners_fetching_state);

    const applyAllHandler = async () => {
        const result = await dispatch(actApplySupervisorsAll());

        if (result) {
            toast.success('Изменения применены', toastOptions);
        } else {
            toast.error('Ошибка при сохранении. Пожалуйста, попробуйте еще раз', toastOptions);
        }
    };

    return (
        <ButtonRectangular
            disabled={!workers.find(worker => worker.state === 1 && !worker.is_applied)}
            loading={apply_cleaners_fetching}
            onClick={applyAllHandler}
            className="w-40"
        >
            <svg width="20" height="20" viewBox="0 0 20 20" className="fill-current">
                <path d="M7.95825 15.0001L3.20825 10.2501L4.39575 9.06258L7.95825 12.6251L15.6041 4.97925L16.7916 6.16675L7.95825 15.0001Z" />
            </svg>
            Применить
        </ButtonRectangular>
    );
};
