import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { t } from 'i18next';

import {
    tasks as tasks_state,
    task as task_state,
    tasksFetch,
    tasks_fetching as tasks_fetching_state,
    filter as filter_state,
    set_detail_open,
    getScheduledTask,
    venues as venues_state,
    filter_fetch as filter_fetch_state,
    venueFetch,
    departmentsFetch,
    changeStatusScheduledTask,
    change_status_error as change_status_error_state,
    set_change_status_error,
    set_filter_page,
} from '../../redux/slices/adminSchedulerSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { Loader } from '../common/Loader';

export const Table = ({ clearFilter }) => {
    const dispatch = useDispatch();
    const [cookies] = useCookies();

    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const tasks_fetching = useSelector(tasks_fetching_state);
    const tasks = useSelector(tasks_state);
    const filter = useSelector(filter_state);
    const task = useSelector(task_state);
    const venues = useSelector(venues_state);
    const filter_fetch = useSelector(filter_fetch_state);
    const change_status_error = useSelector(change_status_error_state);

    const [editBlockIsOpen, setEditBlockIsOpen] = useState(false);
    const [activeBlockIsOpen, setActiveBlockIsOpen] = useState(false);
    const [listeningEdit, setListeningEdit] = useState(false);
    const [listeningActive, setListeningActive] = useState(false);

    const tableEditRef = useRef(null);
    const tableActiveRef = useRef(null);

    const lang = cookies.i18next;
    //change status state

    useEffect(() => {
        if (change_status_error) {
            toast.error(`${change_status_error}`, {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });

            dispatch(set_change_status_error(null));
        }
    }, [dispatch, change_status_error]);

    const editHandler = async id => {
        await dispatch(getScheduledTask({ username, auth_key_calendar, id, lang }));
    };

    const activeHandler = async (id, value) => {
        setActiveBlockIsOpen(false);
        await dispatch(changeStatusScheduledTask({ username, auth_key_calendar, data: { id, status: value }, lang }));
    };

    const openDetail = async () => {
        if (!venues.length) {
            await dispatch(venueFetch({ username, auth_key_calendar, lang }));
        }
        if (!filter_fetch.departments.length) {
            await dispatch(departmentsFetch(lang));
        }

        await dispatch(set_detail_open(true));
    };

    useEffect(() => {
        if (task.id) {
            openDetail();
        }
    }, [task]);

    useEffect(() => {
        if (username && auth_key_calendar && clearFilter) {
            dispatch(tasksFetch({ username, auth_key_calendar, filter, lang }));
        }
    }, [dispatch, username, auth_key_calendar, filter, clearFilter, lang]);

    const listenForOutsideClick = (listening, setListening, ref, setIsOpen) => () => {
        if (listening) return;
        setListening(true);

        document.addEventListener(`click`, evt => {
            const cur = ref.current;
            if (!ref.current) return;

            const node = evt.target;
            if (cur.contains(node)) return;
            setIsOpen(null);
        });
    };

    useEffect(listenForOutsideClick(listeningEdit, setListeningEdit, tableEditRef, setEditBlockIsOpen));
    useEffect(listenForOutsideClick(listeningActive, setListeningActive, tableActiveRef, setActiveBlockIsOpen));

    // table
    const columns = useMemo(
        () => [
            {
                Header: t('ADM_PLANNER_STATUS'),
                accessor: 'status',
            },
            {
                Header: t('ADM_PLANNER_ID'),
                accessor: 'id',
            },
            {
                Header: t('ADM_PLANNER_DESCRIPTION'),
                accessor: 'description',
            },
            {
                Header: t('ADM_PLANNER_ACTIVITY_PERIOD'),
                accessor: 'date',
            },
            {
                Header: t('ADM_PLANNER_CREATOR'),
                accessor: 'user',
            },
            {
                Header: t('ADM_PLANNER_DIRECTION'),
                accessor: 'direction',
            },
            {
                Header: '',
                accessor: 'edit',
            },
        ],
        [lang]
    );
    const tasks_list = useMemo(() => tasks?.list.map(task => task) || [], [tasks]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: tasks_list });

    if (tasks_fetching && filter.page === 1) {
        return (
            <div className="w-full h-20 flex items-center justify-center">
                <Loader />
            </div>
        );
    }

    if (!tasks_list.length) {
        return <div>{t('CALENDAR_NOTHING_FOUND')}</div>;
    }

    return (
        <>
            <div className="w-full">
                <InfiniteScroll
                    next={() => dispatch(set_filter_page(filter.page + 1))}
                    hasMore={tasks.count > 0 && tasks.total_count > 20}
                    loader={
                        <div className="w-full h-20 flex items-center justify-center">
                            <Loader />
                        </div>
                    }
                    dataLength={rows.length}
                    scrollableTarget="wrapper"
                >
                    <table {...getTableProps()} className="w-full border-separate border-spacing-0 text-sm">
                        <thead className="uppercase text-black text-left w-0 md:w-full absolute md:sticky md:z-10 top-16 overflow-hidden md:overflow-visible">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className="bg-white font-medium px-2 first:pl-4 last:pr-4 py-5 border-y border-gray-10 first:border-l first:rounded-tl-lg last:border-r last:rounded-tr-lg"
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="block md:table-row mb-4 md:mb-0 border md:border-0 border-gray-10 rounded-lg overflow-hidden md:overflow-visible"
                                    >
                                        {row.cells.map(cell => {
                                            let additionally = '';
                                            let styles = {};

                                            //status
                                            if (cell.column.id === 'status') {
                                                let statusJSX;
                                                if (cell.value === 10) {
                                                    statusJSX = (
                                                        <div className="uppercase text-green md:overflow-hidden md:overflow-ellipsis w-[inherit]">
                                                            {t('ADM_PLANNER_TASK_ACTIVE')}
                                                        </div>
                                                    );
                                                } else if (cell.value === 0) {
                                                    statusJSX = (
                                                        <div className="uppercase text-gray whitespace-nowrap md:overflow-hidden md:overflow-ellipsis w-[inherit]">
                                                            {t('ADM_PLANNER_TASK_NOT_ACTIVE')}
                                                        </div>
                                                    );
                                                } else {
                                                    statusJSX = (
                                                        <div className="uppercase text-gray-300 whitespace-nowrap md:overflow-hidden md:overflow-ellipsis w-[inherit]">
                                                            {t('ADM_PLANNER_TASK_UNDEFINED')}
                                                        </div>
                                                    );
                                                }

                                                additionally = (
                                                    <div className="relative md:w-24" ref={activeBlockIsOpen === cell.row.original.id ? tableActiveRef : null}>
                                                        <button
                                                            className="flex flex-row items-center text-gray-30 hover:text-black"
                                                            onClick={() =>
                                                                activeBlockIsOpen === cell.row.original.id
                                                                    ? setActiveBlockIsOpen(false)
                                                                    : setActiveBlockIsOpen(cell.row.original.id)
                                                            }
                                                        >
                                                            {statusJSX}
                                                            <svg className="w-6 h-6 fill-current transition-colors" viewBox="0 0 24 24">
                                                                <path d="M7 10L12 15L17 10H7Z" />
                                                            </svg>
                                                        </button>
                                                        <div
                                                            className={`w-36 bg-white shadow-3xl rounded-sm absolute right-0 md:right-auto md:left-[75%] transition-opacity opacity-100 showed z-20 ${
                                                                activeBlockIsOpen === cell.row.original.id ? 'opacity-100 showed' : 'hidden opacity-0'
                                                            }`}
                                                        >
                                                            <button
                                                                className="w-full flex flex-row items-center justify-between pl-3 pr-4 py-2 hover:bg-gray-100 text-green font-normal text-sm text-left border-0 cursor-pointer whitespace-nowrap"
                                                                type="button"
                                                                onClick={() => activeHandler(cell.row.original.id, 1)}
                                                            >
                                                                {t('ADM_PLANNER_TASK_ACTIVE')}
                                                                {cell.value === 10 && (
                                                                    <svg className="w-4 h-4 fill-current" viewBox="0 0 17 17">
                                                                        <path d="M6.375 11.4537L3.42125 8.5L2.41542 9.49875L6.375 13.4583L14.875 4.95833L13.8763 3.95958L6.375 11.4537Z" />
                                                                    </svg>
                                                                )}
                                                            </button>
                                                            <button
                                                                className="w-full flex flex-row items-center justify-between pl-3 pr-4 py-2 hover:bg-gray-100 text-gray font-normal text-sm text-left border-0 cursor-pointer whitespace-nowrap"
                                                                type="button"
                                                                onClick={() => activeHandler(cell.row.original.id, 0)}
                                                            >
                                                                {t('ADM_PLANNER_TASK_NOT_ACTIVE')}
                                                                {cell.value === 0 && (
                                                                    <svg className="w-4 h-4 fill-current" viewBox="0 0 17 17">
                                                                        <path d="M6.375 11.4537L3.42125 8.5L2.41542 9.49875L6.375 13.4583L14.875 4.95833L13.8763 3.95958L6.375 11.4537Z" />
                                                                    </svg>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            //edit
                                            if (cell.column.id === 'edit') {
                                                additionally = (
                                                    <div className="relative" ref={editBlockIsOpen === cell.value ? tableEditRef : null}>
                                                        <button
                                                            className="w-10 h-10 hidden md:flex justify-center items-center border-0 bg-transparent rounded-full hover:bg-gray-100"
                                                            type="button"
                                                            onClick={() =>
                                                                editBlockIsOpen === cell.value ? setEditBlockIsOpen(false) : setEditBlockIsOpen(cell.value)
                                                            }
                                                        >
                                                            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                                                                <path d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" />
                                                            </svg>
                                                        </button>
                                                        <div
                                                            className={`relative md:absolute right-0 transition-opacity ${
                                                                editBlockIsOpen === cell.value ? 'md:opacity-100 md:showed' : 'md:hidden md:opacity-0'
                                                            }`}
                                                        >
                                                            <button
                                                                className="pl-3 pr-4 py-2 bg-white hover:bg-gray-100 text-black font-normal shadow-3xl rounded-sm border-0 cursor-pointer"
                                                                type="button"
                                                                onClick={() => editHandler(cell.value)}
                                                            >
                                                                {t('ADM_PLANNER_EDIT_BTN')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                );

                                                styles = 'md:w-10';
                                            }

                                            //description
                                            if (cell.column.id === 'description') {
                                                additionally = (
                                                    <div
                                                        data-tip={cell.value}
                                                        className="max-w-xs md:max-w-none w-[inherit] md:line-clamp-2 md:overflow-ellipsis"
                                                    >
                                                        {cell.value}
                                                    </div>
                                                );
                                                styles = 'md:w-20 md:w-40 xl:w-80 2xl:w-[40rem]';
                                            }

                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    data-label={cell.column.Header}
                                                    className={`${styles} font-normal flex justify-between md:table-cell text-right md:text-left before:content-[attr(data-label)] before:uppercase before:font-medium  md:before:hidden bg-white  py-5 px-2 md:first:pl-4 md:last:pr-4 border-b last:border-b-0 md:last:border-b border-gray-10 md:first:border-l md:last:border-r ${
                                                        rows.length === index + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                                    }`}
                                                >
                                                    {additionally || cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#707183E5" effect="solid" className="!rounded-md !py-2 !px-2.5 max-w-prose" />
        </>
    );
};
