import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listenForOutsideClick } from '../../../api/functions';
import {
    supervisors_fetching as supervisors_fetching_state,
    getSupervisors,
    object as object_state,
    supervisors as supervisors_state,
} from '../../../redux/slices/hospitalitySlice';
import { Supervisor } from './Supervisor';
import { Loader } from '../../common/Loader';

export const SupervisorsSection = () => {
    const dispatch = useDispatch();

    const object = useSelector(object_state);
    const supervisors = useSelector(supervisors_state);
    const supervisors_fetching = useSelector(supervisors_fetching_state);

    const [isOpenSupervisorMenu, setIsOpenSupervisorMenu] = useState(null);
    const [listeningSupervisorMenu, setListeningSupervisorMenu] = useState(false);

    const activeSupervisorMenuRef = useRef(null);

    useEffect(listenForOutsideClick(listeningSupervisorMenu, setListeningSupervisorMenu, activeSupervisorMenuRef, () => setIsOpenSupervisorMenu(null)));

    useEffect(() => {
        if (object) {
            dispatch(getSupervisors());
        }
    }, [object, dispatch]);

    return (
        <div className="w-full box-border p-6 overflow-y-auto">
            {supervisors_fetching ? (
                <div className="w-full flex justify-center items-center">
                    <Loader height={30} width={30} />
                </div>
            ) : (
                <div className="flex flex-row flex-wrap gap-6">
                    {supervisors.filter(supervisor => supervisor.state && supervisor.is_filtered).length ? (
                        supervisors
                            .filter(supervisor => supervisor.state && supervisor.is_filtered)
                            .map(supervisor => (
                                <Supervisor
                                    key={supervisor.id}
                                    id={supervisor.id}
                                    name={supervisor.name}
                                    isOpenMenu={isOpenSupervisorMenu}
                                    setIsOpenMenu={setIsOpenSupervisorMenu}
                                    activeSupervisorMenuRef={activeSupervisorMenuRef}
                                />
                            ))
                    ) : (
                        <p className="font-medium text-gray">Нет назначенных супервайзеров</p>
                    )}
                </div>
            )}
        </div>
    );
};
