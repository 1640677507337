import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { set_filter, filter_fetch as filter_fetch_state, set_filter_open, filter as filter_state } from '../../redux/slices/applicationsSlice';
import { t } from 'i18next';

export const Filter = () => {
    const dispatch = useDispatch();

    const skipFirstrender = useRef(true);

    const filter = useSelector(filter_state);
    const [searchLine, setSearchLine] = useState(filter ? filter.filter_id : '');

    const filter_fetch = useSelector(filter_fetch_state);

    useEffect(() => {
        if (skipFirstrender.current) {
            skipFirstrender.current = false;
            return;
        }
        const timeOutId = setTimeout(() => {
            dispatch(set_filter({ type: 'filter_id', value: searchLine }));
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchLine, dispatch]);

    const status_options = filter_fetch.status;
    const category_options = filter_fetch.category;
    const departments_options = filter_fetch.departments;
    const priority_options = filter_fetch.priority;

    const signs = [
        {
            value: 'repeated',
            label: t('APPLICATIONS_SIGNS_REPEATING'),
        },
        {
            value: 'overdue',
            label: t('APPLICATIONS_SIGNS_OVERDUE'),
        },
        {
            value: 'sabbath',
            label: t('APPLICATIONS_SIGNS_SABBATH'),
        },
    ];

    return (
        <div className="flex flex-wrap gap-2 w-full bg-white rounded-lg border border-gray-10 p-2 mb-6">
            <div className="h-10 w-44 relative">
                <input
                    type="text"
                    placeholder={t('ADM_PLANNER_DESCR_ID')}
                    onChange={e => setSearchLine(e.target.value)}
                    className="box-border w-full h-full text-sm rounded-1 bg-gray-100 border-gray-10 py-0 pl-2 pr-9"
                    defaultValue={filter ? filter.filter_id : ''}
                />

                <svg className="w-[20px] h-[20px] absolute right-[10px] top-[9px]" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M8.33333 14.1667C11.555 14.1667 14.1667 11.555 14.1667 8.33333C14.1667 5.11167 11.555 2.5 8.33333 2.5C5.11167 2.5 2.5 5.11167 2.5 8.33333C2.5 11.555 5.11167 14.1667 8.33333 14.1667Z"
                        stroke="#B3B3BC"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M12.5 12.5L17.5 17.5" stroke="#B3B3BC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>

            <Select
                onChange={el => dispatch(set_filter({ type: 'filter_status', value: el ? el.value : '' }))}
                options={status_options}
                isClearable={true}
                isSearchable={false}
                // getOptionLabel={e => <span>{getColorStatus(e.value)}</span>}
                placeholder={t('APPLICATIONS_SEARCH_STATUS')}
                classNamePrefix="filter"
                className="select text-sm w-44 cursor-pointer"
                defaultValue={status_options.find(el => {
                    return Number(el.value) === Number(filter.filter_status);
                })}
            />

            <Select
                onChange={el => dispatch(set_filter({ type: 'filter_signs', value: el ? el.value : '' }))}
                options={signs}
                isClearable={true}
                isSearchable={false}
                // isMulti
                // getOptionLabel={e => (
                //     <div style={{ display: 'flex', alignItems: 'center' }}>
                //         {/* {e.icon} */}
                //         <span style={{ marginLeft: 8 }}>{e.label}</span>
                //     </div>
                // )}
                placeholder={t('APPLICATIONS_SEARCH_SIGN')}
                classNamePrefix="filter"
                className="select text-sm w-52 cursor-pointer "
                defaultValue={signs.find(el => {
                    return String(el.value) === String(filter.filter_signs);
                })}
            />

            <Select
                onChange={el => dispatch(set_filter({ type: 'filter_source', value: el ? el.value : '' }))}
                options={category_options}
                isClearable={true}
                isSearchable={false}
                placeholder={t('APPLICATIONS_FORM_SOURCE')}
                classNamePrefix="filter"
                className="select text-sm w-52 cursor-pointer "
                defaultValue={category_options.find(el => {
                    return Number(el.value) === Number(filter.filter_source);
                })}
            />

            <Select
                onChange={el => dispatch(set_filter({ type: 'filter_department', value: el ? el.value : '' }))}
                options={departments_options}
                isClearable={true}
                isSearchable={false}
                placeholder={t('APPLICATIONS_FORM_DEPARTMENT')}
                classNamePrefix="filter"
                className="select text-sm w-48 cursor-pointer "
                defaultValue={departments_options.find(el => {
                    return Number(el.value) === Number(filter.filter_department);
                })}
            />

            <Select
                onChange={el => dispatch(set_filter({ type: 'filter_priority', value: el ? el.value : '' }))}
                options={priority_options}
                isClearable={true}
                isSearchable={false}
                placeholder={t('APPLICATIONS_FORM_PRIORITY')}
                classNamePrefix="filter"
                className="select text-sm w-44 cursor-pointer "
                defaultValue={priority_options.find(el => {
                    return Number(el.value) === Number(filter.filter_priority);
                })}
            />

            <div className="ml-auto mr-7 text-sm font-bold flex " onClick={() => dispatch(set_filter_open(true))}>
                <button type="button" className="flex items-center border border-solid border-blue-400 px-3 rounded-sm font-normal	">
                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none">
                        <path d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z" fill="#3F8CFE" />
                    </svg>
                    <span className="ml-2 text-sky-500">{t('APPLICATIONS_ADDIT_FILTER')}</span>
                </button>
            </div>
        </div>
    );
};
