import { twMerge } from 'tailwind-merge';
import { Loader } from '../Loader';

export const ButtonRectangular = ({ children, onClick, className = '', buttonRef = null, disabled = false, loading = false }) => {
    const paddingClasses = Array.isArray(children) && children[0].type === 'svg' ? 'pl-4 pr-6' : 'px-6';
    const disabledClasses = disabled || loading ? 'border-gray-30 bg-gray-30 hover:bg-gray-30 text-white' : '';

    const buttonClasses = twMerge(
        `w-auto h-10 flex items-center justify-around gap-2 whitespace-nowrap transition-colors border rounded-md text-white border-green bg-green hover:bg-green-600 ${paddingClasses} ${className} ${disabledClasses}`
    );

    return (
        <button onClick={onClick} className={buttonClasses} ref={buttonRef} disabled={disabled || loading}>
            {loading ? <Loader height={20} width={20} color="#ffffff" /> : children}
        </button>
    );
};
