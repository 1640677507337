import { DistributeButton } from './DistributeButton';
import { SupervisorsPaneButton } from './SupervisorsPaneButton';
import { SupervisorsFilterSection } from '../supervisorsFilter/SupervisorsFilterSection';
import { SwitchPanel } from '../switchPanel/SwitchPanel';

export const SubHeader = ({ setSupervisorPaneIsOpen }) => {
    return (
        <div className="flex flex-col">
            <div className="w-full z-20 shrink-0 flex flex-row bg-white border-b border-b-gray-10 border-l border-l-gray-10">
                <div className="w-52 h-full shrink-0 flex justify-center px-3 py-5 border-r border-r-gray-10 box-border">
                    <DistributeButton />
                </div>
                <div className="w-full h-full flex flex-row flex-wrap px-6 py-5 gap-4">
                    <SupervisorsPaneButton setSupervisorPaneIsOpen={setSupervisorPaneIsOpen} />
                    <SupervisorsFilterSection />
                </div>
            </div>
            <div className="w-full shrink-0 flex flex-row bg-white border-b border-b-gray-10 border-l border-l-gray-10">
                <div className="w-52 h-full shrink-0 flex justify-center bg-white border-r border-l-gray-10 border-r-gray-10 box-border"></div>
                <SwitchPanel />
            </div>
        </div>
    );
};
