import { useMemo, useState, useRef, useEffect } from 'react';
import { useTable } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { t } from 'i18next';

import { book } from '../../navigation/book';
import { Detail } from './Detail';
import {
    set_detail_open,
    qualificationPeriodFetch,
    qualifictionsPeriodList as qualifictionsPeriodList_state,
    qualificationsListFetch,
    deletQualificationTypeFetch,
    qualificationsList as qualificationsList_state,
} from '../../redux/slices/qualificationSlice';

import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { userQualificationRole as userQualificationRole_state } from '../../redux/slices/qualificationSlice';
import { ContentContainer } from '../common/ContentContainer';
import { SubHeader } from '../common/subheader/SubHeader';
import { SubHeaderLeft } from '../common/subheader/SubHeaderLeft';
import { SubHeaderRight } from '../common/subheader/SubHeaderRight';
import { Title } from '../common/subheader/Title';
import { Loader } from '../common/Loader';
import { CreateQualificationBtn } from './CreateQualificationBtn';

export const QualificationList = () => {
    const [editBlockIsOpen, setEditBlockIsOpen] = useState(false);
    const [listeningEdit, setListeningEdit] = useState(false);
    const [qualification, setQualification] = useState(null);

    const tableEditRefQual = useRef(null);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const qualifictionsPeriodList = useSelector(qualifictionsPeriodList_state);
    const qualificationsList = useSelector(qualificationsList_state);
    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const userQualificationRole = useSelector(userQualificationRole_state);

    const [cookie] = useCookies();
    const lang = cookie.i18next;

    const listenForOutsideClick = (listening, setListening, ref, setIsOpen) => () => {
        if (listening) return;
        setListening(true);

        document.addEventListener(`click`, evt => {
            const cur = ref.current;
            if (!ref.current) return;

            const node = evt.target;
            if (cur.contains(node)) return;
            setIsOpen(null);
        });
    };

    useEffect(() => {
        if (qualifictionsPeriodList === null) {
            dispatch(qualificationPeriodFetch());
        }
    }, [dispatch, qualifictionsPeriodList]);

    useEffect(() => {
        if (userQualificationRole === undefined || userQualificationRole === 'employer') {
            navigate('/calendar');
        }
    });

    useEffect(() => {
        if (qualificationsList.length === 0) {
            dispatch(qualificationsListFetch());
        }
    }, [dispatch, qualificationsList]);

    useEffect(listenForOutsideClick(listeningEdit, setListeningEdit, tableEditRefQual, setEditBlockIsOpen));

    const columns = useMemo(
        () =>
            userQualificationRole === 'administrator' || userQualificationRole === 'manager'
                ? [
                      { Header: t('QUALIFICATION_FULL_NAME'), accessor: 'name' },
                      { Header: t('QUALIFICATION_PERIOD'), accessor: 'period_id' },
                      { Header: t('QUALIFICATION_CHANGE'), accessor: 'change_certificate' },
                      { Header: '', accessor: 'edit' },
                  ]
                : [
                      { Header: t('QUALIFICATION_FULL_NAME'), accessor: 'name' },
                      { Header: t('QUALIFICATION_PERIOD'), accessor: 'period_id' },
                      { Header: t('QUALIFICATION_CHANGE'), accessor: 'change_certificate' },
                  ],

        [lang, userQualificationRole]
    );

    const editHandler = async id => {
        setQualification(qualificationsList.find(el => el.id === id));
        await dispatch(set_detail_open(true));
    };

    const deletHandler = async id => {
        setEditBlockIsOpen(false);
        await dispatch(deletQualificationTypeFetch({ username, auth_key_calendar, id }));
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: qualificationsList === null ? [] : qualificationsList,
    });

    if (qualificationsList === null) {
        return (
            <div className="w-full h-20 flex items-center justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Title>{t(book.qualification_list.key)}</Title>
                </SubHeaderLeft>
                <SubHeaderRight>
                    {(userQualificationRole === 'administrator' || userQualificationRole === 'manager') && <CreateQualificationBtn />}
                </SubHeaderRight>
            </SubHeader>
            <ContentContainer>
                <Detail qualification={qualification} setQualification={setQualification} />
                <div className="w-full">
                    <table {...getTableProps()} className="w-full border-separate border-spacing-0 text-sm">
                        <thead className="uppercase text-black text-left w-0 md:w-full absolute md:sticky md:z-10 top-16 overflow-hidden md:overflow-visible">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => {
                                        return (
                                            <th
                                                {...column.getHeaderProps()}
                                                className={`bg-white border-l font-medium px-2 first:pl-4 last:pr-4 py-5 border-y border-gray-10 first:border-l first:rounded-tl-lg  last:rounded-tr-lg  ${
                                                    userQualificationRole === 'administrator' || userQualificationRole === 'manager'
                                                        ? 'last:border-l-0'
                                                        : 'last:border-l'
                                                }`}
                                            >
                                                {column.render('Header')}
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="block md:table-row mb-4 md:mb-0 border md:border-0 border-gray-10 rounded-lg overflow-hidden md:overflow-visible"
                                    >
                                        {row.cells.map(cell => {
                                            let additionally = '';

                                            if (cell.column.id === 'edit') {
                                                additionally = (
                                                    <div className="relative" ref={editBlockIsOpen === cell.row.original.id ? tableEditRefQual : null}>
                                                        {(userQualificationRole === 'administrator' || userQualificationRole === 'manager') && (
                                                            <button
                                                                className=" hidden md:flex justify-center items-center border-0 bg-transparent rounded-full hover:bg-gray-100"
                                                                type="button"
                                                                onClick={() =>
                                                                    editBlockIsOpen === cell.row.original.id
                                                                        ? setEditBlockIsOpen(false)
                                                                        : setEditBlockIsOpen(cell.row.original.id)
                                                                }
                                                            >
                                                                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                                                                    <path d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" />
                                                                </svg>
                                                            </button>
                                                        )}
                                                        <div
                                                            className={`relative md:absolute right-0 transition-opacity z-10  ${
                                                                editBlockIsOpen === cell.row.original.id ? 'md:opacity-100 md:showed' : 'md:hidden md:opacity-0'
                                                            }`}
                                                        >
                                                            {(userQualificationRole === 'administrator' || userQualificationRole === 'manager') && (
                                                                <button
                                                                    className="pl-3 pr-4 py-2 w-28 text-xs bg-white hover:bg-gray-100 text-black font-normal shadow-3xl rounded-sm border-0 cursor-pointer"
                                                                    type="button"
                                                                    onClick={() => editHandler(cell.row.original.id)}
                                                                >
                                                                    {t('ADM_PLANNER_EDIT_BTN')}
                                                                </button>
                                                            )}
                                                            {userQualificationRole === 'administrator' && (
                                                                <button
                                                                    className="pl-3 pr-4 py-2 w-28 text-xs bg-white hover:bg-gray-100 text-red-600 font-normal shadow-3xl rounded-sm border-0 cursor-pointer"
                                                                    type="button"
                                                                    onClick={() => deletHandler(cell.row.original.id)}
                                                                >
                                                                    {t('CALENDAR_BTN_DEL')}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            if (cell.column.id === 'change_certificate') {
                                                additionally = <div>{cell.row.original.change_certificate === false ? t('NO') : t('YES')}</div>;
                                            }

                                            if (cell.column.id === 'period_id') {
                                                const label = qualifictionsPeriodList?.find(el => el.value === cell.row.original.period_id);
                                                additionally = <div>{label?.label}</div>;
                                            }

                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    data-label={cell.column.Header}
                                                    className={`flex border-l justify-between md:table-cell text-right last:pl-1 md:text-left before:content-[attr(data-label)] before:uppercase before:font-medium md:before:hidden bg-white font-medium  px-2 py-5 md:first:pl-4 md:last:pr-1 border-b last:border-b-0 md:last:border-b border-gray-10 md:first:border-l md:last:border-r ${
                                                        rows.length === index + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                                    }`}
                                                >
                                                    {additionally || cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </ContentContainer>
        </>
    );
};
