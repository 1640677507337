import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../api/functions';
import {
    actResetSupervisors,
    reset_all_tasks_fetching as reset_all_tasks_fetching_state,
    workers as workers_state,
} from '../../../redux/slices/hospitalitySlice';
import { ButtonRectangular } from '../../common/btn/ButtonRectangular';

export const ResetAllTasksButton = () => {
    const dispatch = useDispatch();

    const workers = useSelector(workers_state);

    const reset_all_tasks_fetching = useSelector(reset_all_tasks_fetching_state);

    const resetAllHandler = async () => {
        const result = await dispatch(actResetSupervisors({ supervisor_id: null }));

        if (result) {
            toast.success('Изменения сброшены', toastOptions);
        } else {
            toast.error('Ошибка при сохранении. Пожалуйста, попробуйте еще раз', toastOptions);
        }
    };

    return (
        <ButtonRectangular
            className="text-red-600 border-red-600 bg-white hover:bg-red-100 w-44"
            disabled={!workers.find(worker => worker.supervisor_id && !worker.is_applied)}
            loading={reset_all_tasks_fetching}
            onClick={resetAllHandler}
        >
            <svg width="20" height="20" viewBox="0 0 20 20" className="fill-current">
                <path d="M5.83325 17.5C5.37492 17.5 4.98256 17.3368 4.65617 17.0104C4.32978 16.684 4.16659 16.2917 4.16659 15.8333V5H3.33325V3.33333H7.49992V2.5H12.4999V3.33333H16.6666V5H15.8333V15.8333C15.8333 16.2917 15.6701 16.684 15.3437 17.0104C15.0173 17.3368 14.6249 17.5 14.1666 17.5H5.83325ZM14.1666 5H5.83325V15.8333H14.1666V5ZM7.49992 14.1667H9.16658V6.66667H7.49992V14.1667ZM10.8333 14.1667H12.4999V6.66667H10.8333V14.1667Z" />
            </svg>
            Сбросить все
        </ButtonRectangular>
    );
};
