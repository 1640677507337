import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stausListFetch, categoryListfetch, departmentsFetch, priorityListFetch, venueFetch, set_detail_open, set_application } from '../../redux/slices/applicationsSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { t } from 'i18next';
import { useCookies } from 'react-cookie';

export const CreateApplicationButton = () => {
    const dispatch = useDispatch();

    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const [cookies] = useCookies();

    const lang = cookies.i18next;

    useEffect(() => {
        dispatch(venueFetch({ username, auth_key_calendar, lang }));
        dispatch(departmentsFetch(lang));
        dispatch(categoryListfetch({ username, auth_key_calendar, lang }));
        dispatch(stausListFetch({ username, auth_key_calendar, lang }));
        dispatch(priorityListFetch({ username, auth_key_calendar, lang }));
    }, [dispatch, lang, username, auth_key_calendar]);

    const handlerClick = async () => {
        await dispatch(set_detail_open(true));
    };

    return (
        <div className="flex">
            <button className="bg-blue hover:bg-blue-500 transition-colors duration-200 text-white font-semibold rounded px-6 py-2" onClick={handlerClick}>
                + {t('APPLICATIONS_NEW_APP_BTN')}
            </button>
        </div>
    );
};
