import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import {
    userQualificationRole as userQualificationRole_state,
    qualificationsListFetch,
    filter_fetch as filter_fetch_state,
    qualificationError as qualificationError_state,
} from '../redux/slices/qualificationSlice';

import { book } from '../navigation/book';
import { SubHeader } from '../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../components/common/subheader/SubHeaderLeft';
import { ContentContainer } from '../components/common/ContentContainer';
import { SubHeaderRight } from '../components/common/subheader/SubHeaderRight';
import { Filter } from '../components/qualification/Filter';
import { Table } from '../components/qualification/Table';
// import { HistoryQualificationButton } from '../components/qualification/HistoryQualificationButton';
import { ListQualificationButton } from '../components/qualification/ListQualificationButton';
import { Title } from '../components/common/subheader/Title';
import { Loader } from '../components/common/Loader';

export const QualificationPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const filter_fetch = useSelector(filter_fetch_state);
    const qualificationError = useSelector(qualificationError_state);
    const userQualificationRole = useSelector(userQualificationRole_state);
    const qualification_options = filter_fetch.qualifications;

    useEffect(() => {
        if (userQualificationRole === undefined || userQualificationRole === 'employer') {
            navigate(book.home.link);
        }
    });

    useEffect(() => {
        if (qualification_options.length === 0) {
            (async () => {
                await dispatch(qualificationsListFetch());
            })();
        }
    }, [dispatch, qualification_options]);

    if (qualificationError === 38) {
        return (
            <div className="w-full h-20 flex items-center justify-center">
                Нет ни одной созданной квалификации. Добавьте квалификацию в разделе "Список квалификаций"
            </div>
        );
    }

    if (qualification_options.length === 0) {
        return (
            <div className="w-full h-20 flex items-center justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Title>{t(book.qualification.key)}</Title>
                </SubHeaderLeft>
                <SubHeaderRight>
                    {/* <HistoryQualificationButton /> */}
                    {(userQualificationRole === 'administrator' || userQualificationRole === 'manager') && <ListQualificationButton />}
                </SubHeaderRight>
            </SubHeader>
            <ContentContainer>
                <Filter />
                <Table />
            </ContentContainer>
        </>
    );
};
