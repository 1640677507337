import { createSlice } from '@reduxjs/toolkit';
import { qualificationAPI } from '../../api/modules/qualificationAPI';
import { adminSchedulerAPI } from '../../api/modules/adminSchedulerAPI';

export const qualificationSlice = createSlice({
    name: 'qualification',

    initialState: {
        detail_is_open: false,
        users_fetching: false,
        qualifications_fetching: false,

        users: {
            list: [],
            count: null,
            total_count: null,
        },
        user_role: null,

        qualifications_list: [],
        qualifications_period_list: null,
        qualification_error: null,

        filter_fetch: {
            venues: [],
            departments: [],
            qualifications: [],
        },

        filter: {
            page: 1,
            status: [],
            departments: [],
            venues: [],
            qualifications: [],
            filter_user_name: '',
        },
    },

    reducers: {
        set_detail_open: (state, action) => {
            state.detail_is_open = action.payload;
        },

        //! users
        set_users: (state, action) => {
            state.users.list.push(...action.payload.user_qualifications);
            state.users.count = action.payload.count;
            state.users.total_count = action.payload.total_count;
        },

        set_user_role: (state, action) => {
            state.user_role = action.payload;
        },

        clear_users: state => {
            state.users.list = [];
            state.users.count = null;
            state.users.total_count = null;
        },

        set_users_fetching: (state, action) => {
            state.users_fetching = action.payload;
        },

        add_user_qualification: (state, action) => {
            state.users.list = state.users.list.map(el =>
                el.user_id === action.payload.user_id ? { ...el, ...el.qualifications.push(action.payload) } : { ...el }
            );
        },

        update_user_qualification: (state, action) => {
            state.users.list = state.users.list.map(el =>
                Number(el.user_id) === Number(action.payload.user_id)
                    ? {
                          ...el,
                          qualifications: el.qualifications.map(qual =>
                              Number(qual.qualification_id) === Number(action.payload.qualification_id)
                                  ? {
                                        ...qual,
                                        date_end: `${action.payload.date_end}`,
                                    }
                                  : { ...qual }
                          ),
                      }
                    : { ...el }
            );
        },

        delete_user_qualification: (state, action) => {
            state.users.list =
                state.filter.status?.length === 0
                    ? state.users.list.map(el =>
                          Number(el.user_id) === Number(action.payload.user_id)
                              ? {
                                    ...el,
                                    qualifications: el.qualifications.filter(qual => Number(qual.qualification_id) !== Number(action.payload.qualification_id)),
                                }
                              : { ...el }
                      )
                    : state.users.list
                          .map(el =>
                              Number(el.user_id) === Number(action.payload.user_id)
                                  ? el.qualifications.length === 1
                                      ? {}
                                      : {
                                            ...el,
                                            qualifications: el.qualifications.filter(
                                                qual => Number(qual.qualification_id) !== Number(action.payload.qualification_id)
                                            ),
                                        }
                                  : { ...el }
                          )
                          .filter(el => Object.keys(el).length !== 0);
        },

        //!

        //! qualifications
        set_qualifications_list: (state, action) => {
            state.qualifications_list = action.payload;
        },

        set_qualifications_fetch: (state, action) => {
            state.qualifications_fetching = action.payload;
        },

        set_qualifications_period_list: (state, action) => {
            state.qualifications_period_list = action.payload;
        },

        delet_qualification_type: (state, action) => {
            state.qualifications_list = state.qualifications_list.filter(el => el.id !== action.payload);
        },

        update_qualification_type: (state, action) => {
            state.qualifications_list = state.qualifications_list.map(el =>
                el.id === action.payload.id ? { ...el, ...action.payload, files: [...el?.files, ...action.payload?.files] } : el
            );
        },

        add_qualifification_type: (state, action) => {
            state.qualifications_list.unshift(action.payload);
        },

        set_error_qualification: (state, action) => {
            state.qualification_error = action.payload;
        },

        add_new_qual_in_filter: (state, action) => {
            state.filter.qualifications = [...state.filter.qualifications, action.payload];
            state.filter_fetch.qualifications = [...state.filter_fetch.qualifications, action.payload];
        },

        delete_qual_in_filter: (state, action) => {
            state.filter.qualifications = state.filter.qualifications.filter(el => Number(el.value) !== Number(action.payload));
            state.filter_fetch.qualifications = state.filter_fetch.qualifications.filter(el => Number(el.value) !== Number(action.payload));
        },

        update_qual_in_filter: (state, action) => {
            state.filter.qualifications = state.filter.qualifications.map(el => (el.value === action.payload.value ? { ...action.payload } : el));
            state.filter_fetch.qualifications = state.filter_fetch.qualifications.map(el => (el.value === action.payload.value ? { ...action.payload } : el));
        },

        //!

        //! filter_fetch
        set_filter_fetch_departments: (state, action) => {
            state.filter_fetch.departments = action.payload;
        },

        set_filter_fetch_venues: (state, action) => {
            state.filter_fetch.venues = action.payload;
        },

        set_filter_fetch_qualifications: (state, action) => {
            state.filter_fetch.qualifications = action.payload;
        },
        //!

        //! filter
        set_filter: (state, action) => {
            state.filter[action.payload.type] = action.payload.value;
            state.filter.page = 1;
        },

        set_filter_page: (state, action) => {
            state.filter.page = action.payload;
        },

        set_filter_qualifications: (state, action) => {
            state.filter.qualifications = action.payload;
        },
        //!
    },
});

export const {
    set_filter_qualifications,
    update_qual_in_filter,
    delete_qual_in_filter,
    add_new_qual_in_filter,
    set_error_qualification,
    set_user_role,
    delete_user_qualification,
    update_user_qualification,
    add_user_qualification,
    set_filter_page,
    set_filter,
    set_filter_fetch_qualifications,
    set_filter_fetch_venues,
    set_filter_fetch_departments,
    add_qualifification_type,
    update_qualification_type,
    delet_qualification_type,
    set_qualifications_period_list,
    set_qualifications_list,
    set_qualifications_fetch,
    clear_users,
    set_detail_open,
    set_users,
    set_users_fetching,
} = qualificationSlice.actions;

// request users
export const usersQualificationsFetch =
    ({ username, auth_key_calendar, filter }) =>
    async dispatch => {
        await dispatch(set_users_fetching(true));

        try {
            const response = await qualificationAPI.getUsersQualifications.fetch(username, auth_key_calendar, filter);

            if (response.status === 200) {
                const res = await response.json();
                if (filter.page === 1) {
                    await dispatch(clear_users());
                }

                if (res.code === 0) {
                    await dispatch(set_users(res.data));
                }
            } else {
                await dispatch(clear_users());
            }
        } catch (e) {
            console.log('usersFetch error:', e);
        } finally {
            await dispatch(set_users_fetching(false));
        }
    };

export const qualificationsListFetch = () => async dispatch => {
    await dispatch(set_qualifications_fetch(true));

    try {
        const response = await qualificationAPI.getQualificationsList.fetch();
        if (response.status === 200) {
            const res = await response.json();
            if (res.code === 0) {
                const data = res.data.qualification_dict_list.map(item => ({
                    value: item.id,
                    label: item.abbreviation,
                    Header: item.abbreviation,
                    accessor: String(item.id),
                    name: item.name,
                }));

                await dispatch(set_filter_qualifications(data));
                await dispatch(set_filter_fetch_qualifications(data));
                await dispatch(set_qualifications_list(res.data.qualification_dict_list));
                await dispatch(set_error_qualification(null));
            }

            if (res.code === 38) {
                await dispatch(set_error_qualification(res.code));
            }
        }
    } catch (e) {
        console.log('qualificationsFetch error:', e);
    } finally {
        await dispatch(set_qualifications_fetch(false));
    }
};

export const qualificationPeriodFetch = () => async dispatch => {
    try {
        const response = await qualificationAPI.getQualificationsPeriodList.fetch();
        if (response.status === 200) {
            const res = await response.json();
            if (res.code === 0) {
                const data = res.data.map(el => ({
                    value: el.period_id,
                    label: el.period_name,
                }));
                await dispatch(set_qualifications_period_list(data));
            }
        }
    } catch (e) {
        console.log('qualificationsPeriodFetch error:', e);
    }
};

export const addQualificationTypeFetch =
    ({ username, auth_key_calendar, data }) =>
    async dispatch => {
        try {
            const response = await qualificationAPI.addQualificationType.fetch(username, auth_key_calendar, {
                ...data,
                change_certificate: data.change_certificate === true ? 1 : 0,
            });
            if (response.status === 200) {
                const res = await response.json();
                if (res.code === 62) {
                    await dispatch(set_error_qualification(res.code));
                    return res.code;
                } else if (res.code === 84) {
                    await dispatch(set_error_qualification(res.code));
                } else {
                    const newData = { ...data, id: res.data.passport_qualification_id };
                    const tableHeadInfo = {
                        value: res.data.passport_qualification_id,
                        label: data.abbreviation,
                        Header: data.abbreviation,
                        accessor: String(res.data.passport_qualification_id),
                        name: data.name,
                    };
                    const qualificationError = state => state.qualification.qualification_error;
                    if (qualificationError !== null) {
                        await dispatch(set_error_qualification(null));
                    }

                    await dispatch(add_qualifification_type(newData));
                    await dispatch(add_new_qual_in_filter(tableHeadInfo));
                }
            }
        } catch (e) {
            console.log('addQualificationTypeFetch error:', e);
        }
    };

export const deletQualificationTypeFetch =
    ({ username, auth_key_calendar, id }) =>
    async dispatch => {
        try {
            const response = await qualificationAPI.deleteQualificationType.fetch(username, auth_key_calendar, id);
            if (response.status === 200) {
                await dispatch(delet_qualification_type(id));
                await dispatch(delete_qual_in_filter(id));
            }
        } catch (e) {
            console.log('deletQualificationTypeFetch error:', e);
        }
    };

export const updateQualificationTypeFetch =
    ({ username, auth_key_calendar, data }) =>
    async dispatch => {
        try {
            const response = await qualificationAPI.updateQualificationType.fetch(username, auth_key_calendar, {
                ...data,
                change_certificate: data.change_certificate === true ? 1 : 0,
            });
            if (response.status === 200) {
                const res = await response.json();
                if (res.code === 62) {
                    await dispatch(set_error_qualification(res.code));
                    return res.code;
                } else if (res.code === 84) {
                    await dispatch(set_error_qualification(res.code));
                    return res.code;
                } else {
                    const tableHeadInfo = {
                        value: data.id,
                        label: data.abbreviation,
                        Header: data.abbreviation,
                        accessor: String(data.id),
                        name: data.name,
                    };
                    await dispatch(update_qualification_type(data));
                    await dispatch(update_qual_in_filter(tableHeadInfo));
                }
            }
        } catch (e) {
            console.log('updateQualificationTypeFetch error:', e);
        }
    };

export const departmantsFetch = lang => async dispatch => {
    try {
        const response = await adminSchedulerAPI.getDepartments.fetch(lang);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                if (res.data) {
                    const data = res.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }));

                    await dispatch(set_filter_fetch_departments(data));
                }
            }
        }
    } catch (e) {
        console.log('departmentsFetch error: ', e);
    }
};

export const venuesFetch =
    ({ username, auth_key_calendar }) =>
    async dispatch => {
        try {
            const response = await qualificationAPI.getUsersVenues.fetch(username, auth_key_calendar);
            if (response.status === 200) {
                const res = await response.json();
                if (res.code === 0) {
                    if (res.data.items) {
                        const data = res.data.items.map(item => ({
                            value: item.ID,
                            label: item.Name,
                        }));
                        await dispatch(set_filter_fetch_venues(data));
                    }
                }
            }
        } catch (e) {
            console.log('venuesFetch error: ', e);
        }
    };

export const addUserQualificationFetch =
    ({ username, auth_key_calendar, dataQualification }) =>
    async dispatch => {
        try {
            const response = await qualificationAPI.addUserQualification.fetch(username, auth_key_calendar, dataQualification);
            if (response.status === 200) {
                const res = await response.json();
                if (res.code === 83) {
                    await dispatch(set_error_qualification(res.code));
                } else {
                    const date_end = `${dataQualification.date_end} 00-00-00`;
                    await dispatch(
                        add_user_qualification({
                            ...dataQualification,
                            date_end,
                            qualification_id: res.data.qualification_id,
                        })
                    );
                }
            }
        } catch (e) {
            console.log('addUserQualificationFetch error: ', e);
        }
    };

export const updateUserQualificationFetch =
    ({ username, auth_key_calendar, dataQualification }) =>
    async dispatch => {
        try {
            const response = await qualificationAPI.updateUserQualification.fetch(username, auth_key_calendar, dataQualification);
            if (response.status === 200) {
                const date_end = `${dataQualification.date_end} 00-00-00`;
                await dispatch(update_user_qualification({ ...dataQualification, date_end }));
            }
        } catch (e) {
            console.log('updateUserQualificationFetch error: ', e);
        }
    };

export const deleteUserQualificationFetch =
    ({ username, auth_key_calendar, qualification_id, user_id }) =>
    async dispatch => {
        try {
            const response = await qualificationAPI.deleteUserQualification.fetch(username, auth_key_calendar, qualification_id);
            if (response.status === 200) {
                // const res = response.json();
                await dispatch(delete_user_qualification({ qualification_id, user_id }));
            }
        } catch (e) {
            console.log('deleteUserQualification error: ', e);
        }
    };

export const detail_is_open = state => state.qualification.detail_is_open;
export const usersQualifications = state => state.qualification.users;
export const qualificationsList = state => state.qualification.qualifications_list;
export const usersFetching = state => state.qualification.users_fetching;
export const qualifictionsPeriodList = state => state.qualification.qualifications_period_list;
export const filter_fetch = state => state.qualification.filter_fetch;
export const filter = state => state.qualification.filter;
export const userQualificationRole = state => state.qualification.user_role;
export const qualificationError = state => state.qualification.qualification_error;

export default qualificationSlice.reducer;
