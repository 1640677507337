export const book = Object.freeze({
    home: {
        link: '/',
        key: 'MAIN_PAGE',
    },

    //календарь
    calendar: {
        link: '/calendar',
        key: 'SIDEBAR_CALENDAR',
    },

    //административный планировщик
    admin_scheduler: {
        link: '/admin-scheduler',
        key: 'SIDEBAR_ADMINISTRATIVE_PLANNER',
    },

    //квалификации
    qualification: {
        link: '/qualification',
        key: 'QUALIFICATION',
    },
    qualification_list: {
        link: '/qualification/list',
        key: 'QUALIFICATION_LIST',
    },

    //бмс
    bms: {
        link: '/bms',
        key: 'BMS',
    },
    bms_venue: {
        link: '/bms/venues',
        key: 'BMS_VENUES',
    },
    bms_server: {
        link: '/bms/servers',
        key: 'BMS_SERVER',
    },
    bms_system: {
        link: '/bms/venues/:venueId/systems',
        key: 'BMS_SYSTEM',
    },
    bms_document: {
        link: '/bms/venues/:venueId/system/:systemId/documents',
        key: 'BMS_DOCUMENT',
    },
    bms_create_doc: {
        link: '/bms/venues/:venueId/system/:systemId/documents/create',
        key: 'BMS_CREATE',
    },
    bms_edit_doc: {
        link: '/bms/venues/:venueId/system/:systemId/documents/edit/:documentId',
        key: 'BMS_EDIT',
    },
    bms_detail_doc: {
        link: '/bms/venues/:venueId/system/:systemId/documents/:documentId',
        key: 'BMS_DETAIL',
    },

    //шахматка
    hospitality: {
        link: '/hospitality',
        key: 'HOSPITALITY',
    },
    hospitality_cleaners: {
        link: '/hospitality/cleaners',
        key: 'HOSPITALITY',
    },

    //задачи
    applications: {
        link: '/applications',
        key: 'SIDEBAR_APPLICATIONS',
    },
});
