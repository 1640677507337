import { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { useCookies } from 'react-cookie';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import { t } from 'i18next';

import { userQualificationRole as userQualificationRole_state } from '../../redux/slices/qualificationSlice';

export const CustomDatePicker = ({ dataAllInfo, handlerClickAddUpdate, handlerClickDelete, date_end }, props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [date, setDate] = useState(null);
    const [cookie] = useCookies();
    const lang = cookie.i18next;

    const userQualificationRole = useSelector(userQualificationRole_state);

    const dateStartPars = date_end ? date_end.split(' ')[0].split('-') : Date.now();
    const dateStart = new Date(dateStartPars[0], dateStartPars[1] - 1, Number(dateStartPars[2]));

    const handlerClearDate = dataAllInfo => {
        handlerClickDelete(dataAllInfo);
        setIsOpen(false);
    };

    const handlerAddUpdateDate = dataAllInfo => {
        if (date === null) {
            setIsOpen(false);
            return;
        }
        handlerClickAddUpdate(date, dataAllInfo);
        setIsOpen(false);
    };

    const CustomInput = forwardRef(({ value, onClick }, ref) => {
        return (
            <div className="svg_box" onClick={onClick} ref={ref}>
                <svg width="12" height="6" viewBox="0 0 10 5" fill="none">
                    <path d="M5 5L0 0H10L5 5Z" fill="none" />
                </svg>
            </div>
        );
    });

    const DropContainer = ({ children }) => {
        return (
            <>
                <div className="qualifiction_datepicker bg-white z-40 shadow-lg rounded ">
                    <CalendarContainer>
                        <>
                            <div className="relative">{children}</div>
                            <div className=" justify-between">
                                <div>
                                    {(userQualificationRole === 'administrator' || userQualificationRole === 'manager') && dataAllInfo.dateOfQual !== undefined ? (
                                        <button type="button" className="ml-3 mb-2 text-status_text-new" onClick={() => handlerClearDate(dataAllInfo)}>
                                            {t('DELETE_DATE')}
                                        </button>
                                    ) : (
                                        ''
                                    )}

                                    {(userQualificationRole === 'administrator' || userQualificationRole === 'manager' || (userQualificationRole === 'editor' && date_end === undefined)) && (
                                        <button type="button" className="rounded ml-24 mb-2 pr-2 pl-2 bg-status_text-new text-white" onClick={() => handlerAddUpdateDate(dataAllInfo)}>
                                            {t('OK')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </>
                    </CalendarContainer>
                </div>
            </>
        );
    };
    return (
        <DatePicker
            {...props}
            selected={date_end ? new Date(dateStart) : Date.now() + 172800000}
            minDate={Date.now() + 172800000}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            // withPortal
            scrollableYearDropdown
            onChange={date => setDate(date)}
            customInput={<CustomInput />}
            calendarContainer={DropContainer}
            onInputClick={() => setIsOpen(true)}
            onClickOutside={() => setIsOpen(false)}
            open={isOpen}
            locale={lang === 'ru' ? ru : en}
        />
    );
};
