import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../api/functions';
import { SimpleSelect } from '../../common/widgets/SimpleSelect';
import { ButtonRectangular } from '../../common/btn/ButtonRectangular';
import {
    getRooms,
    getTypicalTasksList,
    rooms as rooms_state,
    workers as workers_state,
    task_list as task_list_state,
    createTask,
} from '../../../redux/slices/hospitalitySlice';

const initialState = {
    room: null,
    task: null,
    worker: null,
    amount: '',
};

export const TaskPane = ({ isOpen, setIsOpen }) => {
    const [taskFields, setTaskFields] = useState(initialState);

    const dispatch = useDispatch();
    const rooms = useSelector(rooms_state);
    const workers = useSelector(workers_state);
    const task_list = useSelector(task_list_state);

    useEffect(() => {
        if (isOpen) {
            dispatch(getRooms());
            dispatch(getTypicalTasksList());
        }
    }, [isOpen]);

    const closePopupHandler = () => {
        setIsOpen(false);
        setTaskFields(initialState);
    };

    const createTaskHandler = () => {
        const data = {
            office_id: taskFields.room.value,
            user_id: taskFields.worker?.value ? taskFields.worker.value : null,
            typical_id: taskFields.task.value,
            typical_amount: taskFields.amount,
        };
        const status = dispatch(createTask({ data }));
        if (status) {
            toast.success('Задача создана', toastOptions);
            setIsOpen(false);
            setTaskFields(initialState);
        } else {
            toast.error('Ошибка при сохранении. Пожалуйста, попробуйте еще раз', toastOptions);
        }
    };

    const rooms_options = rooms.map(room => ({ label: room.room_number, value: room.room_id }));
    const task_options = task_list.map(task => ({ label: task.title, value: task.id, numeric: task.is_numerical }));
    const workers_options = workers.filter(worker => worker.state === 1).map(worker => ({ label: worker.name, value: worker.id }));

    return (
        <SlidingPane
            isOpen={isOpen}
            hideHeader={true}
            onRequestClose={closePopupHandler}
            overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100  h-full"
            width="25rem"
        >
            <div className="flex items-center justify-between w-full sticky -top-6 -mt-6 bg-white z-20 py-6">
                <div className="font-bold text-black text-xl">Создать задачу</div>
                <button className="w-9 h-9 p-0 rounded-full bg-transparent hover:bg-gray-100 flex items-center justify-center" onClick={closePopupHandler}>
                    <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                    </svg>
                </button>
            </div>

            {/* room number */}
            <div className="mb-6">
                <p className="text-sm font-medium mb-2">Номер комнаты</p>

                <SimpleSelect
                    isSearchable={true}
                    options={rooms_options}
                    placeholder="Выберите номер комнаты"
                    value={taskFields.room}
                    callback={val => val && setTaskFields(taskFields => ({ ...taskFields, room: { label: val.label, value: val.value } }))}
                    className="w-80"
                />
            </div>

            {/* task name */}
            <div className="mb-6">
                <p className="text-sm font-medium mb-2">Задача</p>

                <SimpleSelect
                    isSearchable={true}
                    options={task_options}
                    placeholder="Выберите задачу"
                    value={taskFields.task}
                    callback={val =>
                        val && setTaskFields(taskFields => ({ ...taskFields, task: { label: val.label, value: val.value, numeric: val.numeric } }))
                    }
                    className="w-80"
                />
            </div>

            {/* task amount */}
            {taskFields?.task?.numeric && (
                <div className="mb-6">
                    <p className="text-sm font-medium mb-2">Количество</p>
                    <input
                        type="number"
                        value={taskFields.amount}
                        placeholder="Введите количество"
                        className="w-80 h-9 p-2 border-gray-10 border text-sm"
                        onChange={e => setTaskFields(taskFields => ({ ...taskFields, amount: e.target.value }))}
                    />
                </div>
            )}

            {/* worker name */}
            <div>
                <p className="text-sm font-medium mb-2">Исполнитель</p>

                <SimpleSelect
                    isSearchable={true}
                    options={workers_options}
                    placeholder="Выберите исполнителя"
                    value={taskFields.worker}
                    callback={val => val && setTaskFields(taskFields => ({ ...taskFields, worker: { label: val.label, value: val.value } }))}
                    className="w-80"
                />
            </div>

            {/* submit/cancel button */}
            <div className="h-16 w-full fixed bottom-0 left-0 bg-white border-t border-t-gray-10">
                <div className="w-full h-full px-4 flex flex-row items-center">
                    <ButtonRectangular className="bg-blue border-blue hover:bg-blue-500" onClick={createTaskHandler}>
                        Применить
                    </ButtonRectangular>
                    <ButtonRectangular
                        onClick={closePopupHandler}
                        className="bg-transparent border-transparent hover:bg-transparent text-gray hover:text-gray-20"
                    >
                        Отменить
                    </ButtonRectangular>
                </div>
            </div>
        </SlidingPane>
    );
};
