const getRenderDate = (date, lang, flag) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timezone: 'UTC',
    };
    const optionsApp = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timezone: 'UTC',
    };

    // deferred date
    if (date !== null && flag === 'deferred') {
        const hours = new Date(Date.now()).getHours();
        const minutes = String(new Date(Date.now()).getMinutes());
        if (lang === 'ru') {
            return (
                <>
                    <div>
                        {new Date(date.getFullYear(), date.getMonth(), date.getDate()).toLocaleString(lang, optionsApp).split(' ').slice(0, -1).join(' ')} {hours}:{minutes.padStart(2, 0)}
                    </div>
                    <div></div>
                </>
            );
        }
        if (lang === 'en') {
            return (
                <>
                    <div>
                        {new Date(date.getFullYear(), date.getMonth(), date.getDate()).toLocaleString(lang, optionsApp)} {hours}:{minutes.padStart(2, 0)}
                    </div>
                    <div></div>
                </>
            );
        }
    } else {
        const dateArr = date?.split(' ')[0].split('.');
        const dateTime = date?.split(' ')[1];

        // date in application page
        if (lang === 'ru' && date !== null && flag === 'app') {
            return (
                <>
                    <div>
                        {new Date(dateArr[2], dateArr[1] - 1, dateArr[0]).toLocaleString(lang, optionsApp).split(' ').slice(0, -1).join(' ')} {dateTime}
                    </div>
                </>
            );
        }
        if (lang === 'en' && date !== null && flag === 'app') {
            return (
                <>
                    <div>
                        {new Date(dateArr[2], dateArr[1] - 1, dateArr[0]).toLocaleString(lang, optionsApp)} {dateTime}
                    </div>
                </>
            );
        }

        // date in applications tabel
        if (lang === 'ru' && date !== null) {
            return (
                <>
                    <div>{new Date(dateArr[2], dateArr[1] - 1, dateArr[0]).toLocaleString(lang, options)}</div>
                    <div>{dateTime}</div>
                </>
            );
        }

        if (lang === 'en' && date !== null) {
            return (
                <>
                    <div>{new Date(dateArr[2], dateArr[1] - 1, dateArr[0]).toLocaleString(lang, options)}</div>
                    <div>{dateTime}</div>
                </>
            );
        }
    }
};

export default getRenderDate;
