import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { Loader } from '../../common/Loader';

import {
    getBMSSystemList,
    bms_systems_fetching as bms_systems_fetching_state,
    removeBMSSystemInVenue,
    active_system as active_system_state,
    clear_system_list,
    set_bms_doc_list,
} from '../../../redux/slices/bmsSlice';
import { useCheckRole } from '../../../hooks/useCheckRole';

export const SystemList = () => {
    const dispatch = useDispatch();
    const { venueId } = useParams();

    const bms_systems_fetching = useSelector(bms_systems_fetching_state);
    const active_system = useSelector(active_system_state);

    const isObjectDirectionsDelete = useCheckRole('bms', 'bms.object_directions.del');

    useEffect(() => {
        dispatch(set_bms_doc_list([]));
        if (active_system.length === 0) {
            dispatch(getBMSSystemList({ venueId }));
        }
        return () => dispatch(clear_system_list());
    }, [dispatch, venueId]);

    const handlerSystemRemoveClick = systemId => {
        dispatch(removeBMSSystemInVenue({ object_id: venueId, direction_id: systemId }));
    };

    if (bms_systems_fetching) {
        return (
            <div className="flex justify-center items-center w-52 h-28">
                <Loader />
            </div>
        );
    }

    return (
        <>
            {active_system.length ? (
                active_system.map(system => (
                    <div className="relative" key={`bms${system.id}`}>
                        <Link
                            to={`/bms/venues/${venueId}/system/${system.id}/documents`}
                            className="flex flex-col justify-center items-center overflow-hidden w-52 h-28 p-2 border-gray-10 hover:border-blue border-2 rounded-md bg-white hover:bg-blue-10"
                        >
                            {system.title && <div className="uppercase text-sm font-bold text-center line-clamp-2">{system.title}</div>}
                        </Link>
                        {isObjectDirectionsDelete && (
                            <button className="absolute top-2 right-2 cursor-pointer" onClick={() => handlerSystemRemoveClick(system.id)}>
                                <svg width="18" height="18" viewBox="0 0 18 18" className="hover:fill-red-500 fill-current">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12.75 6C13.1642 6 13.5 6.33579 13.5 6.75V14.25C13.5 15.4926 12.4926 16.5 11.25 16.5H6.75C5.50736 16.5 4.5 15.4926 4.5 14.25V6.75C4.5 6.33579 4.83579 6 5.25 6H12.75ZM12 7.5H6V14.25C6 14.6642 6.33579 15 6.75 15H11.25C11.6642 15 12 14.6642 12 14.25V7.5ZM6.75 2.25C6.75 1.83579 7.08579 1.5 7.5 1.5H10.5C10.9142 1.5 11.25 1.83579 11.25 2.25V3H14.25C14.6642 3 15 3.33579 15 3.75C15 4.16421 14.6642 4.5 14.25 4.5H3.75C3.33579 4.5 3 4.16421 3 3.75C3 3.33579 3.33579 3 3.75 3H6.75V2.25Z"
                                    />
                                </svg>
                            </button>
                        )}
                    </div>
                ))
            ) : (
                <div className="flex flex-col justify-center items-center overflow-hidden w-64 h-28 p-2">Нет выбранных систем</div>
            )}
        </>
    );
};
