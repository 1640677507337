import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Container } from './Container';
import { CustomDragLayer } from './CustomDragLayer';

export const CanvasSection = ({ nodes, editNodeHandler }) => {
    return (
        <DndProvider backend={HTML5Backend}>
            <div className="relative">
                <Container nodes={nodes} editNodeHandler={editNodeHandler} />
                <CustomDragLayer />
            </div>
        </DndProvider>
    );
};
