import { Link } from 'react-router-dom';

import { ContentContainer } from '../ContentContainer';

export const NoAccessPage = () => {
    return (
        <ContentContainer>
            <p className="mb-3">Нет прав для просмотра данной страницы</p>
            <p>
                <Link to="/" className="text-blue hover:underline">
                    Вернуться на главную
                </Link>
            </p>
        </ContentContainer>
    );
};
