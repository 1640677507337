import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import {
    bms_detail_history as bms_detail_history_state,
    bms_detail_history_fetching as bms_detail_history_fetching_state,
    getBMSDocHistory,
} from '../../../../../redux/slices/bmsSlice';

import { Loader } from '../../../../common/Loader';
import { TrendsTable } from './TrendsTable';

export const TrendsLog = ({ bmsDetail }) => {
    const dispatch = useDispatch();

    const bms_detail_history = useSelector(bms_detail_history_state);
    const bms_detail_history_fetching = useSelector(bms_detail_history_fetching_state);

    useEffect(() => {
        if (bmsDetail && bmsDetail.hasOwnProperty('id')) {
            dispatch(getBMSDocHistory({ data: { doc_id: bmsDetail.id } }));

            const interval = setInterval(async () => {
                await dispatch(getBMSDocHistory({ data: { doc_id: bmsDetail.id } }));
            }, 60000);
            return () => clearInterval(interval);
        }
    }, [bmsDetail]);

    return (
        <div id="trends">
            <div className="uppercase text-xl text-black font-semibold mb-4">Тренды</div>
            {bms_detail_history_fetching ? (
                <div className="flex w-full justify-center">
                    <Loader />
                </div>
            ) : (
                <div>
                    {bms_detail_history && bms_detail_history.hasOwnProperty('data') && bms_detail_history.data.length ? (
                        <TrendsTable data={{ columns: { ts: 'Время', ...bms_detail_history.columns }, data: bms_detail_history.data }} />
                    ) : (
                        <p>Нет данных</p>
                    )}
                </div>
            )}
        </div>
    );
};
