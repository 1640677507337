import { Link, useParams } from 'react-router-dom';

export const CreateDocumentButton = () => {
    const { venueId, systemId } = useParams();

    return (
        <Link
            to={`/bms/venues/${venueId}/system/${systemId}/documents/create`}
            className="flex flex-col items-center text-center overflow-hidden w-28 h-28 p-2 text-xs border-gray-10 hover:border-blue border-2 rounded-md bg-white hover:bg-blue-10 justify-end pb-2"
        >
            <svg width="25" height="25" viewBox="0 0 12 12" className="fill-current mb-2">
                <path d="M5.16602 11.8332V6.83317H0.166016V5.1665H5.16602V0.166504H6.83268V5.1665H11.8327V6.83317H6.83268V11.8332H5.16602Z" />
            </svg>
            <span>Новый документ</span>
        </Link>
    );
};
