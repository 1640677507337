import { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SimpleSelect } from '../../../../common/widgets/SimpleSelect';

import { setBMSControlValues } from '../../../../../redux/slices/bmsSlice';
import { url } from '../../../../../api/config';

export const ControlsSection = ({ controls, docId }) => {
    const dispatch = useDispatch();
    const containerSize = useRef({
        width: 1920,
        height: 540,
    });

    const [controlsSelectsValues, setControlsSelectsValues] = useState([]);

    useEffect(() => {
        if (controls) {
            const valuesArr = controls.map(control => {
                const correctValue = control.data.values.find(value => value.value === control.value);
                return { cn_id: control.cn_id, values: correctValue ? correctValue : control.data.values[0] };
            });
            setControlsSelectsValues(valuesArr);
        }
    }, [controls]);

    const handlerSetControlValue = (control, val) => {
        const data = { cn_id: control.cn_id, value: JSON.stringify(val), doc_id: docId };
        setControlsSelectsValues(() => controlsSelectsValues.map(el => (el.cn_id === control.cn_id ? { ...el, values: val } : el)));
        dispatch(setBMSControlValues({ data }));
    };

    return (
        <div
            className="border mb-1 p-2.5 flex gap-4 flex-wrap place-content-start bg-red-200"
            style={{ width: containerSize.current.width, maxHeight: containerSize.current.height, minHeight: '100px' }}
        >
            {controls.length !== 0 &&
                controls.map(control => (
                    <div key={control.cn_id} className="flex items-center gap-2">
                        <span className="text-sm">{control.data.title}</span>

                        {control.lib_data.subtype === 'list' && (
                            <SimpleSelect
                                value={controlsSelectsValues.length !== 0 ? controlsSelectsValues.find(el => el.cn_id === control.cn_id)?.values : ''}
                                options={control.data.values}
                                isDisabled={true}
                                callback={val => handlerSetControlValue(control, val)}
                                isClearable={false}
                                className="w-36 text-xs controlSelect"
                                placeholder="Выберете значение"
                            />
                        )}
                        {control.lib_data.subtype === 'button' && <img src={`${url}${control.lib_data.default_pic}`} alt={control.title} className="w-6 h-6" />}
                    </div>
                ))}
        </div>
    );
};
