import { root } from '../config';
const timeInMs = Date.now();

export const adminSchedulerAPI = Object.freeze({
    // получить список задач административного планировщика
    getScheduledTasks: {
        fetch: (username, auth_key_calendar, filter, lang) => {
            const formData = new FormData();
            formData.append('lang', lang);

            for (const item in filter) {
                if (filter[item] !== '') {
                    formData.append(item, filter[item]);
                }
            }
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            return fetch(
                `${root}?r=api/admin-scheduler/get-schedule-tasks&app_id=123&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // добавить задачу административного планировщика
    addScheduledTask: {
        fetch: (username, auth_key_calendar, data, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                if (item === 'files') {
                    data[item].forEach((file, index) => formData.append(`file${index}`, file));
                } else {
                    formData.append(item, data[item]);
                }
            }

            return fetch(
                `${root}?r=api/admin-scheduler/add-schedule-task&app_id=123&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // получить задачу административного планировщика
    getScheduledTask: {
        fetch: (username, auth_key_calendar, id, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('id', id);
            formData.append('lang', lang);

            return fetch(
                `${root}?r=api/admin-scheduler/get-schedule-task&app_id=123&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // получить список объектов
    getVenues: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            return fetch(
                `${root}?r=api/admin-scheduler/get-responsible-venues&app_id=123&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // получить список администраторов, которые создавали или редактировали задачи в административном планировщике
    getCreators: {
        fetch: lang => {
            const formData = new FormData();
            formData.append('lang', lang);

            return fetch(
                `${root}?r=api/admin-scheduler/get-admin-list&app_id=123&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // получить список департаментов (направлений)
    getDepartments: {
        fetch: lang => {
            const formData = new FormData();
            formData.append('lang', lang);
            return fetch(
                `${root}?r=api/admin-scheduler/get-department-list&app_id=123&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    // изменить статус задачи административного планировщика
    changeStatusScheduledTask: {
        fetch: (username, auth_key_calendar, data, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(
                `${root}?r=api/admin-scheduler/change-status&app_id=123&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },
});
