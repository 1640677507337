import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';

import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import {
    buildingFetch,
    directionFetch,
    filter as filter_state,
    filter_fetch as filter_fetch_state,
    floorFetch,
    officeFetch,
    set_filter,
    userFetch,
    venueFetch,
} from '../../redux/slices/calendarSlice';

import { t } from 'i18next';
import { CalendarPrintModal } from './filter/CalendarPrintModal';

export const Filter = ({ link, type }) => {
    const dispatch = useDispatch();

    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const filterFetch = useSelector(filter_fetch_state);
    const filter = useSelector(filter_state);

    const menuRef = useRef(null);

    const [listening, setListening] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [childMenuIsOpen, setChildMenuIsOpen] = useState(false);
    const [searchLine, setSearchLine] = useState('');
    const [cookies] = useCookies();
    const [printModalOpen, setPrintModalOpen] = useState(false);

    const lang = cookies.i18next;

    let dropdownIntervalId = null;
    const closeChildMenu = () => {
        if (dropdownIntervalId) {
            clearInterval(dropdownIntervalId);
        }
        dropdownIntervalId = setTimeout(() => setChildMenuIsOpen(false), 300);
    };
    const showChildMenu = () => {
        if (dropdownIntervalId) {
            clearInterval(dropdownIntervalId);
        }
        setChildMenuIsOpen(true);
    };

    const listenForOutsideClick = (listening, setListening, menuRef, setIsOpen) => () => {
        if (listening) return;
        setListening(true);

        document.addEventListener(`click`, evt => {
            const cur = menuRef.current;
            if (!menuRef.current) return;

            const node = evt.target;
            if (cur.contains(node)) return;
            setIsOpen(null);
        });
    };
    useEffect(listenForOutsideClick(listening, setListening, menuRef, setMenuIsOpen));

    useEffect(() => {
        if (username && auth_key_calendar) {
            dispatch(venueFetch({ username, auth_key_calendar, lang }));
        }
    }, [dispatch, username, auth_key_calendar, lang]);

    useEffect(() => {
        if (username && auth_key_calendar) {
            dispatch(buildingFetch({ username, auth_key_calendar, venue_id: filter.venue?.value, lang }));
        }
    }, [dispatch, username, auth_key_calendar, filter.venue, cookies.i18next]);

    useEffect(() => {
        if (username && auth_key_calendar) {
            dispatch(floorFetch({ username, auth_key_calendar, building_id: filter.building?.value, lang }));
        }
    }, [dispatch, username, auth_key_calendar, filter.building, cookies.i18next]);

    useEffect(() => {
        if (username && auth_key_calendar) {
            dispatch(officeFetch({ username, auth_key_calendar, building_id: filter.building?.value, floor_id: filter.floor?.value, lang }));
        }
    }, [dispatch, username, auth_key_calendar, filter.building, filter.floor, cookies.i18next]);

    useEffect(() => {
        if (username && auth_key_calendar && link === 'equipments') {
            dispatch(directionFetch({ username, auth_key_calendar, building_id: filter.building?.value, floor_id: filter.floor?.value, office_id: filter.office?.value, lang }));
        }
    }, [dispatch, username, auth_key_calendar, link, filter.building, filter.floor, filter.office, cookies.i18next]);

    useEffect(() => {
        if (username && auth_key_calendar) {
            dispatch(
                userFetch({ username, auth_key_calendar, venue_id: filter.venue?.value, building_id: filter.building?.value, floor_id: filter.floor?.value, office_id: filter.office?.value, lang })
            );
        }
    }, [dispatch, username, auth_key_calendar, filter.venue, filter.building, filter.floor, filter.office, cookies.i18next]);

    const setFilter = ({ type, value }) => {
        dispatch(set_filter({ type, value }));
    };

    //search line
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            setFilter({ type: 'search', value: searchLine });
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchLine]);

    //print
    const printHandler = () => {
        if (filter.venue?.value && filter.building?.value) {
            setPrintModalOpen(true);
        } else {
            toast.error(t('CALENDAR_ERROR_SELECT'), {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        }
        setMenuIsOpen(false);
    };

    return (
        <>
            <div className="flex flex-wrap gap-2 w-full bg-white rounded-lg border border-gray-10 p-2 mb-6">
                <div className="h-10 w-32 relative">
                    <input
                        type="text"
                        placeholder={t('CALENDAR_SEARCH')}
                        onChange={e => setSearchLine(e.target.value)}
                        className="box-border w-full h-full text-sm rounded-1 bg-gray-100 border-gray-10 py-0 pl-2 pr-9"
                    />

                    <svg className="w-[20px] h-[20px] absolute right-[10px] top-[9px]" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.33333 14.1667C11.555 14.1667 14.1667 11.555 14.1667 8.33333C14.1667 5.11167 11.555 2.5 8.33333 2.5C5.11167 2.5 2.5 5.11167 2.5 8.33333C2.5 11.555 5.11167 14.1667 8.33333 14.1667Z"
                            stroke="#B3B3BC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path d="M12.5 12.5L17.5 17.5" stroke="#B3B3BC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>

                <div className="relative h-10 w-24">
                    <button
                        className="absolute left-0 top-0 w-8 h-full flex items-center justify-center text-gray-10 hover:text-gray-400"
                        type="button"
                        onClick={() => setFilter({ type: 'year', value: filter.year - 1 })}
                    >
                        <svg className="w-5 fill-current" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5061 3.25065L6.2561 8.39352C5.91397 8.72867 5.91397 9.27038 6.2561 9.60553L11.5061 14.7484C11.8483 15.0835 12.4013 15.0835 12.7434 14.7484C13.0855 14.4133 13.0855 13.8715 12.7434 13.5364L8.11199 8.99952L12.7434 4.46265C13.0855 4.12751 13.0855 3.5858 12.7434 3.25065C12.5728 3.08351 12.3488 2.99951 12.1248 2.99951C11.9008 2.99951 11.6768 3.08351 11.5061 3.25065Z" />
                        </svg>
                    </button>
                    <input type="text" disabled value={filter.year} className="box-border w-full h-full text-sm rounded-1 border-gray-10 text-center p-0 hover:border-gray-10" />
                    <button
                        className="absolute right-0 top-0 w-8 h-full flex items-center justify-center text-gray-10 hover:text-gray-400"
                        type="button"
                        onClick={() => setFilter({ type: 'year', value: filter.year + 1 })}
                    >
                        <svg className="w-5 fill-current" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.49386 14.7493L12.7439 9.60648C13.086 9.27133 13.086 8.72962 12.7439 8.39447L7.49386 3.2516C7.15173 2.91646 6.59872 2.91646 6.2566 3.2516C5.91447 3.58675 5.91447 4.12846 6.2566 4.46361L10.888 9.00048L6.2566 13.5373C5.91447 13.8725 5.91447 14.4142 6.2566 14.7493C6.42722 14.9165 6.65122 15.0005 6.87523 15.0005C7.09923 15.0005 7.32323 14.9165 7.49386 14.7493Z" />
                        </svg>
                    </button>
                </div>

                <Select
                    onChange={value => setFilter({ type: 'venue', value })}
                    options={filterFetch.venue}
                    value={filter.venue}
                    isClearable={true}
                    isSearchable={true}
                    placeholder={t('CALENDAR_OBJECT')}
                    classNamePrefix="filter"
                    className="select text-sm w-40"
                />
                <Select
                    onChange={value => setFilter({ type: 'building', value })}
                    options={filterFetch.building}
                    value={filter.building}
                    isClearable={true}
                    isSearchable={true}
                    placeholder={t('CALENDAR_BUILDING')}
                    classNamePrefix="filter"
                    className="select text-sm w-40"
                />
                <Select
                    onChange={value => setFilter({ type: 'floor', value })}
                    options={filterFetch.floor}
                    value={filter.floor}
                    isClearable={true}
                    isSearchable={true}
                    placeholder={t('CALENDAR_FLOOR')}
                    classNamePrefix="filter"
                    className="select text-sm w-36"
                />
                <Select
                    onChange={value => setFilter({ type: 'office', value })}
                    options={filterFetch.office}
                    value={filter.office}
                    isClearable={true}
                    isSearchable={true}
                    placeholder={t('CALENDAR_ROOM')}
                    classNamePrefix="filter"
                    className="select text-sm w-40"
                />
                {link === 'equipments' && (
                    <Select
                        onChange={value => setFilter({ type: 'direction', value })}
                        options={filterFetch.direction}
                        value={filter.direction}
                        isClearable={true}
                        isSearchable={true}
                        placeholder={t('CALENDAR_SISTEM')}
                        classNamePrefix="filter"
                        className="select text-sm w-40"
                    />
                )}
                <Select
                    onChange={value => setFilter({ type: 'user', value })}
                    options={filterFetch.user}
                    value={filter.user}
                    isClearable={true}
                    isSearchable={true}
                    placeholder={t('CALENDAR_EMPLOYEE')}
                    classNamePrefix="filter"
                    className="select text-sm w-40"
                />
                <div className="relative" ref={menuRef}>
                    <button className="w-10 h-10 flex items-center justify-center rounded-1 border border-gray-10 hover:border-gray-30" onClick={() => setMenuIsOpen(!menuIsOpen)}>
                        <svg className="w-4 fill-current" viewBox="0 0 20 4" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" />
                            <circle cx="10" cy="2" r="2" transform="rotate(-90 10 2)" />
                            <circle cx="18" cy="2" r="2" transform="rotate(-90 18 2)" />
                        </svg>
                    </button>

                    <ul
                        className={`${
                            !menuIsOpen ? 'hidden' : ''
                        } absolute -right-[1px] mt-1 z-20 bg-blue-20 rounded-lg after:content-[""] after:absolute after:w-[calc(100%-20px)] after:h-[5px] after:left-[10px] before:content-[""] after:bg-blue-200 after:rounded-b-lg before:absolute before:right-[13px] before:-top-[18px] before:border-[9px] before:border-transparent before:border-b-[9px] before:border-b-blue-20`}
                    >
                        <li className="relative" onMouseOver={showChildMenu} onMouseOut={closeChildMenu}>
                            <Link className="flex items-center justify-start p-3 font-bold text-white rounded-t-lg transition-opacity hover:bg-blue-30 opacity-80 hover:opacity-100" to="#">
                                <svg className="w-[18px] h-[18px] fill-current mr-4" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.5008 1.79999C14.9919 1.79999 16.2008 3.00882 16.2008 4.49999V13.5C16.2008 14.9912 14.9919 16.2 13.5008 16.2H4.50078C3.00961 16.2 1.80078 14.9912 1.80078 13.5V4.49999C1.80078 3.00882 3.00961 1.79999 4.50078 1.79999H13.5008ZM8.10078 9.89999H3.60078V13.5C3.60078 13.997 4.00372 14.4 4.50078 14.4H8.10078V9.89999ZM14.4008 9.89999H9.90078V14.4H13.5008C13.9978 14.4 14.4008 13.997 14.4008 13.5V9.89999ZM8.10078 3.59999H4.50078C4.00372 3.59999 3.60078 4.00293 3.60078 4.49999V8.09999H8.10078V3.59999ZM13.5008 3.59999H9.90078V8.09999H14.4008V4.49999C14.4008 4.00293 13.9978 3.59999 13.5008 3.59999Z" />
                                </svg>
                                <span className="whitespace-nowrap">{t('CALENDAR_VIEW')}</span>
                                <svg className="w-4 h-4 fill-current ml-auto" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.29275 12.2933C3.90175 12.6842 3.90175 13.3162 4.29275 13.7072C4.68375 14.0982 5.31575 14.0982 5.70675 13.7072L10.7068 8.70725C11.0978 8.31625 11.0978 7.68425 10.7068 7.29325L5.70675 2.29325C5.31575 1.90225 4.68375 1.90225 4.29275 2.29325C3.90175 2.68425 3.90175 3.31625 4.29275 3.70725L8.58575 8.00025L4.29275 12.2933Z" />
                                </svg>
                            </Link>

                            <ul className={`${!childMenuIsOpen ? 'hidden' : ''} absolute w-32 -left-[8.5rem] top-0 bg-blue-20 z-20 mr-2 rounded-lg rounded-tr-none`}>
                                <li>
                                    <Link
                                        to={`?link=${link}&type=days`}
                                        onClick={() => setMenuIsOpen(false)}
                                        className="flex items-center justify-start p-3 font-bold text-white rounded-tl-lg transition-opacity hover:bg-blue-30 opacity-80 hover:opacity-100"
                                    >
                                        <span>{t('CALENDAR_VIEW_DAYS')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={`?link=${link}&type=weeks`}
                                        onClick={() => setMenuIsOpen(false)}
                                        className="flex items-center justify-start p-3 font-bold text-white transition-opacity hover:bg-blue-30 opacity-80 hover:opacity-100"
                                    >
                                        <span>{t('CALENDAR_VIEW_WEEKS')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={`?link=${link}&type=year`}
                                        onClick={() => setMenuIsOpen(false)}
                                        className="flex items-center justify-start p-3 font-bold text-white rounded-b-lg transition-opacity hover:bg-blue-30 opacity-80 hover:opacity-100"
                                    >
                                        <span>{t('CALENDAR_VIEW_YEAR')}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="flex items-center justify-start p-3 font-bold text-white rounded-b-lg transition-opacity hover:bg-blue-30 opacity-80 hover:opacity-100"
                                onClick={printHandler}
                            >
                                <svg className="w-[18px] h-[18px] fill-transparent stroke-current mr-4" viewBox="0 0 18 18">
                                    <path
                                        d="M5.25 3.55556V2C5.25 1.86193 5.36193 1.75 5.5 1.75H12.5C12.6381 1.75 12.75 1.86193 12.75 2V3.55556C12.75 3.69363 12.6381 3.80556 12.5 3.80556H5.5C5.36193 3.80556 5.25 3.69363 5.25 3.55556Z"
                                        strokeWidth="1.5"
                                    />
                                    <path
                                        d="M2.25 12.4445V6.44446C2.25 6.30639 2.36193 6.19446 2.5 6.19446H15.5C15.6381 6.19446 15.75 6.30639 15.75 6.44446V12.4445C15.75 12.5818 15.6381 12.6945 15.4992 12.6945H2.50078C2.36195 12.6945 2.25 12.5818 2.25 12.4445Z"
                                        strokeWidth="1.5"
                                    />
                                    <rect x="5.25" y="11.6667" width="7.5" height="1.77778" fill="#3F8CFE" />
                                    <path
                                        d="M5.25 16V10.8889C5.25 10.7508 5.36193 10.6389 5.5 10.6389H12.5C12.6381 10.6389 12.75 10.7508 12.75 10.8889V16C12.75 16.1381 12.6381 16.25 12.5 16.25H5.5C5.36193 16.25 5.25 16.1381 5.25 16Z"
                                        strokeWidth="1.5"
                                    />
                                    <circle cx="13.5" cy="8.25" r="0.75" fill="#ffffff" />
                                </svg>

                                <span className="whitespace-nowrap">{t('CALENDAR_PRINT')}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <CalendarPrintModal isOpen={printModalOpen} setOpen={setPrintModalOpen} link={link} type={type} />
        </>
    );
};
