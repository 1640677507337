import { useState } from 'react';

import { SimpleSelect } from '../../../../../common/widgets/SimpleSelect';

export const ListControl = ({ nodeOptions, setValues, setWritePoint }) => {
    const [data, setData] = useState({
        writePoint: null,
        values: [{ label: '', value: '', idx: Date.now() }],
        boolean: { true: '', false: '' },
    });

    return (
        <>
            <SimpleSelect
                isClearable={false}
                options={nodeOptions}
                placeholder="Выберите точку"
                value={data.writePoint}
                callback={val => {
                    if (val === null) {
                        return;
                    }
                    setWritePoint(val.value);
                    setData({ values: [{ label: '', value: '', idx: Date.now() }], boolean: { true: '', false: '' }, writePoint: val });
                    setValues([]);
                }}
                className="w-56 mr-5"
            />
            <div>
                <div className="p-2 border w-72">
                    <div className="flex justify-around border-b m-2">
                        <span>Значение</span>
                        <span>Название</span>
                    </div>
                    <div>
                        {data.writePoint?.datatype === 'boolean' ? (
                            <>
                                <div className="relative mb-1">
                                    <div className="flex justify-around">
                                        <span className="text-sm">true</span>

                                        <input
                                            type="text"
                                            value={data.boolean.true}
                                            placeholder="название"
                                            className="w-24 h-6 text-xs border-0 p-1 ml-10"
                                            onChange={e => {
                                                setValues({ ...data.boolean, true: e.target.value.trim() });
                                                setData({
                                                    ...data,
                                                    boolean: { ...data.boolean, true: e.target.value },
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="relative mb-1">
                                    <div className="flex justify-around">
                                        <span className="text-sm">false</span>

                                        <input
                                            type="text"
                                            value={data.boolean.false}
                                            placeholder="название"
                                            className="w-24 h-6 text-xs border-0 p-1 ml-10"
                                            onChange={e => {
                                                setValues({ ...data.boolean, false: e.target.value.trim() });
                                                setData({
                                                    ...data,
                                                    boolean: { ...data.boolean, false: e.target.value },
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            data.values.map((value, idx) => (
                                <div className="relative mb-1" key={value.idx}>
                                    <div className="flex justify-around">
                                        <input
                                            type="text"
                                            value={data.values[idx].value}
                                            disabled={!data.writePoint}
                                            placeholder="значение"
                                            className="w-20 h-6 text-xs border-0 p-1"
                                            onChange={e => {
                                                setValues(data.values.map(el => (el.idx === value.idx ? { ...el, value: e.target.value.trim() } : el)));
                                                setData({
                                                    ...data,
                                                    values: data.values.map(el => (el.idx === value.idx ? { ...el, value: e.target.value } : el)),
                                                });
                                            }}
                                        />
                                        <input
                                            type="text"
                                            value={data.values[idx].label}
                                            disabled={!data.writePoint}
                                            placeholder="название"
                                            className="w-24 h-6 text-xs border-0 mr-1 p-1"
                                            onChange={e => {
                                                setValues(data.values.map(el => (el.idx === value.idx ? { ...el, label: e.target.value.trim() } : el)));
                                                setData({
                                                    ...data,
                                                    values: data.values.map(el => (el.idx === value.idx ? { ...el, label: e.target.value } : el)),
                                                });
                                            }}
                                        />
                                        {data.values.length > 1 && (
                                            <div
                                                className="absolute top-0.5 right-3 cursor-pointer text-red-600"
                                                onClick={() => {
                                                    setValues(data.values.filter(el => el.idx !== value.idx));
                                                    setData({ ...data, values: data.values.filter(el => el.idx !== value.idx) });
                                                }}
                                            >
                                                –
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
                {data.values.length < 6 && data.writePoint?.datatype !== 'boolean' && (
                    <span
                        className="text-sm underline cursor-pointer"
                        onClick={() => setData({ ...data, values: [...data.values, { value: '', label: '', idx: Date.now() }] })}
                    >
                        Добавить поле
                    </span>
                )}
            </div>
        </>
    );
};
