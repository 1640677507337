import { url } from '../../../../../api/config';

export const LibraryItem = ({ item, onClick }) => {
    return (
        <div
            className="flex flex-col p-2 select-none w-40 h-40 gap-4 border-2 border-gray-10 rounded-md bg-white relative cursor-pointer hover:border-blue hover:bg-blue-10"
            onClick={() => onClick(item)}
        >
            <div className="h-full">
                <p className="text-xs font-semibold line-clamp-2">{item.title}</p>
            </div>

            {item.lib_data.hasOwnProperty('default_pic') && item.lib_data.default_pic && (
                <div className="h-20 flex-shrink-0">
                    <img src={`${url}${item.lib_data.default_pic}`} alt={item.title} className="w-full h-full object-contain block" />
                </div>
            )}
        </div>
    );
};
