import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { EditNodeContextMenu } from './EditNodeContextMenu';
import { update_bms_document_node } from '../../../../../redux/slices/bmsSlice';

export const Box = ({ id, children, node, editNodeHandler }) => {
    const dispatch = useDispatch();
    const boxRef = useRef(null);

    const [width, setWidth] = useState(node.position.width);
    const [height, setHeight] = useState(node.position.height);
    const [degree, setDegree] = useState(node.position.degree);
    const [mouseDown, setMouseDown] = useState(false);
    const [rotateTouch, setRotateTouch] = useState(false);

    const [contextMenuIsOpen, setContextMenuIsOpen] = useState(false);

    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: 'box',
            canDrag: !mouseDown,
            item: {
                id,
                left: node.position.left,
                top: node.position.top,
                width,
                height,
                degree,
                children,
            },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [id, node.position.left, node.position.top, width, height, mouseDown, degree]
    );

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    useEffect(() => {
        if (boxRef.current) {
            setWidth(`${boxRef.current.clientWidth}px`);
            setHeight(`${boxRef.current.clientHeight}px`);

            const observer = new MutationObserver(() => {
                if (boxRef.current) {
                    setWidth(`${boxRef.current.clientWidth}px`);
                    setHeight(`${boxRef.current.clientHeight}px`);
                }
            });
            observer.observe(boxRef.current, {
                attributes: true,
                characterData: true,
            });
            return () => observer.disconnect();
        }
    }, [boxRef]);

    const mouseMoveListener = e => {
        if (boxRef.current) {
            const boxBoundingRect = boxRef.current.getBoundingClientRect();
            const boxCenter = {
                x: boxBoundingRect.left + boxBoundingRect.width / 2,
                y: boxBoundingRect.top + boxBoundingRect.height / 2,
            };
            const degree = Math.atan2(e.pageX - boxCenter.x, -(e.pageY - boxCenter.y)) * (180 / Math.PI) - 45;

            setDegree(degree);
        }
    };
    const mouseUpListener = () => {
        if (mouseDown) {
            setMouseDown(false);
        }
    };

    useEffect(() => {
        if (mouseDown) {
            document.addEventListener('mousemove', mouseMoveListener);
            document.addEventListener('mouseup', mouseUpListener);
            document.getElementById('root').style.cursor = 'move';
        }
        return () => {
            if (mouseDown) {
                document.removeEventListener('mousemove', mouseMoveListener);
                document.removeEventListener('mouseup', mouseUpListener);
                document.getElementById('root').style.cursor = 'default';
            }
        };
    }, [mouseDown]);

    useEffect(() => {
        if (isDragging) {
            setMouseDown(false);
        }
    }, [isDragging]);

    useEffect(() => {
        if (!mouseDown && rotateTouch) {
            const updateNode = { ...node, position: { ...node.position, degree } };
            dispatch(update_bms_document_node(updateNode));
        }
    }, [degree, mouseDown, rotateTouch]);

    const rightButtonMouseClick = e => {
        e.preventDefault();
        setContextMenuIsOpen(true);
    };

    return (
        <>
            <div
                className={`absolute cursor-move resize overflow-hidden border border-dashed active:border-amber-400 active:z-50 ${
                    contextMenuIsOpen ? 'border-amber-400' : 'border-transparent'
                } `}
                ref={el => {
                    drag(el);
                    boxRef.current = el;
                }}
                style={{
                    left: node.position.left,
                    top: node.position.top,
                    width: node.position.width,
                    height: node.position.height,
                    rotate: `${degree}deg`,
                    zIndex: `${node.position.hasOwnProperty('zIndex') ? `${node.position.zIndex}` : ''}`,
                }}
                onMouseUp={() => {
                    const updateNode = { ...node, position: { ...node.position, degree, width, height } };
                    dispatch(update_bms_document_node(updateNode));
                }}
                onContextMenu={e => rightButtonMouseClick(e, id)}
            >
                <div
                    className="absolute right-0 top-0 w-3 z-30"
                    onMouseDown={() => {
                        setMouseDown(true);
                        setRotateTouch(true);
                    }}
                >
                    <svg width="512" height="512" x="0" y="0" viewBox="0 0 64 64" className="w-full h-auto block fill-current">
                        <path d="m37.458 8.83 3.083-2.202c2.11-1.556-.154-4.75-2.324-3.256l-7.38 5.27c-.744.55-1.066 1.615-.652 2.462.138.29.343.535.585.743l7.378 5.756c2.09 1.58 4.51-1.498 2.461-3.155l-1.219-.95c8.896 3.027 15.306 11.357 15.306 21.139-1.238 29.657-44.15 29.666-45.392 0-.062-2.642-3.94-2.631-4 0C5.304 49.174 17.279 61 32 61c32.142-.886 36.505-44.965 5.458-52.17z" />
                    </svg>
                </div>
                {children}
            </div>
            <EditNodeContextMenu
                isOpen={contextMenuIsOpen}
                setIsOpen={setContextMenuIsOpen}
                buttonRef={boxRef}
                nodeId={id}
                node={node}
                editNodeHandler={editNodeHandler}
            />
        </>
    );
};
