import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { bms_servers_list as bms_servers_list_state, editBMSServer, removeBMSServer } from '../../../../redux/slices/bmsSlice';
import { useCheckRole } from '../../../../hooks/useCheckRole';

import { Popup } from '../../../common/popup/Popup';
import { EditServerPopupButton } from './EditServerPopupButton';
import { RemoveServerPopupButton } from './RemoveServerPopupButton';
import { OPCFields } from '../OPCFields';
import { VPNFields } from '../VPNFields';

export const EditServerPopup = ({ isOpenEdit, setIsOpenEdit, serverId, setServerId }) => {
    const dispatch = useDispatch();
    const bms_servers_list = useSelector(bms_servers_list_state);

    const [server, setServer] = useState(null);

    const isServersDelete = useCheckRole('bms', 'bms.servers.del');

    useEffect(() => {
        if (serverId && bms_servers_list.length !== 0) {
            setServer(bms_servers_list.find(server => server.id === serverId));
        }
    }, [bms_servers_list, serverId]);

    useEffect(() => {
        if (!isOpenEdit) {
            setServerId(null);
            setServer(null);
        }
    }, [isOpenEdit, setServerId]);

    const handleEditServer = () => {
        dispatch(editBMSServer({ data: server }));
        setIsOpenEdit(false);
        setServerId(null);
        setServer(null);
    };

    const handleRemoveServer = () => {
        dispatch(removeBMSServer({ id: serverId }));
        setIsOpenEdit(false);
        setServerId(null);
        setServer(null);
    };
    if (server) {
        return (
            <Popup isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} className="w-[700px] h-[430px]">
                <div>
                    <div className="flex flex-col items-center mb-4">
                        <label className="block mb-2">Название сервера</label>
                        <input
                            onInput={e => setServer({ ...server, title: e.target.value })}
                            value={server.title}
                            placeholder="введите название сервера"
                            type="text"
                            className="w-96 h-10 border-gray-70 hover:border-gray-30 text-sm px-2 mr-2"
                        />
                    </div>
                    <div className="flex">
                        <div className="w-1/2 flex flex-col items-center">
                            <OPCFields server={server} setServer={setServer} />
                        </div>
                        <div className="w-1/2 flex flex-col items-center">
                            <VPNFields server={server} setServer={setServer} />
                        </div>
                    </div>
                </div>
                <div className="flex justify-center gap-8">
                    <EditServerPopupButton
                        onClick={handleEditServer}
                        disabled={server.title.length === 0 || server.data?.port.length === 0 || server.data?.server.length === 0}
                    />
                    {isServersDelete && <RemoveServerPopupButton onClick={handleRemoveServer} />}
                </div>
            </Popup>
        );
    }
};
