import React from 'react';

export const Breadcrumbs = ({ crumbs }) => {
    return (
        <div className="uppercase text-sm text-gray mb-1.5">
            {crumbs.map((crumb, index) => {
                return (
                    <React.Fragment key={index}>
                        {crumb}
                        {crumbs[index + 1] && ' / '}
                    </React.Fragment>
                );
            })}
        </div>
    );
};
