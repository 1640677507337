import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import CustomScroll from 'react-custom-scroll';

import { auto_mode as auto_mode_state } from '../../../redux/slices/hospitalitySlice';
import { Draggable } from '../../common/dnd/Draggable';
import { Worker } from '../worker/Worker';

export const SupervisorTasksList = ({ isOpen, setIsOpen, cleaners }) => {
    const auto_mode = useSelector(auto_mode_state);

    useEffect(() => {
        if (cleaners.length) {
            ReactTooltip.rebuild();
        }
    }, [cleaners]);

    return (
        <div className={`relative -top-10 -mb-10 w-full bg-white rounded-b  ${isOpen ? 'block' : 'hidden'}`}>
            <div className="max-h-80 h-full">
                <CustomScroll allowOuterScroll={true}>
                    <div className="overflow-hidden">
                        <div className="flex flex-col gap-2 pl-3 pt-3 pb-4">
                            {cleaners.length ? (
                                cleaners.map(cleaner => (
                                    <Draggable id={cleaner.id} key={cleaner.id} disabled={auto_mode}>
                                        <Worker description={cleaner.name}>{cleaner.name}</Worker>
                                    </Draggable>
                                ))
                            ) : (
                                <div className="font-medium text-sm text-gray">Нет задач</div>
                            )}
                        </div>
                    </div>
                </CustomScroll>
            </div>
            <button
                onClick={() => setIsOpen(false)}
                className="w-full h-12 flex flex-col items-center justify-center font-medium text-sm text-gray border-t border-t-gray-10"
            >
                Скрыть
            </button>
        </div>
    );
};
