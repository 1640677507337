import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { t } from 'i18next';

export const PicsLoader = ({ disabled, files, setFiles }) => {
    const [sizeAddFiles, setSizeAddFiles] = useState(0);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            const size = acceptedFiles.reduce((acc, cur) => acc + cur.size, 0);

            if (files.length + acceptedFiles.length <= 20 && size < 20000000 && size + sizeAddFiles < 20000000) {
                setSizeAddFiles(sizeAddFiles + size);
                setFiles([
                    ...files,
                    ...acceptedFiles.map(file =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    ),
                ]);
            } else if (files.length + acceptedFiles.length >= 20) {
                toast.error(t('ADM_PLANNER_ERROR_MAX_AMOUNT'), {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            } else {
                toast.error(t('ADM_PLANNER_ERROR_MAX_SUMM_SIZE'), {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            }
        },
        onDropRejected: fileRejections => {
            fileRejections.forEach(f => {
                toast.error(`${t('ADM_PLANNER_ERROR_FILE')} ${f.file.name} ${t('ADM_PLANNER_ERROR_MAX_SIZE')}`, {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            });
        },

        disabled: disabled,
        maxSize: 20000000,
    });

    const removeFile = preview => {
        setFiles(files.filter(file => file.preview !== preview));
        const dellFile = files.find(file => file.preview === preview);
        setSizeAddFiles(sizeAddFiles - dellFile.size);
    };

    const thumbs = files.map((file, index) => {
        let imgJSX = (
            <>
                <svg className="w-6 h-6 fill-current" viewBox="0 0 512 512">
                    <path d="M459.36,100.64l-96-96C360.341,1.645,356.253-0.024,352,0H96c-26.51,0-48,21.49-48,48v416c0,26.51,21.49,48,48,48h320     c26.51,0,48-21.49,48-48V112C464.025,107.747,462.355,103.66,459.36,100.64z M432,464c0,8.837-7.163,16-16,16H96     c-8.837,0-16-7.163-16-16V48c0-8.837,7.163-16,16-16h240v64c0,17.673,14.327,32,32,32h64V464z" />
                    <rect x="368" y="384" width="32" height="32" />
                    <rect x="112" y="224" width="288" height="32" />
                    <rect x="112" y="304" width="288" height="32" />
                    <rect x="112" y="384" width="224" height="32" />
                </svg>
                <span className="text-xs text-center truncate w-full">{file.name}</span>
            </>
        );

        if (file.type.indexOf('image/') >= 0) {
            imgJSX = <img src={file.preview} className="w-full h-full object-cover" alt="" />;
        }

        return (
            <div className="w-28 h-28 p-2 border border-gray-10 rounded-2 flex flex-col gap-2 items-center justify-center relative" key={`${file.name}${index}`}>
                {imgJSX}
                <div className="absolute w-full h-full bg-black transition-opacity bg-opacity-50 opacity-0 hover:opacity-100 flex items-center justify-center">
                    <button type="button" onClick={() => removeFile(file.preview)} className="w-10 h-10 flex items-center justify-center text-white hover:text-red-300">
                        <svg className="w-4 h-4 fill-current" viewBox="0 0 16 16">
                            <path d="M5.28544 2.14123H5.14258C5.22115 2.14123 5.28544 2.07694 5.28544 1.99837V2.14123H10.714V1.99837C10.714 2.07694 10.7783 2.14123 10.8569 2.14123H10.714V3.42694H11.9997V1.99837C11.9997 1.36801 11.4872 0.855515 10.8569 0.855515H5.14258C4.51222 0.855515 3.99972 1.36801 3.99972 1.99837V3.42694H5.28544V2.14123ZM14.2854 3.42694H1.71401C1.39794 3.42694 1.14258 3.6823 1.14258 3.99837V4.5698C1.14258 4.64837 1.20686 4.71266 1.28544 4.71266H2.36401L2.80508 14.0519C2.83365 14.6609 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6627 13.1944 14.0519L13.6354 4.71266H14.714C14.7926 4.71266 14.8569 4.64837 14.8569 4.5698V3.99837C14.8569 3.6823 14.6015 3.42694 14.2854 3.42694ZM11.9158 13.8555H4.08365L3.65151 4.71266H12.3479L11.9158 13.8555Z" />
                        </svg>
                    </button>
                </div>
            </div>
        );
    });

    return (
        <section className="flex flex-row flex-wrap gap-2">
            {thumbs}
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className="flex gap-1 w-28 h-28 p-1 bg-gray-100 items-center justify-center flex-col text-gray border rounded-2 border-dotted border-gray-10 cursor-pointer transition-colors hover:bg-gray-200">
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 14 14">
                        <path d="M6.53125 1.37571H7.46875C7.55208 1.37571 7.59375 1.41738 7.59375 1.50071V12.5007C7.59375 12.584 7.55208 12.6257 7.46875 12.6257H6.53125C6.44792 12.6257 6.40625 12.584 6.40625 12.5007V1.50071C6.40625 1.41738 6.44792 1.37571 6.53125 1.37571Z" />
                        <path d="M1.75024 6.40696H12.2502C12.3336 6.40696 12.3752 6.44863 12.3752 6.53196V7.46946C12.3752 7.5528 12.3336 7.59446 12.2502 7.59446H1.75024C1.66691 7.59446 1.62524 7.5528 1.62524 7.46946V6.53196C1.62524 6.44863 1.66691 6.40696 1.75024 6.40696Z" />
                    </svg>
                    <span className="text-xs text-center">{t('ADM_PLANNER_ADD_PHOTO_FILE')}</span>
                </div>
            </div>
        </section>
    );
};
