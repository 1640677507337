import { twMerge } from 'tailwind-merge';
import { Loader } from '../Loader';

export const ButtonTile = ({ children, className = '', buttonRef = null, onClick, disabled = false, loading = false }) => {
    const paddingClasses = Array.isArray(children) && children[0].type === 'svg' ? 'flex flex-col ' : 'px-6';
    const disabledClasses = disabled || loading ? 'border-gray-30 bg-gray-30 hover:bg-gray-30 text-white' : '';

    const buttonClasses = twMerge(
        `flex justify-center items-center overflow-hidden w-28 h-28 p-2 text-xs border-gray-10 hover:border-blue border-2 rounded-md bg-white hover:bg-blue-10 ${className} ${disabledClasses} ${paddingClasses}`
    );

    return (
        <button onClick={onClick} className={buttonClasses} ref={buttonRef} disabled={disabled || loading}>
            {loading ? <Loader height={20} width={20} color="#ffffff" /> : children}
        </button>
    );
};
