import { createSlice } from '@reduxjs/toolkit';
import { applicationsAPI } from '../../api/modules/applicationsAPI';
import { adminSchedulerAPI } from '../../api/modules/adminSchedulerAPI';

export const applicationsSlice = createSlice({
    name: 'applications',

    initialState: {
        // popup
        popup_is_open: false,
        new_application_info: {},

        // sort
        sort: null,

        // filter
        filter_fetch: {
            departments: [],
            priority: [],
            category: [],
            status: [],
            executors: [],
            users: [],
            venue: [],
            buildings: [],
            floors: [],
            offices: [],
            systems: [],
            devices: [],
        },

        filter: {
            page: 1,
        },

        // additional filter
        additional_filter: null,

        // applications
        applications_fetching: false,
        applications: {
            list: [],
            count: null,
            total_count: null,
        },
        application_created: false,
        application_changing: false,
        application: null,

        detail_is_open: false,
        filter_is_open: false,
        venues: [],

        // errors
        applications_error: null,
    },

    reducers: {
        // applications
        set_applications_fetching: (state, action) => {
            state.applications_fetching = action.payload;
        },

        set_applications: (state, action) => {
            state.applications.list.push(...action.payload.tasks);
            state.applications.count = action.payload.count;
            state.applications.total_count = action.payload.total_count;
        },

        set_application: (state, { payload }) => {
            state.application = payload;
        },

        clear_applications: state => {
            state.applications.list = [];
            state.applications.count = null;
            state.applications.total_count = null;
        },

        set_created_application: (state, action) => {
            state.application_created = action.payload;
        },
        set_changing_application: (state, action) => {
            state.application_changing = action.payload;
        },
        set_new_application_info: (state, action) => {
            state.new_application_info = action.payload;
        },

        // errors
        set_applications_error: (state, action) => {
            state.applications_error = action.payload;
        },

        // detail
        set_detail_open: (state, action) => {
            state.detail_is_open = action.payload;
        },

        // additional filter
        set_addit_filter: (state, action) => {
            for (const item in action.payload) {
                state.filter[item] = action.payload[item];
            }
            state.filter.page = 1;
            // state.filter = action.payload;
        },
        set_additional_filter: (state, action) => {
            state.additional_filter = action.payload;
        },

        // filter
        set_filter_open: (state, action) => {
            state.filter_is_open = action.payload;
        },
        set_venues: (state, action) => {
            state.venues = action.payload;
        },

        // filter
        set_filter: (state, action) => {
            state.filter[action.payload.type] = action.payload.value;
            state.filter.page = 1;
        },
        set_filter_page: (state, action) => {
            state.filter.page = action.payload;
        },
        set_filter_fetch_departments: (state, action) => {
            state.filter_fetch.departments = action.payload;
        },
        set_filter_fetch_priority_list: (state, action) => {
            state.filter_fetch.priority = action.payload;
        },
        set_filter_fetch_executors_list: (state, action) => {
            state.filter_fetch.executors = action.payload;
        },
        set_filter_fetch_category_list: (state, action) => {
            state.filter_fetch.category = action.payload;
        },
        set_filter_fetch_status_list: (state, action) => {
            state.filter_fetch.status = action.payload;
        },
        set_filter_fetch_users_list: (state, action) => {
            state.filter_fetch.users = action.payload;
        },
        set_filter_fetch_buildings: (state, action) => {
            state.filter_fetch.buildings = action.payload;
        },
        set_filter_fetch_floors: (state, action) => {
            state.filter_fetch.floors = action.payload;
        },
        set_filter_fetch_offices: (state, action) => {
            state.filter_fetch.offices = action.payload;
        },
        set_filter_fetch_systems: (state, action) => {
            state.filter_fetch.systems = action.payload;
        },
        set_filter_fetch_devices: (state, action) => {
            state.filter_fetch.devices = action.payload;
        },
        clear_filter: state => {
            for (const item in state.filter) {
                state.filter[item] = '';
            }
            state.filter.page = 1;
        },

        // sort
        set_sort: (state, action) => {
            state.sort = action.payload;
        },

        // popup
        set_popup_open: (state, action) => {
            state.popup_is_open = action.payload;
        },
    },
});

export const {
    set_applications_error,
    set_additional_filter,
    set_new_application_info,
    set_changing_application,
    set_popup_open,
    set_addit_filter,
    set_filter_fetch_devices,
    set_filter_fetch_systems,
    set_filter_fetch_offices,
    set_filter_fetch_floors,
    set_filter_fetch_buildings,
    set_sort,
    set_filter_fetch_users_list,
    set_filter_fetch_status_list,
    set_filter_fetch_category_list,
    set_application,
    set_created_application,
    set_filter_fetch_executors_list,
    set_filter_fetch_priority_list,
    set_filter_fetch_departments,
    set_venues,
    set_detail_open,
    set_filter_open,
    set_filter_page,
    set_applications_fetching,
    set_applications,
    set_filter,
    clear_applications,
    clear_filter,
} = applicationsSlice.actions;

// request applictions
export const applicationsFetch =
    ({ username, auth_key_calendar, filter, sort, lang }) =>
    async dispatch => {
        await dispatch(set_applications_fetching(true));

        try {
            const response = await applicationsAPI.getApplications.fetch(username, auth_key_calendar, filter, sort, lang);

            if (response.status === 200) {
                const res = await response.json();
                // console.log('res', res);

                if (filter.page === 1) {
                    await dispatch(clear_applications());
                }

                if (res.code === 0) {
                    await dispatch(set_applications(res.data));
                }
            } else {
                await dispatch(clear_applications());
            }
        } catch (e) {
            console.log('applicationsFetch error:', e);
        } finally {
            await dispatch(set_applications_fetching(false));
        }
    };

// request venue
export const venueFetch =
    ({ username, auth_key_calendar, lang }) =>
    async dispatch => {
        try {
            const response = await adminSchedulerAPI.getVenues.fetch(username, auth_key_calendar, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    const arr = res.data.map(item => ({
                        value: item.ID,
                        label: item.Name,
                    }));
                    await dispatch(set_venues(arr));
                } else {
                    await dispatch(set_venues([]));
                }
            } else {
                await dispatch(set_venues([]));
            }
        } catch (e) {
            console.log('venueFetch error: ', e);
        } finally {
        }
    };

// request departments
export const departmentsFetch = lang => async dispatch => {
    try {
        const response = await adminSchedulerAPI.getDepartments.fetch(lang);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                if (res.data) {
                    const data = res.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }));

                    await dispatch(set_filter_fetch_departments(data));
                }
            }
        }
    } catch (e) {
        console.log('departmentsFetch error: ', e);
    } finally {
    }
};

// request priority
export const priorityListFetch =
    ({ username, auth_key_calendar, lang }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.getPriorityList.fetch(username, auth_key_calendar, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    if (res.data) {
                        const data = res.data.map(item => ({
                            value: item.id,
                            label: item.name,
                        }));
                        await dispatch(set_filter_fetch_priority_list(data));
                    }
                }
            }
        } catch (e) {
            console.log('priorityListFetch error: ', e);
        }
    };
// request executors
export const executorsListFetch =
    ({ username, auth_key_calendar, venue: venue_id, department: department_id, lang }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.getExecutorsList.fetch(username, auth_key_calendar, venue_id, department_id, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    if (res.data) {
                        const data = res.data.map(item => ({
                            value: item.id,
                            label: item.full_name,
                        }));
                        await dispatch(set_filter_fetch_executors_list(data));
                    }
                }
            }
        } catch (e) {
            console.log('executorsListFetch error: ', e);
        }
    };

// create application
export const addApplicationFetch =
    ({ username, auth_key_calendar, data, lang }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.addApplication.fetch(username, auth_key_calendar, data, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    await dispatch(set_created_application(true));
                    await dispatch(set_applications_error(null));
                    await dispatch(set_detail_open(false));
                } else {
                    await dispatch(set_applications_error(res.data.errors));
                }
            }
        } catch (e) {
            console.log('addApplication error:', e);
        } finally {
            await dispatch(set_created_application(false));
        }
    };

// get application
export const getApplicationFetch =
    ({ username, auth_key_calendar, id, lang }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.getApplication.fetch(username, auth_key_calendar, id, lang);
            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_application(res.data.task));
                }
            }
        } catch (e) {
            console.log('getApplicationFetch error:', e);
        }
    };

// change application
export const changeApplicationFetch =
    ({ username, auth_key_calendar, data, lang }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.changeApplication.fetch(username, auth_key_calendar, data, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    await dispatch(set_changing_application(true));
                }
            }
        } catch (e) {
            console.log('changeApplication error:', e);
        } finally {
            // await dispatch(set_detail_open(false));
            await dispatch(set_changing_application(false));
        }
    };

// get category list
export const categoryListfetch =
    ({ username, auth_key_calendar, lang }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.getCategoryList.fetch(username, auth_key_calendar, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    if (res.data) {
                        const data = [];
                        for (const item in res.data) {
                            data.push({
                                value: Number(item),
                                label: res.data[item],
                            });
                        }
                        await dispatch(set_filter_fetch_category_list(data));
                    }
                }
            }
        } catch (e) {
            console.log('categoryListFetch error: ', e);
        }
    };

// get status list
export const stausListFetch =
    ({ username, auth_key_calendar, lang }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.getStatusList.fetch(username, auth_key_calendar, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    if (res.data) {
                        const data = [];
                        for (const item in res.data) {
                            data.push({
                                value: Number(item),
                                label: res.data[item],
                            });
                        }
                        await dispatch(set_filter_fetch_status_list(data));
                    }
                }
            }
        } catch (e) {
            console.log('statusListFetch error: ', e);
        }
    };

// get users list
export const usersListFetch =
    ({ username, auth_key_calendar, lang, venue: venue_id, type }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.getUsersList.fetch(username, auth_key_calendar, lang, venue_id, type);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    if (res.data) {
                        const data = res.data.map(item => ({
                            value: item.id,
                            label: item.full_name,
                            position: item.position,
                        }));
                        if (type === 1) {
                            await dispatch(set_filter_fetch_users_list(data));
                        }
                        if (type === 2) {
                            await dispatch(set_filter_fetch_executors_list(data));
                        } else {
                            await dispatch(set_filter_fetch_users_list(data));
                        }
                    }
                }
            }
        } catch (e) {
            console.log('usersListFetch error: ', e);
        }
    };

// get buildings
export const buildingsListFetch =
    ({ username, auth_key_calendar, lang, venue: venue_id }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.getBuildingsList.fetch(username, auth_key_calendar, lang, venue_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    const data = res.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }));
                    await dispatch(set_filter_fetch_buildings(data));
                }
            }
        } catch (e) {
            console.log('buildingsListFetch error: ', e);
        }
    };

// get floors
export const floorsListFetch =
    ({ username, auth_key_calendar, lang, building: building_id }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.getFloorsList.fetch(username, auth_key_calendar, lang, building_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    console.log('res', res);
                    const data = res.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }));
                    await dispatch(set_filter_fetch_floors(data));
                }
            }
        } catch (e) {
            console.log('floorsListFetch error: ', e);
        }
    };
// get offices
export const officesListFetch =
    ({ username, auth_key_calendar, lang, floor: floor_id }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.getOfficesList.fetch(username, auth_key_calendar, lang, floor_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    const data = res.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }));
                    await dispatch(set_filter_fetch_offices(data));
                }
            }
        } catch (e) {
            console.log('officesListFetch error: ', e);
        }
    };
// get systems
export const systemsListFetch =
    ({ username, auth_key_calendar, lang, office: office_id }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.getSystemsList.fetch(username, auth_key_calendar, lang, office_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    console.log('res', res);
                    const data = res.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }));
                    await dispatch(set_filter_fetch_systems(data));
                }
            }
        } catch (e) {
            console.log('systemsListFetch error: ', e);
        }
    };
// get devices
export const devicesListFetch =
    ({ username, auth_key_calendar, lang, system: system_id }) =>
    async dispatch => {
        try {
            const response = await applicationsAPI.getDevicesList.fetch(username, auth_key_calendar, lang, system_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    console.log('res', res);
                    const data = res.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }));
                    await dispatch(set_filter_fetch_devices(data));
                }
            }
        } catch (e) {
            console.log('devicesListFetch error: ', e);
        }
    };

export const filter = state => state.applications.filter;
export const applications_fetching = state => state.applications.applications_fetching;
export const applications = state => state.applications.applications;
export const detail_is_open = state => state.applications.detail_is_open;
export const filter_is_open = state => state.applications.filter_is_open;
export const popup_is_open = state => state.applications.popup_is_open;
export const filter_fetch = state => state.applications.filter_fetch;
export const venues = state => state.applications.venues;
export const executors = state => state.applications.filter_fetch.executors;
export const users = state => state.applications.filter_fetch.users;
export const application_created = state => state.applications.application_created;
export const application = state => state.applications.application;
export const sort = state => state.applications.sort;
export const new_application_info = state => state.applications.new_application_info;
export const additional_filter = state => state.applications.additional_filter;
export const applications_error = state => state.applications.applications_error;

export default applicationsSlice.reducer;
