import { useState } from 'react';
import { t } from 'i18next';

import { useCheckRole } from '../../hooks/useCheckRole';
import { book } from '../../navigation/book';

import { SubHeader } from '../../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../../components/common/subheader/SubHeaderLeft';
import { SubHeaderRight } from '../../components/common/subheader/SubHeaderRight';
import { ContentContainer } from '../../components/common/ContentContainer';
import { Title } from '../../components/common/subheader/Title';
import { VenueList } from '../../components/bms/venues/VenueList';
import { CreateVenuePopup } from '../../components/bms/venues/createPopup/CreateVenuePopup';
import { EditVenuePopup } from '../../components/bms/venues/editPopup/EditVenuePopup';
import { OpenServersPageButton } from '../../components/bms/venues/OpenServersPageButton';
import { CreateVenueButton } from '../../components/bms/venues/CreateVenueButton';
import { NoAccessPage } from '../../components/common/noAccess/NoAccessPage';

export const BMSVenuesPage = () => {
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [editPopupIsOpen, setEditPopupIsOpen] = useState(false);
    const [venueId, setVenueId] = useState(null);

    const isObjectView = useCheckRole('bms', 'bms.object.view');
    const isObjectAdd = useCheckRole('bms', 'bms.object.add');
    const isServersView = useCheckRole('bms', 'bms.servers.view');

    if (!isObjectView) {
        return <NoAccessPage />;
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Title>{t(book.bms_venue.key)}</Title>
                </SubHeaderLeft>

                <SubHeaderRight>{isServersView && <OpenServersPageButton />}</SubHeaderRight>
            </SubHeader>

            <ContentContainer>
                <div className="flex flex-wrap gap-8">
                    {isObjectAdd && <CreateVenueButton setPopupIsOpen={setPopupIsOpen} />}

                    <VenueList setVenueId={setVenueId} setIsOpen={setEditPopupIsOpen} />
                </div>
            </ContentContainer>

            {popupIsOpen && isObjectAdd && <CreateVenuePopup isOpen={popupIsOpen} setIsOpen={setPopupIsOpen} />}

            {editPopupIsOpen && <EditVenuePopup isOpenEdit={editPopupIsOpen} setIsOpenEdit={setEditPopupIsOpen} venueId={venueId} setVenueId={setVenueId} />}
        </>
    );
};
