import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { creatorsFetch, departmentsFetch, filter_fetch as filter_fetch_state, set_filter } from '../../redux/slices/adminSchedulerSlice';
import { t } from 'i18next';
import { useCookies } from 'react-cookie';

export const Filter = () => {
    const dispatch = useDispatch();
    const filter_fetch = useSelector(filter_fetch_state);
    const [searchLine, setSearchLine] = useState('');
    const [cookie, setCookie] = useCookies();
    const langRef = useRef(true);

    const lang = cookie.i18next;
    useEffect(() => {
        if (!filter_fetch.departments.length) {
            dispatch(departmentsFetch(lang));
        }
    }, [filter_fetch.departments]);

    useEffect(() => {
        if (!filter_fetch.creators.length) {
            dispatch(creatorsFetch(lang));
        }
    }, [filter_fetch.creators]);

    useEffect(() => {
        if (langRef.current) {
            langRef.current = false;
            return;
        }

        dispatch(departmentsFetch(lang));
    }, [lang]);

    //search line
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            dispatch(set_filter({ type: 'filter_id_desc', value: searchLine }));
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchLine]);

    return (
        <div className="flex flex-wrap gap-2 w-full bg-white rounded-lg border border-gray-10 p-2 mb-6">
            <div className="h-10 w-48 relative">
                <input
                    type="text"
                    placeholder={t('ADM_PLANNER_DESCR_ID')}
                    onChange={e => setSearchLine(e.target.value)}
                    className="box-border w-full h-full text-sm rounded-1 bg-gray-100 border-gray-10 py-0 pl-2 pr-9"
                />

                <svg className="w-[20px] h-[20px] absolute right-[10px] top-[9px]" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.33333 14.1667C11.555 14.1667 14.1667 11.555 14.1667 8.33333C14.1667 5.11167 11.555 2.5 8.33333 2.5C5.11167 2.5 2.5 5.11167 2.5 8.33333C2.5 11.555 5.11167 14.1667 8.33333 14.1667Z"
                        stroke="#B3B3BC"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M12.5 12.5L17.5 17.5" stroke="#B3B3BC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>

            <Select
                onChange={el => dispatch(set_filter({ type: 'filter_active', value: el ? el.value : '' }))}
                options={[
                    { value: 1, label: t('ADM_PLANNER_TASK_ACTIVE') },
                    { value: 0, label: t('ADM_PLANNER_TASK_NOT_ACTIVE') },
                ]}
                isClearable={true}
                isSearchable={false}
                placeholder={t('ADM_PLANNER_STATUS')}
                classNamePrefix="filter"
                className="select text-sm w-48"
            />

            <Select
                onChange={el => dispatch(set_filter({ type: 'filter_creator', value: el ? el.value : '' }))}
                options={
                    filter_fetch.creators.length
                        ? filter_fetch.creators.map(el => ({
                              value: el.id,
                              label: `${el.last_name} ${el.first_name}`,
                          }))
                        : []
                }
                isClearable={true}
                isSearchable={false}
                placeholder={t('ADM_PLANNER_CREATOR')}
                classNamePrefix="filter"
                className="select text-sm w-48"
            />

            <Select
                onChange={el => dispatch(set_filter({ type: 'filter_department', value: el ? el.value : '' }))}
                options={
                    filter_fetch.departments.length
                        ? filter_fetch.departments.map(el => ({
                              value: el.id,
                              label: el.name,
                          }))
                        : []
                }
                isClearable={true}
                isSearchable={false}
                placeholder={t('ADM_PLANNER_DIRECTION')}
                classNamePrefix="filter"
                className="select text-sm w-48"
            />
        </div>
    );
};
