import { useSelector } from 'react-redux';

import { application as application_state } from '../../../redux/slices/applicationsSlice';

export const ApplicationPath = () => {
    const application = useSelector(application_state);
    // console.log('ApplicationPath ~ application', application);

    const arrow = () => (
        <svg width="5" height="10" viewBox="0 0 5 10" fill="none">
            <path d="M0 10V0L5 5L0 10Z" fill="black" fillOpacity="0.45" />
        </svg>
    );

    return (
        <div className="flex items-center gap-x-6 pb-6">
            <span>{application.venue}</span>
            {application.di_building_name && arrow()}

            <span>{application.di_building_name}</span>
            {application.di_floor_name && arrow()}

            <span>{application.di_floor_name}</span>
            {application.di_office_name && arrow()}

            <span>{application.di_office_name}</span>
            {application.di_system_name && arrow()}

            <span>{application.di_system_name}</span>
            {application.di_device_name && arrow()}

            <span>{application.di_device_name}</span>
        </div>
    );
};
