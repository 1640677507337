import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { nodes_list as nodes_list_state, addBMSDocNode, getBMSNodesList, updateNodeData, add_bms_document_node } from '../../../../../redux/slices/bmsSlice';

import { Popup } from '../../../../common/popup/Popup';
import { ButtonRectangular } from '../../../../common/btn/ButtonRectangular';
import { NodeStatusForm } from './NodeStatusForm';
import { PopupHead } from './PopupHead';
import { PopupTitle } from './PopupTitle';
import { toastOptions } from '../../../../../api/functions';

export const CreateNodePopup = ({ setIsOpen, node, docId }) => {
    const dispatch = useDispatch();

    const nodes_list = useSelector(nodes_list_state);

    const [libElem, setLibElem] = useState(node);
    const [libElemTitle, setLibElemTitle] = useState(node.name);
    const [nodeStates, setNodeStates] = useState(node.data?.states ? node.data.states : []);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setLibElem(node);
    }, [node]);

    useEffect(() => {
        if (!nodes_list.length) {
            dispatch(getBMSNodesList({ docId }));
        }
    }, [dispatch]);

    useEffect(() => {
        !libElemTitle ? setDisabled(true) : setDisabled(false);

        for (const el of nodeStates) {
            if (el.hasOwnProperty('value') && el.hasOwnProperty('node') && el.hasOwnProperty('title')) {
                if (el.value?.length === 0 || el.node.length === 0 || el.title.length === 0 || libElemTitle.length === 0) {
                    return setDisabled(true);
                } else {
                    setDisabled(false);
                }
            } else {
                setDisabled(true);
            }
        }
    }, [nodeStates, libElemTitle]);

    const nodeOptions =
        nodes_list.length &&
        nodes_list.map(node => {
            return { value: node.id, label: node.name, datatype: node.datatype };
        });

    const clickHandler = async () => {
        for (let i = 0; i < nodeStates.length; i++) {
            const nodeI = nodeStates[i];
            for (let j = i + 1; j < nodeStates.length; j++) {
                const nodeJ = nodeStates[j];
                if (nodeI.node === nodeJ.node) {
                    if (nodeI.value === nodeJ.value) {
                        return toast.error('Нельзя добавить два или более одинаковых значений у точки данных', toastOptions);
                    }
                }
            }
        }
        const position = {
            left: 0,
            top: 0,
            leftPercent: 0,
            topPercent: 0,
            height: 'auto',
            width: 'auto',
            degree: 0,
        };

        const data = {
            title: libElemTitle,
            states: nodeStates,
            position,
        };

        const nodeObj = {
            doc_id: docId,
            node_id: node.id,
            data: JSON.stringify(data),
        };

        if (node.hasOwnProperty('cn_id')) {
            const updNode = {
                ...node,
                title: libElemTitle,
                data: JSON.stringify(data),
                position: JSON.stringify(node.position),
            };

            const nodesArr = [];
            nodesArr.push(updNode);
            const result = await dispatch(updateNodeData({ nodesArr }));
            if (result.error) {
                toast.error('Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.', toastOptions);

                return null;
            }
        } else {
            const { cn_id, width, height } = await dispatch(addBMSDocNode({ nodeObj }));
            const resultNode = {
                cn_id,
                data,
                title: libElemTitle,
                lib_data: node.lib_data,
                lib_title: node.title,
                name: data.title,
                position: { ...position, width: `${width}px`, height: `${height}px`, zIndex: 0 },
            };
            dispatch(add_bms_document_node(resultNode));
        }

        setIsOpen(false);
    };

    if (!libElem.lib_data.default_pic) {
        return (
            <Popup isOpen={true} setIsOpen={setIsOpen} className="w-[750px]">
                <div className="text-center p-4">Элемент без изображения добавить на холст нельзя</div>
            </Popup>
        );
    }

    return (
        <Popup isOpen={true} setIsOpen={setIsOpen} className="w-[750px]">
            <PopupTitle title={libElem.title} setLibElemTitle={setLibElemTitle} />

            {libElem.lib_data.states.length !== 0 && <PopupHead />}

            {libElem.lib_data.states.length !== 0 &&
                libElem.lib_data.states.map((el, idx) => (
                    <div key={el.name} className="flex mt-3">
                        <NodeStatusForm
                            nodeOptions={nodeOptions}
                            node={el}
                            setNodeStates={setNodeStates}
                            nodeStates={nodeStates}
                            statusInfo={nodeStates.find(ell => ell.name === el.name)}
                        />
                    </div>
                ))}

            <ButtonRectangular disabled={disabled} className="w-40 ml-auto mr-auto mt-5 justify-center" onClick={clickHandler}>
                Применить
            </ButtonRectangular>
        </Popup>
    );
};
