import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DndContext, DragOverlay } from '@dnd-kit/core';

import { moveTask, tasks as tasks_state } from '../../../redux/slices/hospitalitySlice';
import { TasksSection } from '../tasksSection/TasksSection';
import { CleanersSection } from '../cleanersSection/CleanersSection';
import { Task } from '../task/Task';

export const MainSection = () => {
    const dispatch = useDispatch();

    const tasks = useSelector(tasks_state);

    const [activeTask, setActiveTask] = useState(null);

    const handleDragEnd = event => {
        if (event.over) {
            dispatch(
                moveTask({
                    data: {
                        task_id: event.active.id,
                        user_id: event.over.id === 'default' ? null : event.over.id,
                    },
                })
            );
        }
        setActiveTask(null);
    };

    const handleDragStart = event => {
        setActiveTask(tasks.find(task => task.task_id === event.active.id));
    };

    return (
        <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
            <div className="h-full w-full border-l border-l-gray-10 flex flex-row overflow-hidden">
                <TasksSection />
                <CleanersSection />
            </div>

            <DragOverlay dropAnimation={null}>
                {activeTask ? (
                    <Task type={activeTask.clear_type_id} status={activeTask.status_id}>
                        {activeTask.room_str || activeTask.description}
                    </Task>
                ) : null}
            </DragOverlay>
        </DndContext>
    );
};
