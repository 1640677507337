import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { bms_venues_list as bms_venues_list_state, removeBMSVenue } from '../../../../redux/slices/bmsSlice';
import { useCheckRole } from '../../../../hooks/useCheckRole';
import { CreateVenuePopupButton } from './EditVenuePopupButton';
import { Popup } from '../../../common/popup/Popup';
import { MultiSelect } from '../../../common/widgets/MultiSelect';

const initValues = { venueName: '', servers: [] };

export const EditVenuePopup = ({ isOpenEdit, setIsOpenEdit, venueId, setVenueId }) => {
    const dispatch = useDispatch();

    const bms_venues_list = useSelector(bms_venues_list_state);

    const [venue, setVenue] = useState(initValues);
    const [servers, setServers] = useState(null);

    const isObjectDelete = useCheckRole('bms', 'bms.object.del');

    useEffect(() => {
        if (venueId && bms_venues_list.length !== 0) {
            setVenue(bms_venues_list.find(venue => venue.id === venueId));
        }
    }, [venueId, bms_venues_list]);

    useEffect(() => {
        if (!isOpenEdit) {
            setVenueId(null);
            setVenue(initValues);
        }
    }, [isOpenEdit, setVenueId]);

    useEffect(() => {
        if (venue.servers[0]?.label) {
            return setServers(venue.servers);
        } else {
            setServers(
                venue.servers.map(server => {
                    return { label: server.title, value: server.id };
                })
            );
        }
    }, [venue]);

    const removeVenueHandler = () => {
        dispatch(removeBMSVenue({ id: venueId, title: venue.title }));
        setIsOpenEdit(false);
        setVenueId(null);
        setVenue(initValues);
    };
    return (
        <Popup isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} className="w-[750px] h-72">
            <div className="text-center text-lg">{venue.title}</div>
            <div className="flex justify-around mt-10 mb-24">
                <input
                    type="text"
                    className="w-96 h-10 border-gray-70 hover:border-gray-30 text-sm px-2 mr-2"
                    defaultValue={venue.title}
                    onChange={e => setVenue({ ...venue, title: e.target.value })}
                    placeholder="Наименование объекта"
                    disabled={true}
                />

                <MultiSelect placeholder="Выберите серверы" value={servers} className="w-96" isDisabled={true} />
            </div>
            {isObjectDelete && <CreateVenuePopupButton disabled={false} onClick={removeVenueHandler} />}
        </Popup>
    );
};
