export const Worker = ({ children, description = '' }) => {
    return (
        <div
            className="flex flex-row items-center overflow-hidden w-44 max-w-full h-9 border border-gray-70 rounded-md pr-3 box-border bg-white select-none"
            data-tip={description}
        >
            <div className={`w-2 shrink-0 h-full mr-4 bg-hospitality-clear-type-4`}></div>
            <div className="w-full text-left line-clamp-1">{children || description}</div>
        </div>
    );
};
