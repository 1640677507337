import { root } from '../config';

export const applicationsAPI = Object.freeze({
    // getting a list of requests
    getApplications: {
        fetch: (username, auth_key_calendar, filter, sort, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            if (sort) {
                formData.append('sort[]', sort);
            }

            for (const item in filter) {
                if (filter[item]) {
                    if (item === 'page') {
                        formData.append('page', filter[item]);
                    }
                    if (item === 'filter_id') {
                        formData.append('TaskSearch[id_desc]', filter[item]);
                    }
                    if (item === 'filter_status') {
                        if (Array.isArray(filter[item])) {
                            filter[item].map(el => formData.append('TaskSearch[status_list][]', el));
                        } else {
                            formData.append('TaskSearch[status_list][]', filter[item]);
                        }
                    }
                    if (item === 'filter_signs') {
                        if (Array.isArray(filter[item])) {
                            filter[item].map(el => formData.append('TaskSearch[sign_list][]', el));
                        } else {
                            formData.append('TaskSearch[sign_list][]', filter[item]);
                        }
                    }
                    if (item === 'filter_source') {
                        if (Array.isArray(filter[item])) {
                            filter[item].map(el => formData.append('TaskSearch[category_list][]', el));
                        } else {
                            formData.append('TaskSearch[category_list][]', filter[item]);
                        }
                    }
                    if (item === 'filter_department') {
                        if (Array.isArray(filter[item])) {
                            filter[item].map(el => formData.append('TaskSearch[department_list][]', el));
                        } else {
                            formData.append('TaskSearch[department_list][]', filter[item]);
                        }
                    }
                    if (item === 'filter_priority') {
                        if (Array.isArray(filter[item])) {
                            filter[item].map(el => formData.append('TaskSearch[priority_list][]', el));
                        } else {
                            formData.append('TaskSearch[priority_list][]', filter[item]);
                        }
                    }
                    if (item === 'filter_venue') {
                        if (Array.isArray(filter[item])) {
                            filter[item].map(el => formData.append('TaskSearch[venue_list_id][]', el));
                        } else {
                            formData.append('TaskSearch[venue_list_id][]', filter[item]);
                        }
                    }
                    if (item === 'createdAtMin') {
                        formData.append('TaskSearch[createdAtMin]', filter[item]);
                    }
                    if (item === 'createdAtMax') {
                        formData.append('TaskSearch[createdAtMax]', filter[item]);
                    }
                    if (item === 'startDateMin') {
                        formData.append('TaskSearch[startDateMin]', filter[item]);
                    }
                    if (item === 'startDateMax') {
                        formData.append('TaskSearch[startDateMax]', filter[item]);
                    }
                    if (item === 'expectedEndDateMin') {
                        formData.append('TaskSearch[expectedEndDateMin]', filter[item]);
                    }
                    if (item === 'expectedEndDateMax') {
                        formData.append('TaskSearch[expectedEndDateMax]', filter[item]);
                    }
                    if (item === 'endDateMin') {
                        formData.append('TaskSearch[endDateMin]', filter[item]);
                    }
                    if (item === 'endDateMax') {
                        formData.append('TaskSearch[endDateMax]', filter[item]);
                    }
                }
            }

            return fetch(`${root}?r=api/core/get-task-list`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // getting a list of priorities
    getPriorityList: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/core/get-priority-list`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // getting a list of performers
    getExecutorsList: {
        fetch: (username, auth_key_calendar, venue_id, department_id, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('venue_id', venue_id);
            formData.append('department_id', department_id);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/core/get-responsible-department-venue`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // adding a new application
    addApplication: {
        fetch: (username, auth_key_calendar, data, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);
            formData.append('params', JSON.stringify(data));

            for (const item in data) {
                if (item === 'files') {
                    data[item].forEach((file, index) => {
                        formData.append('uploaded_files[]', `file${index}`);
                        formData.append(`file${index}`, file);
                    });
                }
            }

            return fetch(`${root}?r=api/core/create-task`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // get application
    getApplication: {
        fetch: (username, auth_key_calendar, id, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);
            formData.append('task_id', id);

            return fetch(`${root}?r=api/core/get-task-detail`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // change application
    changeApplication: {
        fetch: (username, auth_key_calendar, data, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);
            formData.append('params', JSON.stringify(data));

            for (const item in data) {
                if (item === 'files') {
                    data[item].forEach((file, index) => {
                        formData.append('uploaded_files[]', `file${index}`);
                        formData.append(`file${index}`, file);
                    });
                }
                if (item === 'creatorPhoto') {
                    data[item].forEach((file, index) => {
                        formData.append('uploaded_files[]', `fileCreator${index}`);
                        formData.append(`fileCreator${index}`, file);
                    });
                }
                if (item === 'executorPhoto') {
                    data[item].forEach((file, index) => {
                        formData.append('uploaded_files[]', `fileExecutor${index}`);
                        formData.append(`fileExecutor${index}`, file);
                    });
                }
            }

            return fetch(`${root}?r=api/core/update-task`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // get category list
    getCategoryList: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/core/get-category-list`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // get status list
    getStatusList: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/core/get-status-list`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // get users list
    getUsersList: {
        fetch: (username, auth_key_calendar, lang, venue_id, type) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);
            venue_id && formData.append('venue_id', venue_id);
            type && formData.append('type', type);

            return fetch(`${root}?r=api/core/get-users-list`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // get buildings list
    getBuildingsList: {
        fetch: (username, auth_key_calendar, lang, venue_id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);
            formData.append('parent_id', venue_id);

            return fetch(`${root}?r=api/core/get-buildings`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // get floor list
    getFloorsList: {
        fetch: (username, auth_key_calendar, lang, building_id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);
            formData.append('parent_id', building_id);

            return fetch(`${root}?r=api/core/get-floors`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // get offices list
    getOfficesList: {
        fetch: (username, auth_key_calendar, lang, floor_id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);
            formData.append('parent_id', floor_id);

            return fetch(`${root}?r=api/core/get-offices`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // get systems list
    getSystemsList: {
        fetch: (username, auth_key_calendar, lang, office_id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);
            formData.append('parent_id', office_id);

            return fetch(`${root}?r=api/core/get-systems`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // get devices list
    getDevicesList: {
        fetch: (username, auth_key_calendar, lang, system_id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);
            formData.append('parent_id', system_id);

            return fetch(`${root}?r=api/core/get-devices`, {
                method: 'POST',
                body: formData,
            });
        },
    },
});
