import { configureStore } from '@reduxjs/toolkit';

import loginReducer from './slices/loginSlice';
import calendarReducer from './slices/calendarSlice';
import adminSchedulerReducer from './slices/adminSchedulerSlice';
import applicationsReducer from './slices/applicationsSlice';
import qualificationReducer from './slices/qualificationSlice';
import bmsReducer from './slices/bmsSlice';
import hospitalityReducer from './slices/hospitalitySlice';

export const store = configureStore({
    reducer: {
        login: loginReducer,
        calendar: calendarReducer,
        admin_scheduler: adminSchedulerReducer,
        applications: applicationsReducer,
        qualification: qualificationReducer,
        bms: bmsReducer,
        hospitality: hospitalityReducer,
    },
});
