import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

import { application as application_state } from '../../../redux/slices/applicationsSlice';

import ReactCarouselPhoto from '../../common/ReactCarouselPhoto';
import { FancyboxTask } from '../../common/FancyboxTask';

export const ApplicationPhotoExecutor = () => {
    const application = useSelector(application_state);
    const executorPhotos = [...application.start_photo_list, ...application.end_photo_list];
    const [selectPhoto, setSelectPhoto] = useState(executorPhotos[0]);

    const [renderPhoto, setRenderPhoto] = useState(null);

    useEffect(() => {
        if (executorPhotos.length > 0) {
            setRenderPhoto(executorPhotos.filter(el => el !== selectPhoto));
        }
    }, [selectPhoto]);

    return (
        <>
            {executorPhotos.length === 0 ? (
                <div className="text-center text-base font-semibold opacity-50 mt-10">{t('APPLICATIONS_ATTACHED_PHOTO')}</div>
            ) : (
                <>
                    <div className="w-full cursor-pointer">
                        <FancyboxTask photo={selectPhoto} setPhoto={setSelectPhoto}>
                            <>
                                <img data-caption={t('APPLICATIONS_EXECUTOR')} data-fancybox="gallery" width={'100%'} className="h-64 object-cover" src={selectPhoto} alt="select-photo" />

                                <div className="hidden">
                                    {renderPhoto?.map((image, idx) => (
                                        <div data-caption={t('APPLICATIONS_EXECUTOR')} data-fancybox="gallery" data-src={`${image}`} key={`image${idx}`}>
                                            <img src={`${image}`} alt="" className="w-full h-full object-cover" />
                                        </div>
                                    ))}
                                </div>
                            </>
                        </FancyboxTask>
                    </div>
                    <div id="myCarousel" className="carousel carouselPhoto">
                        <ReactCarouselPhoto
                            content={executorPhotos}
                            setSelect={setSelectPhoto}
                            options={{
                                infinity: false,
                                Dots: false,
                            }}
                            selectedFancyPhoto={selectPhoto}
                        />
                    </div>
                </>
            )}
        </>
    );
};
