import { useDispatch, useSelector } from 'react-redux';
import { departmentsFetch, filter_fetch as filter_fetch_state, set_detail_open, venueFetch, venues as venues_state } from '../../redux/slices/adminSchedulerSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { t } from 'i18next';
import { useCookies } from 'react-cookie';

export const CreateTaskButton = () => {
    const dispatch = useDispatch();
    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const venues = useSelector(venues_state);
    const filter_fetch = useSelector(filter_fetch_state);
    const [cookies, setCookie] = useCookies();

    const lang = cookies.i18next;

    const handlerClick = async () => {
        if (!venues.length) {
            await dispatch(venueFetch({ username, auth_key_calendar, lang }));
        }
        if (!filter_fetch.departments.length) {
            await dispatch(departmentsFetch(lang));
        }
        await dispatch(set_detail_open(true));
    };

    return (
        <div className="flex items-center">
            <button className="bg-blue hover:bg-blue-500 transition-colors duration-200 text-white font-semibold rounded-[30px] px-6 py-2" onClick={handlerClick}>
                + {t('ADM_PLANNER_CREATE_TASK')}
            </button>
        </div>
    );
};
