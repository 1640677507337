export const PopupHead = () => {
    return (
        <div className="flex mt-5">
            <span className="w-20 mr-5">Статус</span>
            <span className="w-12 mr-5">Фото</span>
            <span className="w-48 mr-5">Выбор точки</span>
            <span className="w-24 mr-5">Значение</span>
            <span>Наименование точки</span>
        </div>
    );
};
