import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { ApplicationPath } from '../components/applications/application/ApplicationPath';
import { Detail } from '../components/applications/Detail';
import { ApplicationMainInfo } from '../components/applications/application/ApplicationMainInfo';
import { ApplicationFiles } from '../components/applications/application/ApplicationFiles';
import { ApplicationHistory } from '../components/applications/application/ApplicationHistory';
import { ApplicationChat } from '../components/applications/application/ApplicationChat';
import { ApplicationInfo } from '../components/applications/application/ApplicationInfo';

import { application as application_state, getApplicationFetch, set_new_application_info } from '../redux/slices/applicationsSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../redux/slices/loginSlice';

export const ApplicationPage = () => {
    const [differentTaskValue, setDifferentTaskValue] = useState({});
    const [differentInfo, setDifferentInfo] = useState({});
    const [differentPhoto, setDifferentPhoto] = useState({});

    const application = useSelector(application_state);
    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);

    const [cookie] = useCookies();
    const lang = cookie.i18next;

    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        if (id && !application) {
            dispatch(getApplicationFetch({ username, auth_key_calendar, id, lang }));
        }
    }, [id]);
    const newInfo = data => {
        setDifferentInfo(data);
    };

    const newPhoto = ({ creatorPhoto, executorPhoto }) => {
        // console.log('newPhoto ~ creatorPhoto', creatorPhoto);
        if (creatorPhoto && executorPhoto) {
            setDifferentPhoto({ ...{ creatorPhoto: [...creatorPhoto] }, ...{ executorPhoto: [...executorPhoto] } });
        } else if (creatorPhoto) {
            if (Object.keys(differentPhoto).length === 0) {
                setDifferentPhoto({ creatorPhoto: [...creatorPhoto] });
            } else {
                const tmp = [...differentPhoto.creatorPhoto];

                tmp.unshift(creatorPhoto[0]);
                setDifferentPhoto({ ...{ creatorPhoto: tmp } });
            }
        } else if (executorPhoto) {
            setDifferentPhoto({ ...{ executorPhoto: [...executorPhoto] } });
        }
    };
    useEffect(() => {
        if (Object.keys(differentInfo).length !== 0 || Object.keys(differentPhoto).length !== 0) {
            // dispatch(set_new_application_info(true));
            setDifferentTaskValue({ ...differentInfo, ...differentPhoto, ...{ id: application?.id } });
        }
    }, [differentInfo, differentPhoto]);

    useEffect(() => {
        dispatch(set_new_application_info(differentTaskValue));
    }, [differentTaskValue]);

    return (
        <>
            {application && (
                <>
                    <ApplicationPath />
                    <div className="flex w-full">
                        <div className="w-[42%]">
                            <ApplicationMainInfo date={newInfo} />
                        </div>
                        <div className="mx-6 w-[27%]">
                            <ApplicationFiles photo={newPhoto} />
                            <ApplicationHistory />
                        </div>
                        <div className="w-[31%]">
                            <ApplicationChat />
                            {application?.department_id === 6 && <ApplicationInfo />}
                        </div>
                    </div>
                </>
            )}
            <Detail />
        </>
    );
};
