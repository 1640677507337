import React, { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import './source/styles/index.scss';

import {
    auth_fetching as auth_fetching_state,
    authFetching,
    name as name_state,
    getLanguage,
    setLanguageFetch,
    lang as lang_state,
} from './redux/slices/loginSlice';
import { Loader } from './components/common/Loader';

export const App = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [cookies, setCookie] = useCookies();
    const { ready } = useTranslation();

    const auth_fetching = useSelector(auth_fetching_state);
    const name = useSelector(name_state);
    const lang = useSelector(lang_state);

    const username = new URLSearchParams(window.location.search).get('username') || localStorage.getItem('username');
    const auth_key_calendar = new URLSearchParams(window.location.search).get('auth_key_calendar') || localStorage.getItem('auth_key_calendar');
    const langUrl = new URLSearchParams(window.location.search).get('lang');
    const langCookies = cookies.i18next;

    useEffect(() => {
        document.querySelector('html').style.scrollBehavior = 'auto';
        window.scroll({ top: 0 });
        document.querySelector('html').style.scrollBehavior = '';
    }, [location.pathname]);

    const setLng = async lng => {
        await i18n.changeLanguage(lng);
        await dispatch(setLanguageFetch({ username, auth_key_calendar, lang: lng }));
        setCookie('i18next', lng, { path: '/' });
    };

    useEffect(() => {
        //check auth
        dispatch(authFetching({ username, auth_key_calendar, langCookies }));

        //set lng
        if (langUrl) {
            (async () => await setLng(langUrl))();
        } else if (langCookies) {
            (async () => await setLng(langCookies))();
        } else {
            dispatch(getLanguage({ username, auth_key_calendar }));
        }
    }, [dispatch, username, auth_key_calendar]);

    useEffect(() => {
        if (!langUrl && !langCookies && lang) {
            (async () => await setLng(lang))();
        }
    }, [lang, langCookies, langUrl]);

    if (!name || auth_fetching || !langCookies) {
        return (
            <div className="fixed w-full h-full flex items-center justify-center bg-gray-100 z-60">
                <Loader height={60} width={60} />
            </div>
        );
    }
    if (ready) {
        return <Outlet />;
    }
    return <span>Loading...</span>;
};
