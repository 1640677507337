import { createSlice } from '@reduxjs/toolkit';
import { hospitalityAPI } from '../../api/modules/hospitalityAPI';
import { store } from '../store';

export const hospitalitySlice = createSlice({
    name: 'hospitality',
    initialState: {
        objects: [],
        objects_fetching: false,
        object: null,
        tasks_statuses: [],
        tasks_statuses_fetching: false,
        clear_types: [],
        clear_types_fetching: false,
        workers: [],
        workers_fetching: false,
        tasks: [],
        tasks_fetching: false,
        supervisors: [],
        supervisors_fetching: false,
        filter_for_tasks: {
            is_touch: false,
            data: {
                clearTypes: [],
                statuses: [],
                rooms: {
                    from: '',
                    to: '',
                },
            },
        },
        settings: {
            is_auto: 0,
        },
        settings_fetching: false,
        apply_tasks_fetching: false,
        apply_cleaners_fetching: false,
        reset_all_tasks_fetching: false,
        distribute_tasks_fetching: false,

        rooms: [],
        typical_tasks_list: [],
    },

    reducers: {
        set_objects: (state, action) => {
            state.objects = action.payload;
        },
        set_objects_fetching: (state, action) => {
            state.objects_fetching = action.payload;
        },
        set_object: (state, action) => {
            state.object = action.payload;
        },
        clear_objects: state => {
            state.objects = [];
            state.object = null;
        },
        set_tasks_statuses: (state, action) => {
            state.tasks_statuses = action.payload;
        },
        set_tasks_statuses_fetching: (state, action) => {
            state.tasks_statuses_fetching = action.payload;
        },
        set_clear_types: (state, action) => {
            state.clear_types = action.payload;
        },
        set_clear_types_fetching: (state, action) => {
            state.clear_types_fetching = action.payload;
        },
        set_workers: (state, action) => {
            state.workers = action.payload;
        },
        update_workers_after_set: (state, action) => {
            state.workers = state.workers.map(worker => (action.payload.find(el => el === worker.id) ? { ...worker, state: 1 } : { ...worker, state: 0 }));
        },
        update_workers_after_filter: (state, action) => {
            if (action.payload.reset) {
                state.workers = state.workers.map(worker => ({ ...worker, is_filtered: true }));
            } else {
                state.workers = state.workers.map(worker =>
                    action.payload.tasks.find(filtered_task => filtered_task.user_id === worker.id)
                        ? { ...worker, is_filtered: true }
                        : { ...worker, is_filtered: false }
                );
            }
        },
        update_workers_after_remove_supervisor: (state, action) => {
            state.workers = state.workers.map(worker => (worker.supervisor_id === action.payload ? { ...worker, supervisor_id: null } : worker));
        },
        set_workers_fetching: (state, action) => {
            state.workers_fetching = action.payload;
        },
        update_supervisors_after_set: (state, action) => {
            state.supervisors = state.supervisors.map(supervisor =>
                action.payload.find(el => el === supervisor.id) ? { ...supervisor, state: 1 } : { ...supervisor, state: 0 }
            );
        },
        set_tasks: (state, action) => {
            state.tasks = action.payload;
        },
        update_tasks_after_move: (state, action) => {
            state.tasks = state.tasks.map(task =>
                task.task_id === action.payload.task_id ? { ...task, user_id: action.payload.user_id, is_applied: false } : task
            );
        },
        update_workers_after_move: (state, action) => {
            state.workers = state.workers.map(worker =>
                worker.id === action.payload.user_id ? { ...worker, supervisor_id: action.payload.supervisor_id, is_applied: false } : worker
            );
        },
        update_tasks_after_filter: (state, action) => {
            state.tasks = state.tasks.map(task => {
                const filtered_task = action.payload.find(filtered_task => filtered_task.task_id === task.task_id);
                if (filtered_task) {
                    return { ...filtered_task, is_filtered: true };
                }

                return { ...task, is_filtered: false };
            });
        },
        set_tasks_fetching: (state, action) => {
            state.tasks_fetching = action.payload;
        },
        set_filter_for_tasks: (state, action) => {
            state.filter_for_tasks = {
                is_touch: true,
                data: {
                    ...state.filter_for_tasks.data,
                    [action.payload.type]: action.payload.val,
                },
            };
        },
        reset_filter_for_tasks: state => {
            state.filter_for_tasks = {
                is_touch: false,
                data: {
                    clearTypes: [],
                    statuses: [],
                    rooms: {
                        from: '',
                        to: '',
                    },
                },
            };
        },
        set_settings: (state, action) => {
            state.settings = action.payload;
        },
        update_settings: (state, action) => {
            state.settings = { ...state.settings, ...action.payload };
        },
        set_settings_fetching: (state, action) => {
            state.settings_fetching = action.payload;
        },
        set_apply_tasks_fetching: (state, action) => {
            state.apply_tasks_fetching = action.payload;
        },
        set_apply_cleaners_fetching: (state, action) => {
            state.apply_cleaners_fetching = action.payload;
        },
        set_reset_all_tasks_fetching: (state, action) => {
            state.reset_all_tasks_fetching = action.payload;
        },
        set_distribute_tasks_fetching: (state, action) => {
            state.distribute_tasks_fetching = action.payload;
        },
        set_supervisors: (state, action) => {
            state.supervisors = action.payload;
        },
        set_supervisors_fetching: (state, action) => {
            state.supervisors_fetching = action.payload;
        },
        update_workers_after_clear_all_supervisor: (state, action) => {
            state.workers = state.workers.map(worker =>
                action.payload === null
                    ? worker.supervisor_id && !worker.is_applied
                        ? { ...worker, supervisor_id: null }
                        : worker
                    : worker.supervisor_id && !worker.is_applied && worker.supervisor_id === action.payload
                    ? { ...worker, supervisor_id: null }
                    : worker
            );
        },
        set_apply_workers: (state, _) => {
            state.workers = state.workers.map(worker => (worker.supervisor_id ? { ...worker, is_applied: true } : worker));
        },
        set_rooms: (state, action) => {
            state.rooms = action.payload;
        },
        set_typical_tasks_list: (state, action) => {
            state.typical_tasks_list = action.payload;
        },
    },
});

export const {
    set_typical_tasks_list,
    set_rooms,
    set_apply_workers,
    set_apply_cleaners_fetching,
    update_workers_after_clear_all_supervisor,
    update_workers_after_remove_supervisor,
    update_workers_after_move,
    update_supervisors_after_set,
    set_supervisors,
    set_supervisors_fetching,
    set_objects,
    set_objects_fetching,
    set_object,
    clear_objects,
    set_tasks_statuses,
    set_tasks_statuses_fetching,
    set_clear_types,
    set_clear_types_fetching,
    set_workers,
    update_workers_after_filter,
    update_workers_after_set,
    set_workers_fetching,
    set_tasks,
    update_tasks_after_move,
    update_tasks_after_filter,
    set_tasks_fetching,
    set_filter_for_tasks,
    reset_filter_for_tasks,
    set_settings,
    update_settings,
    set_settings_fetching,
    set_apply_tasks_fetching,
    set_reset_all_tasks_fetching,
    set_distribute_tasks_fetching,
} = hospitalitySlice.actions;

//получить список объектов
export const getHospitalityObjects = () => async dispatch => {
    dispatch(set_objects_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;

    try {
        const response = await hospitalityAPI.getHospitalityObjects.fetch(username, auth_key_calendar, lang);

        if (response.status === 200) {
            const result = await response.json();

            if (result.data && result.data.objects && result.data.objects.length) {
                dispatch(set_objects(result.data.objects));
                dispatch(set_object(result.data.objects[0]));
            }
        }
    } catch (e) {
        console.log('getHospitalityObjects error: ', e);
    } finally {
        dispatch(set_objects_fetching(false));
    }
};

//получить список статусов задач
export const getTasksStatuses = () => async dispatch => {
    dispatch(set_tasks_statuses_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;

    try {
        const response = await hospitalityAPI.getTasksStatuses.fetch(username, auth_key_calendar, lang);

        if (response.status === 200) {
            const result = await response.json();
            if (result && result.data && result.data.length) {
                dispatch(set_tasks_statuses(result.data));
            }
        }
    } catch (e) {
        console.log('getTasksStatuses error: ', e);
    } finally {
        dispatch(set_tasks_statuses_fetching(false));
    }
};

//получить список типов уборок
export const getClearTypes = () => async dispatch => {
    dispatch(set_clear_types_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;

    try {
        const response = await hospitalityAPI.getClearTypes.fetch(username, auth_key_calendar, lang);

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.data && result.data.length) {
                dispatch(set_clear_types(result.data));
            }
        }
    } catch (e) {
        console.log('getClearTypes error: ', e);
    } finally {
        dispatch(set_clear_types_fetching(false));
    }
};

//получить список сотрудников объекта
export const getWorkers = () => async dispatch => {
    dispatch(set_workers_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;
    const object = store.getState().hospitality.object;

    try {
        const response = await hospitalityAPI.getWorkers.fetch(username, auth_key_calendar, lang, { object_id: object.id });

        if (response.status === 200) {
            const result = await response.json();

            if (result.data && result.data.users) {
                const workers = result.data.users.map(worker => ({
                    id: worker.id,
                    name: `${worker.last_name} ${worker.first_name} ${worker.middle_name}`.trim(),
                    state: worker.state, // назначен на работу сегодня или нет
                    is_filtered: true, //отфильтрован
                    supervisor_id: worker.supervisor_id,
                    is_applied: worker.supervisor_applied,
                }));

                dispatch(set_workers(workers));
            }
        }
    } catch (e) {
        console.log('getWorkers error: ', e);
    } finally {
        dispatch(set_workers_fetching(false));
    }
};

//назначить пользователей работниками на сегодня на объекте
export const setWorkersAtWork =
    ({ user_ids }) =>
    async dispatch => {
        dispatch(set_workers_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const object = store.getState().hospitality.object;

        try {
            const response = await hospitalityAPI.setWorkersAtWork.fetch(username, auth_key_calendar, { user_ids, object_id: object.id });

            if (response.status === 200) {
                const result = await response.json();

                if (result.data) {
                    dispatch(update_workers_after_set(JSON.parse(user_ids)));
                    await dispatch(updateTasks());
                }
            }
        } catch (e) {
            console.log('setWorkersAtWork error: ', e);
        } finally {
            dispatch(set_workers_fetching(false));
        }
    };

// назначить супервайзеров работниками на сегодня на объекте
export const setSupervisorsAtWork =
    ({ user_ids, supervisor_id }) =>
    async dispatch => {
        dispatch(set_supervisors_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const object = store.getState().hospitality.object;

        try {
            const response = await hospitalityAPI.setSupervisorsAtWork.fetch(username, auth_key_calendar, { user_ids, object_id: object.id });

            if (response.status === 200) {
                const result = await response.json();

                if (result.data) {
                    dispatch(update_supervisors_after_set(JSON.parse(user_ids)));
                    dispatch(update_workers_after_remove_supervisor(supervisor_id));
                }
            }
        } catch (e) {
            console.log('setSupervisorsAtWork error: ', e);
        } finally {
            dispatch(set_supervisors_fetching(false));
        }
    };

//получить список задач
export const getTasks = () => async dispatch => {
    dispatch(set_tasks_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;
    const object = store.getState().hospitality.object;

    try {
        const response = await hospitalityAPI.getTasks.fetch(username, auth_key_calendar, lang, { object_id: object.id });

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.data) {
                dispatch(set_tasks(result.data.map(task => ({ ...task, is_filtered: true }))));
            }
        }
    } catch (e) {
        console.log('getTasks error: ', e);
    } finally {
        dispatch(set_tasks_fetching(false));
    }
};

//отфильтровать список задач
export const updateTasks = () => async dispatch => {
    dispatch(set_tasks_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;
    const filter = store.getState().hospitality.filter_for_tasks;
    const object = store.getState().hospitality.object;

    const filter_data = {
        clear_status_id: filter.data.clearTypes.length && JSON.stringify(filter.data.clearTypes.map(item => item.value)),
        status_id: filter.data.statuses.length && JSON.stringify(filter.data.statuses.map(item => item.value)),
        room_from: filter.data.rooms.from,
        room_to: filter.data.rooms.to,
    };

    let clearFilter = true;
    for (const item in filter_data) {
        if (filter_data[item]) {
            clearFilter = false;
            break;
        }
    }

    try {
        const response = await hospitalityAPI.getTasks.fetch(username, auth_key_calendar, lang, {
            object_id: object.id,
            ...filter_data,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.data) {
                dispatch(update_tasks_after_filter(result.data));
                dispatch(update_workers_after_filter({ tasks: result.data.filter(task => task.user_id), reset: clearFilter }));
            }
        }
    } catch (e) {
        console.log('updateTasks error: ', e);
    } finally {
        dispatch(set_tasks_fetching(false));
    }
};

// отфильтровать список супервайзеров
export const updateWorkers = () => async dispatch => {
    dispatch(set_workers_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;
    const filter = store.getState().hospitality.filter_for_tasks;
    const object = store.getState().hospitality.object;

    const filter_data = {
        clear_status_id: filter.data.clearTypes.length && JSON.stringify(filter.data.clearTypes.map(item => item.value)),
        status_id: filter.data.statuses.length && JSON.stringify(filter.data.statuses.map(item => item.value)),
    };

    try {
        const response = await hospitalityAPI.getWorkers.fetch(username, auth_key_calendar, lang, {
            object_id: object.id,
            ...filter_data,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.data) {
                const workers = result.data.users.map(worker => ({
                    id: worker.id,
                    name: `${worker.last_name} ${worker.first_name} ${worker.middle_name}`.trim(),
                    state: worker.state, // назначен на работу сегодня или нет
                    is_filtered: true, //отфильтрован
                    supervisor_id: worker.supervisor_id,
                    is_applied: worker.supervisor_applied,
                }));
                dispatch(set_workers(workers));
            }
        }
    } catch (e) {
        console.log('updateWorkers error: ', e);
    } finally {
        dispatch(set_workers_fetching(false));
    }
};

//перенос задач в шахматке
export const moveTask =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const object = store.getState().hospitality.object;

        dispatch(update_tasks_after_move(data));

        try {
            const response = await hospitalityAPI.moveTask.fetch(username, auth_key_calendar, { ...data, object_id: object.id });
            let error = false;

            if (response.status === 200) {
                const result = await response.json();
                if (!result.data) {
                    error = true;
                }
            } else {
                error = true;
            }

            if (error) {
                await dispatch(updateTasks());
            }
        } catch (e) {
            console.log('moveTask error: ', e);
        }
    };

// перенос клинёров в шахматке
export const moveCleaners =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const object = store.getState().hospitality.object;

        dispatch(update_workers_after_move(data));

        try {
            const response = await hospitalityAPI.moveCleaners.fetch(username, auth_key_calendar, { ...data, object_id: object.id });

            if (response.status === 200) {
                const result = await response.json();

                if (!result.data) {
                    dispatch(updateWorkers());
                    return false;
                }
                return true;
            }
        } catch (e) {
            console.log('moveCleaners error: ', e);
        }
    };

//автоматическое распределение задач
export const actDoAuto = () => async dispatch => {
    dispatch(set_distribute_tasks_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const object = store.getState().hospitality.object;

    try {
        const response = await hospitalityAPI.actDoAuto.fetch(username, auth_key_calendar, { object_id: object.id });

        if (response.status === 200) {
            const result = await response.json();

            if (result.data) {
                await dispatch(updateTasks());
            }

            return result.data;
        }
    } catch (e) {
        console.log('actDoAuto error: ', e);
    } finally {
        dispatch(set_distribute_tasks_fetching(false));
    }

    return false;
};

//автоматическое распределение клинёров
export const actDoAutoWorkers = () => async dispatch => {
    dispatch(set_distribute_tasks_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const object = store.getState().hospitality.object;

    try {
        const response = await hospitalityAPI.actDoAutoWorkers.fetch(username, auth_key_calendar, { object_id: object.id });

        if (response.status === 200) {
            const result = await response.json();

            if (result.data) {
                await dispatch(updateWorkers());
            }

            return result.data;
        }
    } catch (e) {
        console.log('actDoAuto error: ', e);
    } finally {
        dispatch(set_distribute_tasks_fetching(false));
    }

    return false;
};

//сброс распределения задач
export const actResetAll = () => async dispatch => {
    dispatch(set_reset_all_tasks_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const object = store.getState().hospitality.object;

    try {
        const response = await hospitalityAPI.actResetAll.fetch(username, auth_key_calendar, { object_id: object.id });

        if (response.status === 200) {
            const result = await response.json();

            if (result.data) {
                await dispatch(updateTasks());
            }

            return result.data;
        }
    } catch (e) {
        console.log('actResetAll error: ', e);
    } finally {
        dispatch(set_reset_all_tasks_fetching(false));
    }

    return false;
};

//сброс распределения для клинёра
export const actResetSupervisors =
    ({ supervisor_id }) =>
    async dispatch => {
        dispatch(set_reset_all_tasks_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const object = store.getState().hospitality.object;

        try {
            const response = await hospitalityAPI.actResetForSupervisor.fetch(username, auth_key_calendar, { object_id: object.id, supervisor_id });

            if (response.status === 200) {
                const result = await response.json();

                if (result.data) {
                    await dispatch(update_workers_after_clear_all_supervisor(supervisor_id));
                }

                return result.data;
            }
        } catch (e) {
            console.log('actResetAll error: ', e);
        } finally {
            dispatch(set_reset_all_tasks_fetching(false));
        }

        return false;
    };

//сброс распределения задач для пользователя
export const actResetForUser =
    ({ user_id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const object = store.getState().hospitality.object;

        try {
            const response = await hospitalityAPI.actResetForUser.fetch(username, auth_key_calendar, { object_id: object.id, user_id });

            if (response.status === 200) {
                const result = await response.json();

                if (result.data) {
                    await dispatch(updateTasks());
                }

                return result.data;
            }
        } catch (e) {
            console.log('actResetForUser error: ', e);
        }

        return false;
    };

//сброс распределения клинеров для супервайзера
export const actResetForSupervisor =
    ({ supervisor_id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const object = store.getState().hospitality.object;

        try {
            const response = await hospitalityAPI.actResetForSupervisor.fetch(username, auth_key_calendar, { object_id: object.id, supervisor_id });

            if (response.status === 200) {
                const result = await response.json();

                if (result.data) {
                    dispatch(update_workers_after_remove_supervisor(supervisor_id));
                }

                return result.data;
            }
        } catch (e) {
            console.log('actResetForUser error: ', e);
        }

        return false;
    };

//применяет перенос распределения задач
export const actApplyAll = () => async dispatch => {
    dispatch(set_apply_tasks_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const object = store.getState().hospitality.object;

    try {
        const response = await hospitalityAPI.actApplyAll.fetch(username, auth_key_calendar, { object_id: object.id });

        if (response.status === 200) {
            const result = await response.json();

            if (result.data) {
                await dispatch(updateTasks());
            }

            return result.data;
        }
    } catch (e) {
        console.log('actApplyAll error: ', e);
    } finally {
        dispatch(set_apply_tasks_fetching(false));
    }

    return false;
};

// применяет перенос распределения клинёров
export const actApplySupervisorsAll = () => async dispatch => {
    dispatch(set_apply_cleaners_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const object = store.getState().hospitality.object;

    try {
        const response = await hospitalityAPI.actApplySupervisorsAll.fetch(username, auth_key_calendar, { object_id: object.id });

        if (response.status === 200) {
            const result = await response.json();

            if (result.data) {
                await dispatch(set_apply_workers());
            }

            return result.data;
        }
    } catch (e) {
        console.log('actApplySupervisorsAll error: ', e);
    } finally {
        dispatch(set_apply_cleaners_fetching(false));
    }

    return false;
};

//получить список настроек для объекта
export const getSettings = () => async dispatch => {
    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;
    const object = store.getState().hospitality.object;

    try {
        const response = await hospitalityAPI.getSettings.fetch(username, auth_key_calendar, lang, { object_id: object.id });

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.data) {
                dispatch(set_settings(result.data));
            }
        }
    } catch (e) {
        console.log('getSettings error: ', e);
    }
};

//обновить список настроек для объекта
export const setSettings =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const object = store.getState().hospitality.object;

        try {
            const response = await hospitalityAPI.setSettings.fetch(username, auth_key_calendar, { object_id: object.id, ...data });

            if (response.status === 200) {
                const result = await response.json();

                if (result && result.data) {
                    dispatch(update_settings(JSON.parse(data.options)));
                }
            }
        } catch (e) {
            console.log('setSettings error: ', e);
        }
    };

// получение списка супервайзеров
export const getSupervisors = () => async dispatch => {
    dispatch(set_supervisors_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;
    const object = store.getState().hospitality.object;

    try {
        const response = await hospitalityAPI.getSupervisors.fetch(username, auth_key_calendar, lang, { object_id: object.id });

        if (response.status === 200) {
            const result = await response.json();

            if (result.data && result.data.users) {
                const supervisors = result.data.users.map(supervisor => ({
                    id: supervisor.id,
                    name: `${supervisor.last_name} ${supervisor.first_name} ${supervisor.middle_name}`.trim(),
                    state: supervisor.state, // назначен на работу сегодня или нет
                    is_filtered: true, //отфильтрован
                }));

                dispatch(set_supervisors(supervisors));
            }
        }
    } catch (e) {
        console.log('getSupervisors error: ', e);
    } finally {
        dispatch(set_supervisors_fetching(false));
    }
};

// получение списка номеров по объекту
export const getRooms = () => async dispatch => {
    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;
    const object = store.getState().hospitality.object;

    try {
        const response = await hospitalityAPI.getRooms.fetch(username, auth_key_calendar, lang, { object_id: object.id });

        if (response.status === 200) {
            const result = await response.json();
            if (result.code === 0) {
                const rooms = result.data.map(room => ({ room_number: room.office_title, room_id: room.office_id }));
                dispatch(set_rooms(rooms));
            }
        }
    } catch (e) {
        console.log('getRooms error: ', e);
    }
};

// получение списка типовых задач по объекту
export const getTypicalTasksList = () => async dispatch => {
    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;
    const object = store.getState().hospitality.object;

    try {
        const response = await hospitalityAPI.getTypicalTasksList.fetch(username, auth_key_calendar, lang, { object_id: object.id });

        if (response.status === 200) {
            const result = await response.json();

            if (result.code === 0) {
                dispatch(set_typical_tasks_list(result.data));
            }
        }
    } catch (e) {
        console.log('getTypicalTasksList error: ', e);
    }
};

// создание заявки
export const createTask =
    ({ data }) =>
    async dispatch => {
        dispatch(set_tasks_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const lang = store.getState().login.lang;
        const object = store.getState().hospitality.object;

        try {
            const response = await hospitalityAPI.createTask.fetch(username, auth_key_calendar, lang, { object_id: object.id, ...data });

            if (response.status === 200) {
                const result = await response.json();
                if (result.data) {
                    dispatch(getTasks());
                    return true;
                } else {
                    return false;
                }
            }
        } catch (e) {
            console.log('createTask error: ', e);
        }
    };

export const objects = state => state.hospitality.objects;
export const objects_fetching = state => state.hospitality.objects_fetching;
export const object = state => state.hospitality.object;
export const tasks_statuses = state => state.hospitality.tasks_statuses;
export const tasks_statuses_fetching = state => state.hospitality.tasks_statuses_fetching;
export const clear_types = state => state.hospitality.clear_types;
export const clear_types_fetching = state => state.hospitality.clear_types_fetching;
export const workers = state => state.hospitality.workers;
export const workers_fetching = state => state.hospitality.workers_fetching;
export const tasks = state => state.hospitality.tasks;
export const tasks_fetching = state => state.hospitality.tasks_fetching;
export const filter_for_tasks = state => state.hospitality.filter_for_tasks;
export const settings = state => state.hospitality.settings;
export const apply_tasks_fetching = state => state.hospitality.apply_tasks_fetching;
export const reset_all_tasks_fetching = state => state.hospitality.reset_all_tasks_fetching;
export const distribute_tasks_fetching = state => state.hospitality.distribute_tasks_fetching;
export const auto_mode = state => state.hospitality.settings.is_auto;
export const supervisors = state => state.hospitality.supervisors;
export const supervisors_fetching = state => state.hospitality.supervisors_fetching;
export const apply_cleaners_fetching = state => state.hospitality.apply_cleaners_fetching;
export const rooms = state => state.hospitality.rooms;
export const task_list = state => state.hospitality.typical_tasks_list;

export default hospitalitySlice.reducer;
