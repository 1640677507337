import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const BreadcrumbsLink = ({ children, link }) => {
    const { venueId, systemId, documentId } = useParams();

    const [dynamicLink, setDynamicLink] = useState('');

    useEffect(() => {
        localStorage.setItem('venueId', venueId);
        localStorage.setItem('systemId', systemId);
        localStorage.setItem('documentId', documentId);
        setDynamicLink(
            link
                .replace(/:venueId/g, venueId)
                .replace(/:systemId/g, systemId)
                .replace(/:documentId/g, documentId)
        );
    }, [venueId, systemId, documentId, link]);

    return (
        <Link to={dynamicLink} className="text-blue hover:text-gray">
            {children}
        </Link>
    );
};
