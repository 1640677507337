import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { createBMSDoc, set_document, update_bms_doc_list } from '../../../../../redux/slices/bmsSlice';
import { toastOptions } from '../../../../../api/functions';

const SignupSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Поле "Название" слишком короткое').max(100, 'Поле "Название" слишком длинное').required('Поле "Название" обязательное'),
    description: Yup.string().min(2, 'Поле "Описание" слишком короткое').max(1000, 'Поле "Описание" слишком длинное'),
});

export const DocumentInformationForm = ({ info, formRef }) => {
    const dispatch = useDispatch();
    const { venueId, systemId } = useParams();

    const onSubmit = async values => {
        e.preventDefault();
        const data = { ...values, object_id: venueId, direction_id: systemId };
        const result = await dispatch(createBMSDoc({ data }));

        if (!result.error) {
            const doc = {
                ...data,
                direction_id: +data.direction_id,
                id: result.id,
                venue_id: null,
                nodes: [],
                controls: [],
            };

            dispatch(set_document(doc));
            dispatch(update_bms_doc_list(doc));
        } else {
            toast.error(result.msg, toastOptions);
        }
    };

    return (
        <div className="mb-6">
            <Formik initialValues={{ title: info.title, description: info.description }} onSubmit={onSubmit} validationSchema={SignupSchema} innerRef={formRef}>
                {({ errors, isValidating, isSubmitting }) => {
                    if (isSubmitting && !isValidating) {
                        for (const err in errors) {
                            toast.error(errors[err].toString(), toastOptions);
                        }
                    }

                    return (
                        <Form>
                            <div className="mb-6">
                                <div className="mb-1 text-sm font-bold">Название BMS системы*</div>
                                <Field
                                    name="title"
                                    type="text"
                                    placeholder="Введите название BMS системы"
                                    className="w-[33rem] h-12 p-2 border rounded box-border text-sm border-gray-10"
                                />
                            </div>
                            <div className="mb-6">
                                <div className="mb-1 text-sm font-bold">Описание BMS системы</div>
                                <Field
                                    name="description"
                                    as="textarea"
                                    placeholder="Введите описание BMS системы"
                                    className="w-[33rem] h-32 p-2 border rounded box-border text-sm border-gray-10 resize-none"
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
