import { ButtonTile } from '../../common/btn/ButtonTile';

export const CreateVenueButton = ({ setPopupIsOpen }) => {
    return (
        <ButtonTile onClick={setPopupIsOpen} className="justify-end pb-5">
            <svg width="25" height="25" viewBox="0 0 12 12" className="fill-current mb-2">
                <path d="M5.16602 11.8332V6.83317H0.166016V5.1665H5.16602V0.166504H6.83268V5.1665H11.8327V6.83317H6.83268V11.8332H5.16602Z" />
            </svg>
            <span> Новый объект</span>
        </ButtonTile>
    );
};
