import { useState, useEffect } from 'react';

import { url } from '../../../../../api/config';
import { SimpleSelect } from '../../../../common/widgets/SimpleSelect';

const initState = { value: '', node: null, title: '' };

export const NodeStatusForm = ({ nodeOptions, node, nodeStates, setNodeStates, statusInfo }) => {
    const [nodeStatusInfo, setNodeStatusInfo] = useState(statusInfo ? { ...initState, value: statusInfo.value } : initState);
    const [selectValue, setSelectValue] = useState([]);

    useEffect(() => {
        if (statusInfo && nodeOptions) {
            setSelectValue(nodeOptions.find(el => el.value === statusInfo.node));
        }
    }, [statusInfo, nodeOptions]);

    useEffect(() => {
        if (selectValue.datatype === 'boolean') {
            setNodeStatusInfo({
                ...nodeStatusInfo,
                node: selectValue,
                value: statusInfo.value ? (statusInfo.value === 'true' ? { value: 1, label: 'true' } : { value: 0, label: 'false' }) : '',
            });
        }
    }, [selectValue]);

    return (
        <>
            <span className="w-20 mr-5"> {node.name} </span>
            {node.pic ? <img src={`${url}${node.pic}`} alt={node.name} className="w-12 h-12 mr-5" /> : <div className="w-12 h-12 mr-5"></div>}
            <SimpleSelect
                isClearable={false}
                options={nodeOptions}
                placeholder="Выберите точку"
                value={selectValue}
                callback={val => {
                    if (val === null) {
                        return;
                    }
                    setNodeStatusInfo({ ...nodeStatusInfo, node: val, value: '' });
                    setNodeStates(() => {
                        if (nodeStates.find(el => el.name === node.name)) {
                            return nodeStates.map(el => (el.name === node.name ? { ...el, node: val.value, value: '' } : el));
                        } else {
                            return [...nodeStates, { node: val?.value, name: node.name, pic: node.pic, operation: '=' }];
                        }
                    });
                }}
                className="w-48 mr-5"
            />
            {nodeStatusInfo?.node?.datatype !== 'boolean' ? (
                <input
                    type="text"
                    value={nodeStatusInfo.value}
                    disabled={!statusInfo?.node && !nodeStatusInfo.node}
                    className="w-24 h-10 mr-5"
                    onChange={e => {
                        setNodeStatusInfo({ ...nodeStatusInfo, value: e.target.value });
                        setNodeStates(() => {
                            if (nodeStates.find(el => el.name === node.name)) {
                                return nodeStates.map(el => (el.name === node.name ? { ...el, value: e.target.value } : el));
                            } else {
                                return [...nodeStates, { value: e.target.value, name: node.name, pic: node.pic, operation: '=' }];
                            }
                        });
                    }}
                />
            ) : (
                <SimpleSelect
                    isClearable={false}
                    options={[
                        { value: 1, label: 'true' },
                        { value: 0, label: 'false' },
                    ]}
                    placeholder="Выберите значение"
                    value={nodeStatusInfo.value}
                    callback={val => {
                        setNodeStatusInfo({ ...nodeStatusInfo, val });
                        setNodeStates(() => {
                            if (nodeStates.find(el => el.name === node.name)) {
                                return nodeStates.map(el => (el.name === node.name ? { ...el, value: val.label } : el));
                            } else {
                                return [...nodeStates, { value: val.label, name: node.name, pic: node.pic, operation: '=' }];
                            }
                        });
                    }}
                    className="w-24 mr-5"
                />
            )}
            <input
                type="text"
                className="w-48 h-10"
                defaultValue={statusInfo?.title}
                disabled={!statusInfo?.node && !nodeStatusInfo.node}
                onChange={e => {
                    setNodeStatusInfo({ ...nodeStatusInfo, title: e.target.value });
                    setNodeStates(() => {
                        if (nodeStates.find(el => el.name === node.name)) {
                            return nodeStates.map(el => (el.name === node.name ? { ...el, title: e.target.value } : el));
                        } else {
                            return [...nodeStates, { title: e.target.value, name: node.name, pic: node.pic, operation: '=' }];
                        }
                    });
                }}
            />
        </>
    );
};
