import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { toastOptions } from '../../api/functions';
import { bmsAPI } from '../../api/modules/bmsAPI';
import { store } from '../store';
import { update_user_roles } from './loginSlice';

export const bmsSlice = createSlice({
    name: 'bms',

    initialState: {
        bms_doc_list: [],
        bms_doc_list_fetching: false,
        document: {
            id: null,
            nodes: null,
            controls: null,
        },
        bms_detail_nodes_fetching: false,
        bms_detail_history: [],
        bms_detail_history_fetching: false,
        bms_detail_alerts: [],
        bms_detail_alerts_fetching: false,
        nodes_list: [],
        nodes_fetching: false,
        lib: [],
        lib_fetching: false,
        create_bms_fetching: false,
        bms_venues_list: [],
        venues_fetching: false,
        bms_servers_list: [],
        bms_servers_fetching: false,
        bms_system_list: [],
        bms_systems_fetching: false,
        bms_server_error: false,
        bms_error_servers_arr: [],
        bms_servers_state: [],
        user_info_fetching: true,
    },

    reducers: {
        set_bms_doc_list: (state, action) => {
            state.bms_doc_list = action.payload;
        },
        update_bms_doc_list: (state, action) => {
            const doc = state.bms_doc_list.find(doc => doc.id === action.payload.id);

            if (doc) {
                state.bms_doc_list = state.bms_doc_list.map(doc => {
                    if (doc.id === action.payload.id) {
                        return { ...doc, ...action.payload };
                    }

                    return doc;
                });
            } else {
                state.bms_doc_list = [action.payload, ...state.bms_doc_list];
            }
        },
        remove_bms_doc: (state, action) => {
            state.bms_doc_list = state.bms_doc_list.filter(doc => doc.id !== action.payload);
        },
        set_document: (state, action) => {
            state.document = { ...state.document, ...action.payload };
        },
        remove_document: state => {
            state.document = bmsSlice.getInitialState().document;
        },
        update_bms_document_node: (state, action) => {
            state.document = { ...state.document, nodes: state.document.nodes.map(node => (node.cn_id !== action.payload.cn_id ? node : action.payload)) };
        },
        add_bms_document_node: (state, action) => {
            state.document = { ...state.document, nodes: [...state.document.nodes, action.payload] };
        },
        remove_bms_document_node: (state, action) => {
            state.document = { ...state.document, nodes: state.document.nodes.filter(node => node.cn_id !== action.payload) };
        },
        set_bms_doc_list_fetching: (state, action) => {
            state.bms_doc_list_fetching = action.payload;
        },
        clear_bms_doc_detail: state => {
            state.bms_detail_history = [];
            state.bms_detail_alerts = [];
        },
        set_bms_detail_nodes_fetching: (state, action) => {
            state.bms_detail_nodes_fetching = action.payload;
        },
        set_bms_detail_history: (state, action) => {
            state.bms_detail_history = action.payload;
        },
        set_bms_detail_history_fetching: (state, action) => {
            state.bms_detail_history_fetching = action.payload;
        },
        set_bms_detail_alerts: (state, action) => {
            state.bms_detail_alerts = action.payload;
        },
        set_bms_detail_alerts_fetching: (state, action) => {
            state.bms_detail_alerts_fetching = action.payload;
        },
        set_venues_fetching: (state, action) => {
            state.venues_fetching = action.payload;
        },
        set_nodes_list: (state, action) => {
            state.nodes_list = action.payload;
        },
        set_nodes_fetching: (state, action) => {
            state.nodes_fetching = action.payload;
        },
        set_lib: (state, action) => {
            state.lib = action.payload;
        },
        set_lib_fetching: (state, action) => {
            state.lib_fetching = action.payload;
        },
        set_create_bms_fetching: (state, action) => {
            state.create_bms_fetching = action.payload;
        },
        set_bms_venues_list: (state, action) => {
            state.bms_venues_list = action.payload;
        },
        add_bms_venue: (state, action) => {
            state.bms_venues_list = [...state.bms_venues_list, action.payload];
        },
        remove_bms_venue: (state, action) => {
            state.bms_venues_list = state.bms_venues_list.filter(venue => venue.id !== action.payload);
        },
        edit_bms_venue: (state, action) => {
            state.bms_venues_list = state.bms_venues_list.map(venue => (venue.id === action.payload.id ? action.payload : venue));
        },
        add_bms_venueName_in_server: (state, action) => {
            state.bms_servers_list = state.bms_servers_list.map(server =>
                action.payload.servers.indexOf(server.id) >= 0
                    ? { ...server, objects: `${server?.objects}${server.objects ? ', ' : ''}${action.payload.title}` }
                    : server
            );
        },
        remove_bms_venue_from_server: (state, action) => {
            state.bms_servers_list = state.bms_servers_list.map(server =>
                server.objects.includes(action.payload)
                    ? { ...server, objects: server.objects.replace(new RegExp('(,\\s*)?' + action.payload + '(,)?', 'g'), '') }
                    : server
            );
        },
        set_bms_servers_list: (state, action) => {
            state.bms_servers_list = action.payload;
        },
        add_bms_server: (state, action) => {
            state.bms_servers_list = [...state.bms_servers_list, action.payload];
        },
        edit_bms_server: (state, action) => {
            state.bms_servers_list = state.bms_servers_list.map(server => (server.id === action.payload.id ? action.payload : server));
        },
        remove_bms_server: (state, action) => {
            state.bms_servers_list = state.bms_servers_list.filter(server => server.id !== action.payload);
        },
        set_servers_fetching: (state, action) => {
            state.bms_servers_fetching = action.payload;
        },
        set_bms_system_list: (state, action) => {
            state.bms_system_list = action.payload;
        },
        set_bms_system_IN_venue: (state, action) => {
            state.bms_system_list = state.bms_system_list.map(system => (system.id === action.payload ? { ...system, state: 1 } : system));
        },
        set_bms_systems_fetching: (state, action) => {
            state.bms_systems_fetching = action.payload;
        },
        remove_bms_system_IN_venue: (state, action) => {
            state.bms_system_list = state.bms_system_list.map(system => (system.id === action.payload ? { ...system, state: 0 } : system));
        },
        clear_system_list: (state, _) => {
            state.bms_system_list = [];
        },
        set_bms_server_error: (state, action) => {
            state.bms_server_error = action.payload;
        },
        set_bms_error_servers_arr: (state, action) => {
            state.bms_error_servers_arr = action.payload;
        },
        set_bms_servers_state: (state, action) => {
            state.bms_servers_state = action.payload;
        },
        set_user_info_fetching: (state, action) => {
            state.user_info_fetching = action.payload;
        },
        set_control_value: (state, action) => {
            state.document = {
                ...state.document,
                controls: state.document.controls.map(control =>
                    control.cn_id !== action.payload.cn_id ? control : { ...control, value: action.payload.value }
                ),
            };
        },
    },
});

export const {
    remove_bms_document_node,
    add_bms_document_node,
    update_bms_document_node,
    set_bms_servers_state,
    set_bms_error_servers_arr,
    set_bms_server_error,
    remove_bms_doc,
    clear_system_list,
    remove_bms_system_IN_venue,
    set_bms_systems_fetching,
    set_bms_system_IN_venue,
    remove_bms_venue_from_server,
    add_bms_venueName_in_server,
    remove_bms_server,
    add_bms_server,
    set_servers_fetching,
    edit_bms_venue,
    remove_bms_venue,
    add_bms_venue,
    set_bms_system_list,
    edit_bms_server,
    set_bms_servers_list,
    set_bms_venues_list,
    set_bms_doc_list,
    set_bms_doc_list_fetching,
    update_bms_doc_list,
    clear_bms_doc_detail,
    set_bms_detail_nodes_fetching,
    set_bms_detail_history,
    set_bms_detail_history_fetching,
    set_bms_detail_alerts,
    set_bms_detail_alerts_fetching,
    set_venues_fetching,
    set_nodes_list,
    set_nodes_fetching,
    set_lib,
    set_lib_fetching,
    set_create_bms_fetching,
    set_document,
    remove_document,
    set_user_info_fetching,
    set_control_value,
} = bmsSlice.actions;

// Получение списка ролей
export const getUserInfo = () => async dispatch => {
    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;

    try {
        const response = await bmsAPI.getUserInfo.fetch(username, auth_key_calendar);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                dispatch(
                    update_user_roles({
                        bms: {
                            role: res.data.role,
                            actions: res.data.can,
                        },
                    })
                );
            } else {
                toast.error(res.msg, toastOptions);
            }
        }
    } catch (e) {
        toast.error(`Method getUserInfo \n ${e}`, toastOptions);
    } finally {
        dispatch(set_user_info_fetching(false));
    }
};

// Получение списка документов
export const getBMSDocList =
    ({ object_id, direction_id }) =>
    async dispatch => {
        dispatch(clear_bms_doc_detail());
        dispatch(set_bms_doc_list_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const lang = store.getState().login.lang;

        try {
            const response = await bmsAPI.getBMSDocList.fetch(username, auth_key_calendar, lang, object_id, direction_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_bms_doc_list(res.data));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSDocList \n ${e}`, toastOptions);
        } finally {
            dispatch(set_bms_doc_list_fetching(false));
        }
    };

// Получение списка элементов нод, размещенных в рамках документа
export const getBMSDocNodes =
    ({ data }) =>
    async dispatch => {
        dispatch(set_bms_detail_nodes_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.getBMSDocNodes.fetch(username, auth_key_calendar, { doc_id: data.doc_id });

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    const dataElements = res.data.filter(el => !el.is_control);
                    const dataControls = res.data.filter(el => el.is_control);

                    dispatch(
                        set_document({
                            nodes: dataElements.map(node => ({
                                ...node,
                                lib_data: JSON.parse(node.lib_data),
                                data: JSON.parse(node.data),
                                position: JSON.parse(node.position),
                                title: node.name,
                            })),
                            controls: dataControls.map(control => ({
                                ...control,
                                lib_data: JSON.parse(control.lib_data),
                                data: JSON.parse(control.data),
                            })),
                        })
                    );
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSDocNodes \n ${e}`, toastOptions);
        } finally {
            dispatch(set_bms_detail_nodes_fetching(false));
        }
    };

export const getBMSDocNodesTimeout =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        try {
            const response = await bmsAPI.getBMSDocNodes.fetch(username, auth_key_calendar, { doc_id: data.doc_id });
            if (response.status === 200) {
                const result = await response.json();
                if (result.hasOwnProperty('data')) {
                    const dataElements = result.data.filter(el => !el.is_control);
                    const dataControls = result.data.filter(el => el.is_control);
                    dispatch(
                        set_document({
                            nodes: dataElements.map(node => ({
                                ...node,
                                lib_data: JSON.parse(node.lib_data),
                                data: JSON.parse(node.data),
                                position: JSON.parse(node.position),
                                title: node.name,
                            })),
                            controls: dataControls.map(control => ({
                                ...control,
                                lib_data: JSON.parse(control.lib_data),
                                data: JSON.parse(control.data),
                            })),
                        })
                    );
                }
            }
        } catch (e) {
            toast.error(`Method getBMSDocNodes \n ${e}`, toastOptions);
        }
    };

// Получение трендов (исторических данных)
export const getBMSDocHistory =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.getBMSHistory.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_bms_detail_history(res.data));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSDocHistory \n ${e}`, toastOptions);
        }
    };

// Получение системных сообщений об ошибках
export const getBMSDocAlerts =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.getBMSAlerts.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_bms_detail_alerts(res.data));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSDocAlerts \n ${e}`, toastOptions);
        }
    };

// Получение списка нод, которые могут быть использованы при размещении в документе
export const getBMSNodesList =
    ({ docId }) =>
    async dispatch => {
        dispatch(set_nodes_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.getBMSNodes.fetch(username, auth_key_calendar, docId);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_nodes_list(res.data.map(el => ({ ...el, lib_data: JSON.parse(el.lib_data) }))));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSNodesList \n ${e}`, toastOptions);
        } finally {
            dispatch(set_nodes_fetching(false));
        }
    };

// Получение списка элементов библиотеки
export const getBMSLib = () => async dispatch => {
    dispatch(set_lib_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;

    try {
        const response = await bmsAPI.getBMSLib.fetch(username, auth_key_calendar);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                const data = res.data.direction.map(direction => ({
                    title: direction.title,
                    types: [
                        {
                            title: 'Оборудование',
                            groups: direction.groups
                                .filter(group => group.title !== 'Элементы управления')
                                .map(group => ({
                                    title: group.title,
                                    items: group.items.map(item => ({
                                        ...item,
                                        lib_data: JSON.parse(item.data),
                                        name: item.title,
                                    })),
                                })),
                        },
                        {
                            title: 'Элементы управления',
                            groups: direction.groups
                                .filter(group => group.title === 'Элементы управления')
                                .map(group => ({
                                    title: group.title,
                                    items: group.items.map(item => ({
                                        ...item,
                                        lib_data: JSON.parse(item.data),
                                        name: item.title,
                                    })),
                                })),
                        },
                    ],
                }));

                dispatch(set_lib(data));
            } else {
                toast.error(res.msg, toastOptions);
            }
        }
    } catch (e) {
        toast.error(`Method getBMSLib \n ${e}`, toastOptions);
    } finally {
        dispatch(set_lib_fetching(false));
    }
};

// Создание документа
export const createBMSDoc =
    ({ data }) =>
    async () => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.createBMSDoc.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    return {
                        id: res.data.id,
                        error: false,
                        msg: '',
                    };
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method createBMSDoc \n ${e}`, toastOptions);
        }

        return {
            error: true,
            msg: 'Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.',
        };
    };

// Обновление документа
export const updateBMSDoc =
    ({ data }) =>
    async () => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.updateBMSDoc.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    return {
                        id: res.data.id,
                        error: false,
                        msg: '',
                    };
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method updateBMSDoc \n ${e}`, toastOptions);
        }

        return {
            error: true,
            msg: 'Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.',
        };
    };

// Добавление ноды (оборудования) к документу
export const addBMSDocNode =
    ({ nodeObj }) =>
    async dispatch => {
        dispatch(set_create_bms_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.addBMSDocNode.fetch(username, auth_key_calendar, nodeObj);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    return {
                        cn_id: res.data.cn_id,
                        width: res.data.size.width,
                        height: res.data.size.height,
                    };
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method addBMSDocNode \n ${e}`, toastOptions);
        } finally {
            dispatch(set_create_bms_fetching(false));
        }

        return {
            error: true,
            msg: 'Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.',
        };
    };

// Обновление данных ноды
export const updateNodeData =
    ({ nodesArr }) =>
    async dispatch => {
        dispatch(set_create_bms_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            for (let i = 0; i < nodesArr.length; i++) {
                const response = await bmsAPI.updateBMSDocNodeData.fetch(username, auth_key_calendar, nodesArr[i]);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 0) {
                        const data = {
                            ...nodesArr[i],
                            data: JSON.parse(nodesArr[i].data),
                            position: JSON.parse(nodesArr[i].position),
                        };

                        dispatch(update_bms_document_node(data));
                    } else {
                        toast.error(res.msg, toastOptions);
                    }
                }
            }
            return { error: false, msg: '' };
        } catch (e) {
            toast.error(`Method updateNodeData \n ${e}`, toastOptions);
        } finally {
            dispatch(set_create_bms_fetching(false));
        }

        return {
            error: true,
            msg: 'Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.',
        };
    };

// Удаление ноды из документа
export const removeBMSDocNode =
    ({ cn_id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.removeBMSDocNode.fetch(username, auth_key_calendar, cn_id);

            if (response.status === 200) {
                const res = await response.json();
                dispatch(remove_bms_document_node(cn_id));

                if (res.code !== 0) {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method removeBMSDocNode \n ${e}`, toastOptions);
        }
    };

// Получение списка объектов
export const getBMSUserVenuesList = () => async dispatch => {
    dispatch(set_venues_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;

    try {
        const response = await bmsAPI.getBMSUserVenues.fetch(username, auth_key_calendar, lang);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                dispatch(set_bms_venues_list(res.data));
            } else {
                toast.error(res.msg, toastOptions);
            }
        }
    } catch (e) {
        toast.error(`Method getBMSUserVenuesList \n ${e}`, toastOptions);
    } finally {
        dispatch(set_venues_fetching(false));
    }
};

// Создание объекта
export const addBMSVenue =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const lang = store.getState().login.lang;

        try {
            const newData = { ...data, servers: data.servers.map(server => server.value) };
            const response = await bmsAPI.addBMSVenue.fetch(username, auth_key_calendar, newData, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(add_bms_venue({ ...data, id: res.data.id }));

                    const newData = { title: data.title, servers: data.servers.map(el => el.value) };
                    dispatch(add_bms_venueName_in_server(newData));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method addBMSVenue \n ${e}`, toastOptions);
        }
    };

// Редактирование объекта
export const editBMSVenue =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.editBMSVenue.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(edit_bms_venue(data));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method editBMSVenue \n ${e}`, toastOptions);
        }
    };

// Удаление объекта
export const removeBMSVenue =
    ({ id, title }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.removeBMSVenue.fetch(username, auth_key_calendar, id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(remove_bms_venue(id));
                    dispatch(remove_bms_venue_from_server(title));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method addBMSVenue \n ${e}`, toastOptions);
        }
    };

// Получение списка серверов
export const getBMSServersList = () => async dispatch => {
    dispatch(set_servers_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;

    try {
        const response = await bmsAPI.getBMSServers.fetch(username, auth_key_calendar, lang);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                const data = res.data.map(server => {
                    return {
                        state_error: server.state_error,
                        title: server.title,
                        objects: server.objects || '',
                        id: server.id,
                        cur_state: server.cur_state,
                        is_enabled: server.is_enabled,
                        data: JSON.parse(server.data),
                    };
                });

                dispatch(set_bms_servers_list(data));
            } else {
                toast.error(res.msg, toastOptions);
            }
        }
    } catch (e) {
        toast.error(`Method getBMSServersList \n ${e}`, toastOptions);
    } finally {
        dispatch(set_servers_fetching(false));
    }
};

// Создание сервера
export const addBMSServer =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.addBMSServer.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(add_bms_server({ ...data, id: res.data.id, cur_state: data.is_enabled ? 2 : 1 }));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method addBMSServer \n ${e}`, toastOptions);
        }
    };

// Редактирование сервера
export const editBMSServer =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.editBMSServer.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(edit_bms_server({ ...data, cur_state: data.is_enabled ? 2 : 1 }));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method editBMSServer \n ${e}`, toastOptions);
        }
    };

// Удаление сервера
export const removeBMSServer =
    ({ id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.removeBMSServer.fetch(username, auth_key_calendar, id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(remove_bms_server(id));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method removeBMSServer \n ${e}`, toastOptions);
        }
    };

// Получение списка систем объекта
export const getBMSSystemList =
    ({ venueId }) =>
    async dispatch => {
        dispatch(set_bms_systems_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const lang = store.getState().login.lang;

        try {
            const response = await bmsAPI.getBMSSystemOfVenue.fetch(username, auth_key_calendar, venueId, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_bms_system_list(res.data));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSSystemList \n ${e}`, toastOptions);
        } finally {
            dispatch(set_bms_systems_fetching(false));
        }
    };

// Добавление системы в объект
export const addBMSSystemInVenue =
    ({ object_id, direction_id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.addBMSSystemInVenue.fetch(username, auth_key_calendar, object_id, direction_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_bms_system_IN_venue(direction_id));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method addBMSSystemInVenue \n ${e}`, toastOptions);
        }
    };

// Удаление системы из объекта
export const removeBMSSystemInVenue =
    ({ object_id, direction_id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.removeBMSSystemInVenue.fetch(username, auth_key_calendar, object_id, direction_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(remove_bms_system_IN_venue(direction_id));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method removeBMSSystemInVenue \n ${e}`, toastOptions);
        }
    };

// Удаление документа из системы
export const removeBMSDoc =
    ({ id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.removeBMSDoc.fetch(username, auth_key_calendar, id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(remove_bms_doc(id));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method removeBMSDoc \n ${e}`, toastOptions);
        }
    };

// Статистика работы серверов
export const getBMSServersStats = () => async dispatch => {
    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;

    try {
        const response = await bmsAPI.getBMSServersStats.fetch(username, auth_key_calendar);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                if (res.data.length) {
                    dispatch(set_bms_servers_state(res.data));
                }

                const servers_error = res.data.filter(server => server.state_error);

                if (servers_error.length > 0) {
                    dispatch(set_bms_server_error(true));
                    dispatch(set_bms_error_servers_arr(servers_error));

                    return servers_error;
                } else {
                    dispatch(set_bms_server_error(false));
                    dispatch(set_bms_error_servers_arr([]));
                }
            } else {
                toast.error(res.msg, toastOptions);
            }
        }
    } catch (e) {
        toast.error(`Method getBMSServersStats \n ${e}`, toastOptions);
    }
};

// Передача значений контроллеров
export const setBMSControlValues =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.setBMSControlValue.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    if (data.flag === 'list') {
                        const value = { ...data, value: JSON.parse(data.value).value };
                        dispatch(set_control_value(value));
                    }
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method setBMSControlValues \n ${e}`, toastOptions);
        }
    };

export const bms_doc_list = state => state.bms.bms_doc_list;
export const bms_doc_list_fetching = state => state.bms.bms_doc_list_fetching;
export const bms_detail_nodes_fetching = state => state.bms.bms_detail_nodes_fetching;
export const bms_detail_history = state => state.bms.bms_detail_history;
export const bms_detail_history_fetching = state => state.bms.bms_detail_history_fetching;
export const bms_detail_alerts = state => state.bms.bms_detail_alerts;
export const bms_detail_alerts_fetching = state => state.bms.bms_detail_alerts_fetching;
export const venues_fetching = state => state.bms.venues_fetching;
export const nodes_list = state => state.bms.nodes_list;
export const nodes_fetching = state => state.bms.nodes_fetching;
export const lib = state => state.bms.lib;
export const lib_fetching = state => state.bms.lib_fetching;
export const create_bms_fetching = state => state.bms.create_bms_fetching;
export const bms_venues_list = state => state.bms.bms_venues_list;
export const bms_servers_list = state => state.bms.bms_servers_list;
export const servers_fetching = state => state.bms.bms_servers_fetching;
export const bms_systems_fetching = state => state.bms.bms_systems_fetching;
export const active_system = state => state.bms.bms_system_list.filter(system => system.state === 1);
export const not_active_system = state => state.bms.bms_system_list.filter(system => system.state === 0);
export const server_error = state => state.bms.bms_server_error;
export const bms_error_servers_arr = state => state.bms.bms_error_servers_arr;
export const bms_servers_state = state => state.bms.bms_servers_state;
export const documentState = state => state.bms.document;
export const userInfoFetchingState = state => state.bms.user_info_fetching;

export default bmsSlice.reducer;
