import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import SlidingPane from 'react-sliding-pane';
import Select from 'react-select';
import { t } from 'i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { PicsLoader } from '../admin_scheduler/detail/PicsLoader';
import { Fancybox } from '../common/Fancybox';

import { url } from '../../api/config';
import {
    set_detail_open,
    detail_is_open,
    qualifictionsPeriodList as qualifictionsPeriodList_state,
    qualificationError as qualificationError_state,
    addQualificationTypeFetch,
    updateQualificationTypeFetch,
    set_error_qualification,
} from '../../redux/slices/qualificationSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { Loader } from '../common/Loader';

const QualificationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .min(3, 'Поле "Полное название" слишком короткое')
        .max(255, 'Поле "Полное название" слишком длинное')
        .required('Поле "Полное название" обязательное'),
    abbreviation: Yup.string()
        .trim()
        .min(1, 'Поле "Сокращенное название" слишком короткое')
        .max(30, 'Поле "Сокращенное название" слишком длинное')
        .required('Поле "Сокращенное название" обязательное'),
    period_id: Yup.string().required('Поле "Срок действия квалификации" обязательное'),
    change_certificate: Yup.string().required('Поле "Замены удостоверения" обязательное'),
});

export const Detail = ({ qualification, setQualification }) => {
    const [files, setFiles] = useState([]);

    const dispatch = useDispatch();
    const isOpen = useSelector(detail_is_open);
    const qualifictionsPeriodList = useSelector(qualifictionsPeriodList_state);
    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const qualificationError = useSelector(qualificationError_state);

    useEffect(() => {
        if (qualificationError === 62) {
            toast.error('Квалификация с таким названием уже существует', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });

            dispatch(set_error_qualification(null));
        }
        if (qualificationError === 84) {
            toast.error('Максимальное количество прикрепляемых файлов не должно превышать 20 штук', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });

            dispatch(set_error_qualification(null));
        }
    }, [dispatch, qualificationError]);

    const closeHandler = () => {
        setQualification(null);
        dispatch(set_detail_open(false));
        setFiles([]);
    };

    const initValues = qualification
        ? {
              name: qualification.name,
              abbreviation: qualification.abbreviation,
              period_id: qualification.period_id,
              change_certificate: qualification.change_certificate === true ? 1 : 0,
              files: qualification.files ? qualification.files : '',
          }
        : { name: '', abbreviation: '', period_id: '', change_certificate: '', files: '' };

    const period_options = qualifictionsPeriodList;

    const change_options = [
        { value: 1, label: t('YES') },
        { value: 0, label: t('NO') },
    ];

    const formSubmit = async (values, { setSubmitting, setFieldError }) => {
        const data = qualification
            ? {
                  ...values,
                  change_certificate: values.change_certificate === 1 ? true : false,
                  files,
                  id: qualification.id,
              }
            : {
                  ...values,
                  change_certificate: values.change_certificate === 1 ? true : false,
                  files: files,
              };

        const status = qualification
            ? await dispatch(updateQualificationTypeFetch({ username, auth_key_calendar, data }))
            : await dispatch(addQualificationTypeFetch({ username, auth_key_calendar, data }));
        if (status === 62) {
            setFieldError('name', 'qualName');
        } else if (status === 84) {
            return;
        } else {
            setSubmitting(false);
            setQualification(null);
            setFiles([]);
            dispatch(set_detail_open(false));
        }
    };

    const getImgFiles = () => {
        let imgFiles = [];
        let otherFiles = [];

        if (!qualification?.files?.length > 0) {
            return '';
        } else {
            qualification.files.map(file => {
                if (file instanceof File) {
                    if (file.type.includes('image')) {
                        imgFiles.push(file.preview);
                    } else {
                        otherFiles.push({ file: file.preview, name: file.path });
                    }
                } else {
                    if (file.type === 'image') {
                        imgFiles.push(file.file_path);
                    } else {
                        otherFiles.push({ file: file.file_path, name: file.file_name });
                    }
                }
            });
        }

        return {
            images: imgFiles,
            otherFiles,
        };
    };
    const galleryFiles = getImgFiles();
    return (
        <SlidingPane
            isOpen={isOpen}
            hideHeader={true}
            onRequestClose={closeHandler}
            overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100"
            width="28rem"
        >
            <div className="border-b-2 ml-[-15px] mr-[-15px]">
                <div className="flex items-start justify-between w-full sticky -top-6 pl-4 bg-white z-20 mb-6">
                    <div className="font-bold text-black text-xl">{qualification ? t('QUALIFICATION_EDIT') : t('QUALIFICATION_NEW_CREATE')}</div>
                    <button className="w-9 h-9 p-0 mr-6 rounded-full bg-transparent hover:bg-gray-100 flex items-center justify-center" onClick={closeHandler}>
                        <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                        </svg>
                    </button>
                </div>
            </div>
            <Formik initialValues={initValues} onSubmit={formSubmit} validationSchema={QualificationSchema}>
                {({ values, errors, isSubmitting, isValidating, setFieldValue }) => {
                    if (isSubmitting && !isValidating) {
                        for (const err in errors) {
                            toast.error(errors[err].toString(), {
                                position: 'bottom-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: 'colored',
                            });
                        }
                    }
                    return (
                        <Form className="flex flex-col">
                            {/* qualification name */}
                            <div className="mb-4 last:mb-0">
                                <div className="text-sm mb-2 text-gray-20 w-full mt-4">
                                    {t('QUALIFICATION_FULL_NAME')}
                                    {errors.name === 'qualName' ? <div className="text-red-500 font-semibold ml-auto">{t('QUALIFICATION_SAME')}</div> : ''}
                                </div>
                                <Field
                                    name="name"
                                    component="input"
                                    value={values.name}
                                    placeholder={t('QUALIFICATION_FULL_NAME_PLH')}
                                    className="box-border w-full h-10 text-sm border resize-none rounded-1 border-gray-10 p-3"
                                    disabled={isSubmitting}
                                />
                            </div>

                            {/*short qualification name */}
                            <div className="mb-4 last:mb-0">
                                <p className="text-sm mb-2 text-gray-20 flex w-full mt-4">{t('QUALIFICATION_SHORT_NAME')}</p>
                                <Field
                                    name="abbreviation"
                                    component="input"
                                    value={values.abbreviation}
                                    placeholder={t('QUALIFICATION_SHORT_NAME_PLH')}
                                    className="box-border w-full h-10 text-sm border resize-none rounded-1 border-gray-10 p-3"
                                    disabled={isSubmitting}
                                />
                            </div>

                            {/* period */}
                            <div className="mb-4 last:mb-0">
                                <p className="text-sm mb-2 text-gray-20 flex w-full">{t('QUALIFICATION_PERIOD')}</p>
                                <Select
                                    name="period_id"
                                    options={period_options}
                                    className="select text-sm w-full"
                                    isClearable={false}
                                    isSearchable={false}
                                    placeholder={t('QUALIFICATION_PERIOD_PLH')}
                                    classNamePrefix="filter"
                                    defaultValue={period_options.find(item => item.value === values.period_id)}
                                    onChange={item => setFieldValue('period_id', item.value)}
                                />
                            </div>

                            {/* change certificate*/}
                            <div className="mb-4 last:mb-0">
                                <p className="text-sm mb-2 text-gray-20 flex w-full">{t('QUALIFICATION_CHANGE')}</p>
                                <Select
                                    name="change_certificate"
                                    options={change_options}
                                    className="select text-sm w-full"
                                    isClearable={false}
                                    isSearchable={false}
                                    placeholder={t('QUALIFICATION_CHANGE_PLH')}
                                    classNamePrefix="filter"
                                    defaultValue={change_options.find(item => item.value === values.change_certificate)}
                                    onChange={item => setFieldValue('change_certificate', item.value)}
                                />
                            </div>

                            {qualification?.files?.length > 0 && (
                                <div className="mb-3">
                                    <span>{t('QUALIFICATION_ATTACHED_FILES')}:</span>
                                    <div className="flex flex-wrap flex-row mb-3 gap-2 ">
                                        <Fancybox options={{ infinite: false }}>
                                            {galleryFiles.images.map((image, idx) => (
                                                <div
                                                    className="w-24 h-24 border border-neutral-300 rounded-sm p-1 box-border cursor-pointer	transition flex flex-col items-center justify-center hover:border-neutral-500"
                                                    data-fancybox="gallery"
                                                    data-src={`${image.includes('blob') ? image : `${url}/${image}`}`}
                                                    key={`image${idx}`}
                                                >
                                                    <img
                                                        src={`${image.includes('blob') ? image : `${url}/${image}`}`}
                                                        alt=""
                                                        className="w-full h-full object-cover"
                                                    />
                                                </div>
                                            ))}
                                        </Fancybox>
                                        {galleryFiles.otherFiles.map((file, idx) => (
                                            <a
                                                href={`${file.file.includes('blob') ? file.file : `${url}/${file.file}`}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                className=" w-24 h-24 border border-neutral-300 rounded-sm p-1 box-border cursor-pointer transition flex flex-col items-center justify-center hover:border-neutral-500"
                                                key={`file${idx}`}
                                            >
                                                <svg width="40" height="40" fill="#757575" viewBox="0 0 512 512">
                                                    <path d="M459.36,100.64l-96-96C360.341,1.645,356.253-0.024,352,0H96c-26.51,0-48,21.49-48,48v416c0,26.51,21.49,48,48,48h320     c26.51,0,48-21.49,48-48V112C464.025,107.747,462.355,103.66,459.36,100.64z M432,464c0,8.837-7.163,16-16,16H96     c-8.837,0-16-7.163-16-16V48c0-8.837,7.163-16,16-16h240v64c0,17.673,14.327,32,32,32h64V464z" />
                                                    <rect x="368" y="384" width="32" height="32" />
                                                    <rect x="112" y="224" width="288" height="32" />
                                                    <rect x="112" y="304" width="288" height="32" />
                                                    <rect x="112" y="384" width="224" height="32" />
                                                </svg>
                                                <span className="text-xs w-20 mt-2 truncate text-center">{file.name}</span>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* picloader */}
                            <div className="mt-4 last:mb-0 w-full  ">
                                <p className="text-sm mb-2 text-gray-20 flex w-full">{t('QUALIFICATION_FILES')}</p>
                                <PicsLoader disabled={isSubmitting} files={files} setFiles={setFiles} />
                            </div>

                            <div className="flex items-center gap-3 w-full pt-4 mt-8 ">
                                <button
                                    type="submit"
                                    className=" w-28 bg-blue hover:bg-blue-500 transition-colors duration-200 text-white rounded-lg font-medium text-sm px-4 h-10"
                                    disabled={isSubmitting}
                                >
                                    {qualification ? (
                                        <span className="text-center relative">
                                            {isSubmitting ? (
                                                <span className="absolute left-[-10px] bottom-[-10px] ">
                                                    <Loader height={20} width={20} color="#ffffff" />
                                                </span>
                                            ) : (
                                                t('ADM_PLANNER_BTN_SAVE')
                                            )}
                                        </span>
                                    ) : (
                                        <span className="text-center relative">
                                            {isSubmitting ? (
                                                <span className="absolute left-[-10px] bottom-[-10px] ">
                                                    <Loader height={20} width={20} color="#ffffff" />
                                                </span>
                                            ) : (
                                                t('APPLICATIONS_CREATE')
                                            )}
                                        </span>
                                    )}
                                </button>

                                <button
                                    type="button"
                                    className="bg-white hover:bg-gray-100 transition-colors duration-200 text-gray-20 rounded-lg font-medium text-sm px-6 h-10"
                                    disabled={isSubmitting}
                                    onClick={closeHandler}
                                >
                                    {t('ADM_PLANNER_BTN_CANCEL')}
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </SlidingPane>
    );
};
