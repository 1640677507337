import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { documentState } from '../../../../redux/slices/bmsSlice';
import { useCheckRole } from '../../../../hooks/useCheckRole';

import { CanvasSection } from './canvas/CanvasSection';
import { SubmitButton } from './button/SubmitButton';
import { LibrarySection } from './library/LibrarySection';
import { CreateNodePopup } from './nodePopup/CreateNodePopup';
import { ControlsSection } from './controls/ControlsSection';
import { CreateControlPopup } from './controlPopup/CreateControlPopup';
import { DocumentInformationForm } from './documentInformationForm/DocumentInformationForm';

export const DocumentEdit = () => {
    const { venueId, systemId } = useParams();
    const navigate = useNavigate();

    const document = useSelector(documentState);

    const infoFormRef = useRef(null);

    const [nodes, setNodes] = useState(document.nodes);
    const [controls, setControls] = useState(document.controls);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [createPopupIsOpen, setCreatePopupIsOpen] = useState(false);
    const [selectedElement, setSelectedElement] = useState(null);

    const isDocumentNodeAdd = useCheckRole('bms', 'bms.document_nodes.add');

    useEffect(() => {
        if (!document.id) {
            return navigate(`/bms/venues/${venueId}/system/${systemId}/documents/`);
        }
        setNodes(document.nodes);
    }, [document]);

    const handlerNodeClick = node => {
        if (node.lib_data && node.lib_data.hasOwnProperty('type')) {
            setCreatePopupIsOpen(true);
        } else {
            setPopupIsOpen(true);
        }
        setSelectedElement(node);
    };

    if (!document.id) {
        return null;
    }

    return (
        <div>
            <p className="mb-6 uppercase text-xl text-black font-semibold">Информация о документе</p>
            <DocumentInformationForm
                info={{
                    title: document.title,
                    description: document.description,
                }}
                formRef={infoFormRef}
            />

            <p className="mb-6 uppercase text-xl text-black font-semibold">Расстановка оборудования</p>
            <div className="w-full h-full overflow-x-auto mb-6">
                <ControlsSection controls={controls} docId={document.id} />
                <CanvasSection nodes={nodes} editNodeHandler={handlerNodeClick} />
            </div>

            <SubmitButton nodes={nodes} infoFormRef={infoFormRef} docId={document.id} />

            {isDocumentNodeAdd && <LibrarySection itemLibraryHandler={handlerNodeClick} />}

            {popupIsOpen && <CreateNodePopup setIsOpen={setPopupIsOpen} node={selectedElement} docId={document.id} />}

            {createPopupIsOpen && (
                <CreateControlPopup setIsOpen={setCreatePopupIsOpen} control={selectedElement} docId={document.id} setControls={setControls} />
            )}
        </div>
    );
};
