import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { detail_is_open as detail_is_open_state, set_detail_open, set_task, task as task_state, taskEdit } from '../../redux/slices/calendarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { t } from 'i18next';
import { useCookies } from 'react-cookie';

export const Detail = () => {
    const ref = useRef(null);
    const detail_is_open = useSelector(detail_is_open_state);
    const task = useSelector(task_state);
    const dispatch = useDispatch();
    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const [cookies, setCookie] = useCookies();

    const lang = cookies.i18next;

    //temporary, without formik
    const [active, setActive] = useState(null);
    useEffect(() => {
        if (task.id) {
            setActive(task.active);
        } else {
            setActive(null);
        }
    }, [task]);

    const closeHandler = () => {
        dispatch(set_detail_open(false));
        dispatch(set_task({ id: null }));
    };

    useEffect(() => {
        if (ref.current && task.id) {
            if (ref.current.offsetWidth + task.target.right > window.innerWidth) {
                ref.current.style.left = `${task.target.left - ref.current.offsetWidth}px`;
            } else {
                ref.current.style.left = `${task.target.right}px`;
            }

            if (ref.current.offsetHeight / 2 + task.target.top > window.innerHeight) {
                ref.current.style.bottom = `0px`;
                ref.current.style.top = `auto`;
            } else {
                ref.current.style.bottom = `auto`;
                if (task.target.top - ref.current.offsetHeight / 2 > 0) {
                    ref.current.style.top = `${task.target.top - ref.current.offsetHeight / 2}px`;
                } else {
                    ref.current.style.top = `0px`;
                }
            }
        }
    }, [task, ref]);

    const optionsActive = [
        { value: true, label: t('CALENDAR_DETAILS_ACTIVITY_ACTIVE') },
        { value: false, label: t('CALENDAR_DETAILS_ACTIVITY_NOT_ACTIVE') },
    ];

    const editHandler = async mode => {
        const params = {
            detail_id: task.id,
            from_date_work: `${task.start_date} 00:00:00`,
            until_date_work: `${task.start_date} 23:59:59`,
            changed: +task.changed,
            active_work: +active,
            delete_work: mode === 'delete' ? 1 : 0,
        };

        await dispatch(taskEdit({ username, auth_key_calendar, params, lang }));
    };

    if (!task.id) {
        return false;
    }

    return (
        <>
            <div className="fixed inset-0 bg-slate-900 bg-opacity-30 z-40 transition-opacity duration-200 opacity-100" onClick={closeHandler}></div>

            <div
                ref={ref}
                className={`fixed bg-white w-[30rem] z-50 max-w-full rounded-sm p-5 shadow-2xl transition-all duration-200 ease-in-out border border-gray-10 flex flex-col ${
                    !detail_is_open ? 'opacity-0' : ''
                }`}
            >
                <div className="flex flex-row items-center justify-between w-full">
                    <div className="flex flex-row items-center justify-start">
                        {task.week && (
                            <div className="mr-5 pr-5 border-r border-r-gray-10">
                                <span className="text-white bg-blue rounded-full w-8 h-8 inline-flex items-center justify-center">{task.week}</span>{' '}
                                {t('CALENDAR_DETAILS_WEEK')}
                            </div>
                        )}
                        <div>
                            {task.start_date} - {task.end_date}
                        </div>
                    </div>
                    <button className="w-9 h-9 p-0 rounded-full bg-transparent hover:bg-gray-100 flex items-center justify-center" onClick={closeHandler}>
                        <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                        </svg>
                    </button>
                </div>

                <div className="flex flex-col w-full mt-5">
                    <div className="mb-5 last:mb-0">
                        <p className="text-sm mb-1 font-semibold text-gray-20">{t('CALENDAR_DETAILS_LIST_OF_WORKS')}</p>
                        <div className="text-sm max-h-40 overflow-y-auto">
                            {task.operation_list
                                ? task.operation_list.map(operation => <p key={operation.id.toString()}>{operation.name}</p>)
                                : t('CALENDAR_DETAILS_NOT_SET')}
                        </div>
                    </div>
                    <div className="mb-5 last:mb-0">
                        <p className="text-sm mb-1 font-semibold text-gray-20">{t('CALENDAR_DETAILS_EXECUTOR')}</p>
                        <div className="text-sm">{task.user_name}</div>
                    </div>
                    <div className="mb-5 last:mb-0">
                        <p className="text-sm mb-1 font-semibold text-gray-20">{t('CALENDAR_DETAILS_ACTIVITY')}</p>
                        {task.virtual_DEP ? (
                            <Select
                                onChange={v => setActive(v.value)}
                                options={optionsActive || []}
                                defaultValue={optionsActive.find(item => item.value === task.active)}
                                isClearable={false}
                                isSearchable={false}
                                placeholder={t('CALENDAR_DETAILS_ACTIVITY')}
                                classNamePrefix="filter"
                                className="select text-sm w-48"
                            />
                        ) : (
                            <div className="text-sm">{task.active ? t('CALENDAR_DETAILS_ACTIVITY_ACTIVE') : t('CALENDAR_DETAILS_ACTIVITY_NOT_ACTIVE')}</div>
                        )}
                    </div>
                    <div className="mb-5 last:mb-0">
                        <p className="text-sm mb-1 font-semibold text-gray-20">{t('CALENDAR_DETAILS_MAINTENANCE_NUMBER')}</p>
                        <div className="text-sm">
                            {task.period_type_s_name} <span className="text-xs text-gray-30">{task.period_type_l_name}</span>
                        </div>
                    </div>
                    <div className="mb-5 last:mb-0">
                        <p className="text-sm mb-1 font-semibold text-gray-20">{t('CALENDAR_DETAILS_DATA_OF_START')}</p>
                        <div className="text-sm">{task.start_date}</div>
                    </div>
                </div>

                {task.virtual_DEP && (
                    <div className="flex flex-row gap-4 mt-5">
                        <button
                            type="button"
                            className="bg-blue hover:bg-blue-500 transition-colors duration-200 text-white rounded-sm font-medium text-sm px-14 h-10"
                            onClick={() => editHandler('save')}
                        >
                            {t('CALENDAR_BTN_SAVE')}
                        </button>
                        <button
                            type="button"
                            className="bg-white hover:bg-gray-100 transition-colors duration-200 text-red-500 rounded-sm font-medium text-sm px-6 h-10"
                            onClick={() => editHandler('delete')}
                        >
                            {t('CALENDAR_BTN_DEL')}
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};
