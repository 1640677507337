import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useCookies } from 'react-cookie';

import {
    data as data_state,
    dataFetch,
    fetching as fetching_state,
    filter as filter_state,
    set_detail_open,
    task as task_state,
    taskFetch,
} from '../../redux/slices/calendarSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { t } from 'i18next';
import { Loader } from '../common/Loader';

export const ApartmentsYear = () => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const filter = useSelector(filter_state);
    const data = useSelector(data_state);
    const fetching = useSelector(fetching_state);
    const task_detail = useSelector(task_state);
    const [cookies, setCookie] = useCookies();

    const lang = cookies.i18next;

    useEffect(() => {
        if (username && auth_key_calendar && !fetching) {
            dispatch(dataFetch({ username, auth_key_calendar, filter: { ...filter, month: null, task_category_id: 11, calendar_type: 7 }, lang }));
        }
    }, [dispatch, username, auth_key_calendar, filter, lang]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [fetching]);

    const openDetail = async (target, task) => {
        const params = {
            detail_id: task.detail_id,
            date_work: null,
            start_week: task.virtual ? task.start_week : null,
            filter_year: task.virtual ? +task.year : null,
            hash: task.hash,
            target,
        };

        await dispatch(taskFetch({ username, auth_key_calendar, params, lang }));
        await dispatch(set_detail_open(true));
    };

    const month = [
        {
            value: 1,
            name: t('MONTHS_LONG_JAN'),
            short_name: t('MONTHS_SHORT_JAN'),
        },
        {
            value: 2,
            name: t('MONTHS_LONG_FEB'),
            short_name: t('MONTHS_SHORT_FEB'),
        },
        {
            value: 3,
            name: t('MONTHS_LONG_MAR'),
            short_name: t('MONTHS_SHORT_MAR'),
        },
        {
            value: 4,
            name: t('MONTHS_LONG_APR'),
            short_name: t('MONTHS_SHORT_APR'),
        },
        {
            value: 5,
            name: t('MONTHS_LONG_MAY'),
            short_name: t('MONTHS_SHORT_MAY'),
        },
        {
            value: 6,
            name: t('MONTHS_LONG_JUNE'),
            short_name: t('MONTHS_SHORT_JUNE'),
        },
        {
            value: 7,
            name: t('MONTHS_LONG_JULE'),
            short_name: t('MONTHS_SHORT_JULE'),
        },
        {
            value: 8,
            name: t('MONTHS_LONG_AUG'),
            short_name: t('MONTHS_SHORT_AUG'),
        },
        {
            value: 9,
            name: t('MONTHS_LONG_SEPT'),
            short_name: t('MONTHS_SHORT_SEPT'),
        },
        {
            value: 10,
            name: t('MONTHS_LONG_OCT'),
            short_name: t('MONTHS_SHORT_OCT'),
        },
        {
            value: 11,
            name: t('MONTHS_LONG_NOV'),
            short_name: t('MONTHS_SHORT_NOV'),
        },
        {
            value: 12,
            name: t('MONTHS_LONG_DEC'),
            short_name: t('MONTHS_SHORT_DEC'),
        },
    ];

    return (
        <div className="w-full overflow-x-auto">
            <div className="flex w-full min-w-[1000px] flex-col rounded-lg bg-white border border-gray-10">
                {/*first row*/}
                <div className="w-full flex flex-row">
                    <div className="w-1/4 py-3 sticky left-0 bg-white border-r border-r-gray border-b border-b-gray-10 rounded-tl-lg flex items-center justify-center">
                        <div className="flex items-center justify-center rounded-lg p-0.5 bg-gray-100">
                            <Link className="text-sm font-normal px-2 py-1 rounded-lg bg-blue text-white pointer-events-none" to="#">
                                {t('CALENDAR_ROOM')}
                            </Link>
                            <Link className="text-sm font-normal px-2 py-1 rounded-lg hover:text-gray-20" to={`?link=equipments&type=year`}>
                                {t('CALENDAR_EQUIPMENT')}
                            </Link>
                        </div>
                    </div>
                    <div className="w-3/4 flex flex-row border-b border-b-gray-10">
                        {month.map(item => (
                            <div className={`w-1/12 py-3 flex items-center justify-center border-r border-r-gray-10 last:border-r-0`} key={item.value}>
                                {item.short_name}
                            </div>
                        ))}
                    </div>
                </div>

                {/*calendar*/}
                <div className="w-full">
                    {(!data || (!filter.venue && !filter.building)) && !fetching && (
                        <p className="text-sm flex w-full h-20 px-4 items-center">{t('CALENDAR_GET_STARTED')}</p>
                    )}

                    {fetching && (
                        <div className="w-full h-20 flex items-center justify-center">
                            <Loader />
                        </div>
                    )}

                    {data && !data.result.length && !fetching && (
                        <p className="text-sm flex w-full h-20 px-4 items-center">{t('CALENDAR_NOTHING_FOR_REQUEST')}</p>
                    )}

                    {data &&
                        data.result &&
                        !fetching &&
                        filter.venue &&
                        filter.building &&
                        data.result.map(venue => (
                            <React.Fragment key={`${venue.item}${venue.id}`}>
                                {venue.building &&
                                    venue.building.map(building => (
                                        <React.Fragment key={`${building.item}${building.id}`}>
                                            <div className="w-full flex flex-row">
                                                <div
                                                    className="w-1/4 sticky z-10 left-0 bg-white flex items-center border-r border-r-gray border-b border-b-gray-10 p-4"
                                                    data-tip={building.item}
                                                >
                                                    <span className="text-sm line-clamp-2">{building.item}</span>
                                                </div>
                                                <div className="w-3/4 z-[9] flex flex-row border-b border-b-gray-10">
                                                    {building.tasks &&
                                                        building.tasks.map((tasks, index) => (
                                                            <div
                                                                className="flex flex-col items-center justify-center flex-grow basis-0 border-r border-r-gray-10 last:border-r-0"
                                                                key={`${building.item}${building.id}${index}`}
                                                            >
                                                                {tasks.map((task, index) => (
                                                                    <div
                                                                        className={`w-full flex-grow basis-0 flex items-center py-1 justify-center select-none transition ${
                                                                            task.detail_id ? 'cursor-pointer hover:bg-gray-10' : ''
                                                                        } relative z-10 text-xs border-b border-b-gray-10 last:border-b-0 ${
                                                                            !task.work_active && task_detail.hash !== task.hash ? 'bg-gray-40' : ''
                                                                        } ${task.status_id === 5 && task_detail.hash !== task.hash ? 'bg-green-300' : ''} ${
                                                                            task.overdue
                                                                                ? 'after:content-[""] after:bg-red-600 after:w-1.5 after:h-1.5 after:rounded-full after:absolute after:top-[calc(50%+0.4rem)]'
                                                                                : ''
                                                                        } ${
                                                                            task_detail.hash === task.hash
                                                                                ? 'bg-blue text-white hover:bg-blue hover:cursor-pointer'
                                                                                : ''
                                                                        }`}
                                                                        key={task.hash || `no_item${building.id}${index}`}
                                                                        onClick={e => openDetail(e.target, task)}
                                                                        data-tip={`${task.start_month}.${task.year}`}
                                                                        data-place="top"
                                                                        data-type="info"
                                                                        ref={task_detail.hash === task.hash ? ref : null}
                                                                    >
                                                                        {task.period_type_short_name}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>

                                            {building.floor &&
                                                building.floor.map((floor, index) => (
                                                    <React.Fragment key={`${floor.item}${index}`}>
                                                        <div className="w-full flex flex-row">
                                                            <div
                                                                className="w-1/4 sticky z-10 left-0 bg-white flex items-center border-r border-r-gray border-b border-b-gray-10 p-4"
                                                                data-tip={floor.item}
                                                            >
                                                                <span className="text-sm line-clamp-2">{floor.item}</span>
                                                            </div>
                                                            <div className="w-3/4 z-[9] flex flex-row border-b border-b-gray-10">
                                                                {floor.tasks &&
                                                                    floor.tasks.map((tasks, index) => (
                                                                        <div
                                                                            className="flex flex-col items-center justify-center flex-grow basis-0 border-r border-r-gray-10 last:border-r-0"
                                                                            key={`${floor.item}${index}`}
                                                                        >
                                                                            {tasks.map((task, index) => (
                                                                                <div
                                                                                    className={`w-full flex-grow basis-0 flex items-center py-1 justify-center select-none transition ${
                                                                                        task.detail_id ? 'cursor-pointer hover:bg-gray-10' : ''
                                                                                    } relative z-10 text-xs border-b border-b-gray-10 last:border-b-0 ${
                                                                                        !task.work_active && task_detail.hash !== task.hash ? 'bg-gray-40' : ''
                                                                                    } ${
                                                                                        task.status_id === 5 && task_detail.hash !== task.hash
                                                                                            ? 'bg-green-300'
                                                                                            : ''
                                                                                    } ${
                                                                                        task.overdue
                                                                                            ? 'after:content-[""] after:bg-red-600 after:w-1.5 after:h-1.5 after:rounded-full after:absolute after:top-[calc(50%+0.4rem)]'
                                                                                            : ''
                                                                                    } ${
                                                                                        task_detail.hash === task.hash
                                                                                            ? 'bg-blue text-white hover:bg-blue hover:cursor-pointer'
                                                                                            : ''
                                                                                    }`}
                                                                                    key={task.hash || `no_item${floor.id}${index}`}
                                                                                    onClick={e => openDetail(e.target, task)}
                                                                                    data-tip={`${task.start_month}.${task.year}`}
                                                                                    data-place="top"
                                                                                    data-type="info"
                                                                                    ref={task_detail.hash === task.hash ? ref : null}
                                                                                >
                                                                                    {task.period_type_short_name}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>

                                                        {floor.office &&
                                                            floor.office.map((office, index) => (
                                                                <div className="w-full flex flex-row" key={`${office.item}${index}`}>
                                                                    <div
                                                                        className="w-1/4 sticky z-10 left-0 bg-white flex items-center border-r border-r-gray border-b border-b-gray-10 p-4"
                                                                        data-tip={office.item}
                                                                    >
                                                                        <span className="text-sm line-clamp-2">{office.item}</span>
                                                                    </div>
                                                                    <div className="w-3/4 z-[9] flex flex-row border-b border-b-gray-10">
                                                                        {office.tasks &&
                                                                            office.tasks.map((tasks, index) => (
                                                                                <div
                                                                                    className="flex flex-col items-center justify-center flex-grow basis-0 border-r border-r-gray-10 last:border-r-0"
                                                                                    key={`${office.item}${index}${index}`}
                                                                                >
                                                                                    {tasks.map((task, index) => (
                                                                                        <div
                                                                                            className={`w-full flex-grow basis-0 flex items-center py-1 justify-center select-none transition ${
                                                                                                task.detail_id ? 'cursor-pointer hover:bg-gray-10' : ''
                                                                                            } relative z-10 text-xs border-b border-b-gray-10 last:border-b-0 ${
                                                                                                !task.work_active && task_detail.hash !== task.hash
                                                                                                    ? 'bg-gray-40'
                                                                                                    : ''
                                                                                            } ${
                                                                                                task.status_id === 5 && task_detail.hash !== task.hash
                                                                                                    ? 'bg-green-300'
                                                                                                    : ''
                                                                                            } ${
                                                                                                task.overdue
                                                                                                    ? 'after:content-[""] after:bg-red-600 after:w-1.5 after:h-1.5 after:rounded-full after:absolute after:top-[calc(50%+0.4rem)]'
                                                                                                    : ''
                                                                                            } ${
                                                                                                task_detail.hash === task.hash
                                                                                                    ? 'bg-blue text-white hover:bg-blue hover:cursor-pointer'
                                                                                                    : ''
                                                                                            }`}
                                                                                            key={task.hash || `no_item${office.item}${index}`}
                                                                                            onClick={e => openDetail(e.target, task)}
                                                                                            data-tip={`${task.start_month}.${task.year}`}
                                                                                            data-place="top"
                                                                                            data-type="info"
                                                                                            ref={task_detail.hash === task.hash ? ref : null}
                                                                                        >
                                                                                            {task.period_type_short_name}
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </React.Fragment>
                                                ))}
                                        </React.Fragment>
                                    ))}
                            </React.Fragment>
                        ))}
                </div>
            </div>
            <ReactTooltip effect="solid" place="right" className="max-w-prose" />
        </div>
    );
};
