import { useDispatch, useSelector } from 'react-redux';

import { auto_mode as auto_mode_state, setSettings } from '../../../redux/slices/hospitalitySlice';
import { Toggle } from '../../common/widgets/Toggle';

export const AutoModeButton = () => {
    const dispatch = useDispatch();

    const auto_mode = useSelector(auto_mode_state);

    const changeAutoHandler = value => {
        dispatch(setSettings({ data: { options: JSON.stringify({ is_auto: value }) } }));
    };

    return (
        <div className="px-3">
            <Toggle checked={auto_mode} onChange={changeAutoHandler}>
                <svg width="14" height="14" viewBox="0 0 14 14" className="fill-white ml-[3px]">
                    <path d="M4.02508 9.91675H5.13341L5.71675 8.28342H8.28342L8.86675 9.91675H9.97508L7.58342 3.50008H6.41675L4.02508 9.91675ZM6.03758 7.35008L6.97091 4.69591H7.02925L7.96258 7.35008H6.03758ZM7.00008 12.8334C6.19314 12.8334 5.4348 12.6803 4.72508 12.374C4.01536 12.0678 3.398 11.6522 2.873 11.1272C2.348 10.6022 1.93237 9.9848 1.62612 9.27508C1.31987 8.56536 1.16675 7.80703 1.16675 7.00008C1.16675 6.19314 1.31987 5.4348 1.62612 4.72508C1.93237 4.01536 2.348 3.398 2.873 2.873C3.398 2.348 4.01536 1.93237 4.72508 1.62612C5.4348 1.31987 6.19314 1.16675 7.00008 1.16675C7.80703 1.16675 8.56536 1.31987 9.27508 1.62612C9.9848 1.93237 10.6022 2.348 11.1272 2.873C11.6522 3.398 12.0678 4.01536 12.374 4.72508C12.6803 5.4348 12.8334 6.19314 12.8334 7.00008C12.8334 7.80703 12.6803 8.56536 12.374 9.27508C12.0678 9.9848 11.6522 10.6022 11.1272 11.1272C10.6022 11.6522 9.9848 12.0678 9.27508 12.374C8.56536 12.6803 7.80703 12.8334 7.00008 12.8334ZM7.00008 11.6667C8.29314 11.6667 9.39418 11.2122 10.3032 10.3032C11.2122 9.39418 11.6667 8.29314 11.6667 7.00008C11.6667 5.70703 11.2122 4.60598 10.3032 3.69696C9.39418 2.78793 8.29314 2.33341 7.00008 2.33341C5.70703 2.33341 4.60598 2.78793 3.69696 3.69696C2.78793 4.60598 2.33341 5.70703 2.33341 7.00008C2.33341 8.29314 2.78793 9.39418 3.69696 10.3032C4.60598 11.2122 5.70703 11.6667 7.00008 11.6667Z" />
                </svg>

                <svg width="15" height="14" viewBox="0 0 15 14" className="fill-transparent mr-[3px]">
                    <path
                        d="M5.02118 9.91675H6.12951L6.71284 8.28342H9.27951L9.86284 9.91675H10.9712L8.57951 3.50008H7.41284L5.02118 9.91675ZM7.03368 7.35008L7.96701 4.69592H8.02534L8.95868 7.35008H7.03368ZM7.99618 12.8334C7.18923 12.8334 6.4309 12.6803 5.72118 12.374C5.01145 12.0678 4.39409 11.6522 3.86909 11.1272C3.34409 10.6022 2.92847 9.9848 2.62222 9.27508C2.31597 8.56536 2.16284 7.80703 2.16284 7.00008C2.16284 6.19314 2.31597 5.4348 2.62222 4.72508C2.92847 4.01536 3.34409 3.398 3.86909 2.873C4.39409 2.348 5.01145 1.93237 5.72118 1.62612C6.4309 1.31987 7.18923 1.16675 7.99618 1.16675C8.80312 1.16675 9.56145 1.31987 10.2712 1.62612C10.9809 1.93237 11.5983 2.348 12.1233 2.873C12.6483 3.398 13.0639 4.01536 13.3701 4.72508C13.6764 5.4348 13.8295 6.19314 13.8295 7.00008C13.8295 7.80703 13.6764 8.56536 13.3701 9.27508C13.0639 9.9848 12.6483 10.6022 12.1233 11.1272C11.5983 11.6522 10.9809 12.0678 10.2712 12.374C9.56145 12.6803 8.80312 12.8334 7.99618 12.8334ZM7.99618 11.6667C9.28923 11.6667 10.3903 11.2122 11.2993 10.3032C12.2083 9.39418 12.6628 8.29314 12.6628 7.00008C12.6628 5.70703 12.2083 4.60598 11.2993 3.69696C10.3903 2.78793 9.28923 2.33341 7.99618 2.33341C6.70312 2.33341 5.60208 2.78793 4.69305 3.69696C3.78402 4.60598 3.32951 5.70703 3.32951 7.00008C3.32951 8.29314 3.78402 9.39418 4.69305 10.3032C5.60208 11.2122 6.70312 11.6667 7.99618 11.6667Z"
                        fill="#707183"
                    />
                    <rect x="1.28899" y="1.70703" width="2" height="17" transform="rotate(-45 1.28899 1.70703)" fill="#707183" stroke="#BFBFBF" />
                </svg>
            </Toggle>
        </div>
    );
};
