//перевод объекта в массив
export const objectToArray = obj => Object.keys(obj).map(key => obj[key]);

//ввод с клавиатуры только целых чисел
export const inputOnlyInteger = str => str.trim().replace(/[^0-9]/g, '');

//функция-слушатель для кликов вне блока
export const listenForOutsideClick = (listening, setListening, blockRef, callback) => () => {
    if (listening) return;
    setListening(true);

    document.addEventListener('click', e => {
        const cur = blockRef.current;
        if (!blockRef.current) return;

        const node = e.target;
        if (cur.contains(node)) return;
        callback();
    });
};

//настройки для toast по умолчанию
export const toastOptions = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
};

// go to element
export const goToElement = id => {
    document.getElementById('wrapper').scrollTo({
        top: document.getElementById(id).offsetTop,
        behavior: 'smooth',
    });
};
