import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Popup } from '../../../common/popup/Popup';
import { MultiSelect } from '../../../common/widgets/MultiSelect';
import { CreateVenuePopupButton } from '../createPopup/CreateVenuePopupButton';
import { addBMSVenue, bms_servers_list as bms_servers_list_state } from '../../../../redux/slices/bmsSlice';

const initvalues = { title: '', servers: [] };

export const CreateVenuePopup = ({ isOpen, setIsOpen }) => {
    const dispatch = useDispatch();

    const bms_servers_list = useSelector(bms_servers_list_state);

    const [venue, setVenue] = useState(initvalues);

    useEffect(() => {
        setIsOpen(isOpen);

        if (!isOpen) {
            setVenue(initvalues);
        }
    }, [setIsOpen, isOpen]);

    const server_options = bms_servers_list.map(server => {
        return { label: server.title, value: server.id };
    });

    const handleCreateVenue = () => {
        dispatch(addBMSVenue({ data: venue }));

        setIsOpen(false);
        setVenue(initvalues);
    };

    return (
        <Popup isOpen={isOpen} setIsOpen={setIsOpen} className="w-[750px] h-80">
            <div className="text-center text-lg">Добавление нового объекта</div>
            <div className="flex justify-around mt-10 mb-24">
                <input
                    type="text"
                    className="w-96 h-10 border-gray-70 hover:border-gray-30 text-sm px-2 mr-2"
                    onChange={e => setVenue({ ...venue, title: e.target.value })}
                    value={venue.title}
                    placeholder="Наименование объекта"
                />

                <MultiSelect
                    options={server_options}
                    placeholder="Выберите серверы"
                    value={venue.servers}
                    callback={val => setVenue(venue => ({ ...venue, servers: val }))}
                    className="w-80"
                />
            </div>
            <CreateVenuePopupButton disabled={venue.title.length === 0} onClick={handleCreateVenue} />
        </Popup>
    );
};
