import React, { useMemo } from 'react';
import { useTable } from 'react-table';

export const TrendsTable = ({ data }) => {
    const columns = useMemo(
        () => [
            ...Object.keys(data.columns).map(el => ({
                Header: data.columns[el],
                accessor: el,
            })),
        ],
        [data]
    );

    const tableData = useMemo(
        () =>
            data.data.map(el => {
                const res = {};

                for (const [key, value] of Object.entries(el)) {
                    if (value === 'True') {
                        res[key] = '+';
                    } else if (value === 'False') {
                        res[key] = '-';
                    } else if (key === 'ts') {
                        const dateArr = value.split(' ');
                        res[key] = `${new Date(dateArr[0]).toLocaleDateString('ru-RU')} ${dateArr[1]}`;
                    } else {
                        res[key] = value;
                    }
                }

                return res;
            }),
        [data]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: tableData });

    return (
        <div className="w-full overflow-x-auto">
            <table {...getTableProps()} className="w-full border-separate border-spacing-0 text-sm">
                <thead className="text-black text-left">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                    className="bg-white font-medium px-2 first:pl-4 last:pr-4 py-5 border-y border-gray-10 first:border-l first:rounded-tl-lg last:border-r last:rounded-tr-lg"
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="block md:table-row mb-4 md:mb-0 border md:border-0 border-gray-10 rounded-lg overflow-hidden md:overflow-visible"
                            >
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            data-label={cell.column.Header}
                                            className={`font-normal flex justify-between md:table-cell text-right md:text-left before:content-[attr(data-label)] before:uppercase before:font-medium  md:before:hidden bg-white  py-5 px-2 md:first:pl-4 md:last:pr-4 border-b last:border-b-0 md:last:border-b border-gray-10 md:first:border-l md:last:border-r ${
                                                rows.length === index + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                            }`}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
