import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';

import { url } from '../../../../../api/config';
import { update_bms_document_node, documentState as documentState_state } from '../../../../../redux/slices/bmsSlice';

import { Box } from './Box';

export const Container = ({ nodes, editNodeHandler }) => {
    const dispatch = useDispatch();
    const documentState = useSelector(documentState_state);

    const containerSize = useRef({
        width: 1920,
        height: 1080,
    });

    const moveBox = useCallback(
        (id, left, top, leftPercent, topPercent, width, height, degree) => {
            const node = nodes.find(node => node.cn_id === id);
            const updateNode = {
                ...node,
                position: {
                    ...node.position,
                    left,
                    top,
                    leftPercent,
                    topPercent,
                    width: width === '0px' ? node.width : width,
                    height: height === '0px' ? node.height : height,
                    degree,
                },
            };

            dispatch(update_bms_document_node(updateNode));
        },
        [nodes]
    );

    const [, drop] = useDrop(
        () => ({
            accept: 'box',
            drop(item, monitor) {
                const delta = monitor.getDifferenceFromInitialOffset();
                const left = Math.round(item.left + delta.x);
                const top = Math.round(item.top + delta.y);

                moveBox(
                    item.id,
                    left,
                    top,
                    (left * 100) / containerSize.current.width,
                    (top * 100) / containerSize.current.height,
                    item.width,
                    item.height,
                    item.degree
                );
                return undefined;
            },
        }),
        [moveBox, documentState]
    );

    return (
        <div ref={drop} className="z-0 relative border-2 border-gray-10" style={{ width: containerSize.current.width, height: containerSize.current.height }}>
            {nodes.map(node => {
                return (
                    <Box key={node.cn_id} id={node.cn_id} node={node} editNodeHandler={editNodeHandler}>
                        <img src={`${url}${node.lib_data.default_pic}`} alt="lib item" className="object-contain w-full h-full" />
                    </Box>
                );
            })}
        </div>
    );
};
