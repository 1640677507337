import { t } from 'i18next';
const getColorStatus = value => {
    switch (value) {
        // new
        case 2:
            return (
                <div className="text-status_text-new flex items-center gap-2">
                    <svg width="10" height="10" viewBox="0 0 10 10" className="fill-status_text-new">
                        <circle cx="5" cy="5" r="5" />
                    </svg>
                    {t('APPLICATIONS_STATUS_NEW')}
                </div>
            );

        // deferred
        case 1:
            return (
                <div className="text-status_text-deferred flex items-center gap-2">
                    <svg width="10" height="10" viewBox="0 0 10 10" className="fill-status_text-deferred">
                        <circle cx="5" cy="5" r="5" />
                    </svg>
                    {t('APPLICATIONS_STATUS_DEFERRED')}
                </div>
            );

        // in work
        case 3:
            return (
                <div className="text-status_text-in_work flex items-center gap-2">
                    <svg width="10" height="10" viewBox="0 0 10 10" className="fill-status_text-in_work">
                        <circle cx="5" cy="5" r="5" />
                    </svg>
                    {t('APPLICATIONS_STATUS_IN_WORK')}
                </div>
            );

        // finished
        case 8:
            return (
                <div className="text-status_text-finished flex items-center gap-2">
                    <svg width="10" height="10" viewBox="0 0 10 10" className="fill-status_text-finished">
                        <circle cx="5" cy="5" r="5" />
                    </svg>
                    {t('APPLICATIONS_STATUS_FINISHED')}
                </div>
            );

        // completed
        case 5:
            return (
                <div className="text-status_text-completed flex items-center gap-2">
                    <svg width="10" height="10" viewBox="0 0 10 10" className="fill-status_text-completed">
                        <circle cx="5" cy="5" r="5" />
                    </svg>
                    {t('APPLICATIONS_STATUS_COMPLETED')}
                </div>
            );

        // canceled
        case 6:
            return (
                <div className="text-status_text-canceled flex items-center gap-2">
                    <svg width="10" height="10" viewBox="0 0 10 10" className="fill-status_text-canceled">
                        <circle cx="5" cy="5" r="5" />
                    </svg>
                    {t('APPLICATIONS_STATUS_CANCELED')}
                </div>
            );

        default:
            break;
    }
};
export default getColorStatus;
