import { createPortal } from 'react-dom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import { changeApplicationFetch, set_new_application_info } from '../../redux/slices/applicationsSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { t } from 'i18next';

const popUpRoot = document.querySelector('#modal-root');

export const PopUpSaveTask = ({ onClose, data, path }) => {
    const dispatch = useDispatch();
    const username = useSelector(username_state);

    const auth_key_calendar = useSelector(auth_key_calendar_state);

    const navigate = useNavigate();

    const [cookie] = useCookies();
    const lang = cookie.i18next;

    useEffect(() => {
        const handleKeyDown = e => {
            if (e.code === 'Escape') {
                onClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    });

    const hadleBackdropClick = e => {
        if (e.currentTarget === e.target) {
            onClose();
        }
    };

    const handlerCancelClick = async () => {
        await dispatch(set_new_application_info(false));

        onClose();
        navigate(`${path}`);
    };

    const handlerSaveClick = async () => {
        await dispatch(set_new_application_info(false));
        await dispatch(changeApplicationFetch({ username, auth_key_calendar, data, lang }));

        onClose();
        navigate(`${path}`);
    };

    const PhotoModal = ({ hadleBackdropClick }) => {
        return (
            <div className="overlay" onClick={hadleBackdropClick}>
                <div className="popup w-52 h-24">
                    <div className="flex items-center justify-center mt-4 mb-2">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path
                                d="M7 0.875C3.61758 0.875 0.875 3.61758 0.875 7C0.875 10.3824 3.61758 13.125 7 13.125C10.3824 13.125 13.125 10.3824 13.125 7C13.125 3.61758 10.3824 0.875 7 0.875ZM6.5625 4.04688C6.5625 3.98672 6.61172 3.9375 6.67188 3.9375H7.32812C7.38828 3.9375 7.4375 3.98672 7.4375 4.04688V7.76562C7.4375 7.82578 7.38828 7.875 7.32812 7.875H6.67188C6.61172 7.875 6.5625 7.82578 6.5625 7.76562V4.04688ZM7 10.0625C6.82827 10.059 6.66476 9.98831 6.54455 9.86562C6.42434 9.74294 6.35701 9.57801 6.35701 9.40625C6.35701 9.23449 6.42434 9.06956 6.54455 8.94688C6.66476 8.82419 6.82827 8.75351 7 8.75C7.17173 8.75351 7.33524 8.82419 7.45545 8.94688C7.57566 9.06956 7.64299 9.23449 7.64299 9.40625C7.64299 9.57801 7.57566 9.74294 7.45545 9.86562C7.33524 9.98831 7.17173 10.059 7 10.0625Z"
                                fill="#FAAD14"
                            />
                        </svg>
                        <div className="text-sm ml-2">{t('APPLICATIONS_SAVE_TASK')}</div>
                    </div>
                    <div className="text-sm flex justify-center">
                        <div>
                            <button type="button" className="border rounded py-1 px-2" onClick={handlerCancelClick}>
                                {t('CALENDAR_CANCEL')}
                            </button>
                        </div>
                        <div>
                            <button type="button" className="ml-2 bg-status_text-new py-1 px-2 border rounded text-white" onClick={handlerSaveClick}>
                                {t('CALENDAR_BTN_SAVE')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return createPortal(<PhotoModal hadleBackdropClick={hadleBackdropClick} />, popUpRoot);
};
