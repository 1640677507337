import { useSelector } from 'react-redux';
import CustomScroll from 'react-custom-scroll';

import { workers as workers_state, workers_fetching as workers_fetching_state } from '../../../redux/slices/hospitalitySlice';
import { Draggable } from '../../common/dnd/Draggable';
import { Droppable } from '../../common/dnd/Droppable';
import { Loader } from '../../common/Loader';
import { Worker } from '../worker/Worker';

export const CleanersList = () => {
    const workers = useSelector(workers_state);
    const workers_fetching = useSelector(workers_fetching_state);

    return (
        <div className="w-52 bg-white shrink-0">
            <CustomScroll allowOuterScroll={true}>
                <Droppable id="default">
                    <div className="overflow-hidden">
                        {workers_fetching ? (
                            <div className="w-full p-6 flex justify-center items-center">
                                <Loader height={30} width={30} />
                            </div>
                        ) : (
                            <div className="flex flex-col gap-4 bg-white px-3 py-3 box-border">
                                {workers.filter(worker => worker.state && worker.is_filtered && !worker.supervisor_id).length ? (
                                    workers
                                        .filter(worker => worker.state && worker.is_filtered && !worker.supervisor_id)
                                        .map(worker => (
                                            <Draggable id={worker.id} key={worker.id}>
                                                <Worker description={worker.name}>{worker.name}</Worker>
                                            </Draggable>
                                        ))
                                ) : (
                                    <div className="font-medium text-center text-gray mt-3 h-full">Все выбранные клинёры распределены</div>
                                )}
                            </div>
                        )}
                    </div>
                </Droppable>
            </CustomScroll>
        </div>
    );
};
