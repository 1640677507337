import { useDispatch } from 'react-redux';

import { ButtonRectangular } from '../../common/btn/ButtonRectangular';
import { getWorkers, updateTasks } from '../../../redux/slices/hospitalitySlice';
import { useState } from 'react';

export const RefreshButton = () => {
    const dispatch = useDispatch();

    const [isClicked, setIsClicked] = useState(false);

    const refreshHandler = async () => {
        setIsClicked(true);
        await dispatch(getWorkers());
        await dispatch(updateTasks());
        setIsClicked(false);
    };

    return (
        <ButtonRectangular className="text-gray-50 border-gray-70 bg-white hover:bg-gray-100 w-36" onClick={refreshHandler} loading={isClicked}>
            <svg width="20" height="20" viewBox="0 0 20 20" className="fill-current">
                <path d="M10.0001 15.7915C8.39219 15.7915 7.01453 15.22 5.86709 14.0768C4.71966 12.9336 4.16678 11.533 4.20845 9.8749V9.37004L2.8126 10.7659L2.03857 9.99188L4.7501 7.28038L7.46162 9.99188L6.6876 10.7659L5.29174 9.37004V9.8749C5.26396 11.2392 5.71455 12.3859 6.64351 13.3148C7.57247 14.2438 8.69133 14.7083 10.0001 14.7083C10.2896 14.7083 10.5719 14.6845 10.8471 14.6369C11.1222 14.5894 11.389 14.5181 11.6475 14.423L12.4392 15.2146C12.0813 15.3834 11.6977 15.5218 11.2886 15.6297C10.8794 15.7376 10.4499 15.7915 10.0001 15.7915ZM15.2501 12.7194L12.5386 10.0079L13.3126 9.2339L14.7084 10.6298V10.1249C14.7362 8.76058 14.2856 7.61394 13.3567 6.68498C12.4277 5.75602 11.3089 5.29154 10.0001 5.29154C9.72446 5.29154 9.44561 5.31531 9.16355 5.36286C8.88151 5.4104 8.61121 5.48171 8.35266 5.57679L7.56101 4.78517C7.91891 4.61636 8.30593 4.47801 8.72205 4.37011C9.13819 4.2622 9.56421 4.20825 10.0001 4.20825C11.6219 4.20825 13.003 4.77983 14.1435 5.92298C15.284 7.06615 15.8334 8.46679 15.7917 10.1249V10.6298L17.1876 9.2339L17.9616 10.0079L15.2501 12.7194Z" />
            </svg>
            Обновить
        </ButtonRectangular>
    );
};
