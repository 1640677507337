import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../api/functions';
import { actDoAutoWorkers, distribute_tasks_fetching as distribute_tasks_fetching_state } from '../../../redux/slices/hospitalitySlice';
import { ButtonRectangular } from '../../common/btn/ButtonRectangular';

export const DistributeButton = () => {
    const dispatch = useDispatch();

    const distribute_tasks_fetching = useSelector(distribute_tasks_fetching_state);

    const distributeHandler = async () => {
        const result = await dispatch(actDoAutoWorkers());

        if (result) {
            toast.success('Изменения применены', toastOptions);
        } else {
            toast.error('Ошибка при сохранении. Пожалуйста, попробуйте еще раз', toastOptions);
        }
    };

    return (
        <ButtonRectangular onClick={distributeHandler} className="w-full" loading={distribute_tasks_fetching}>
            Распределить
        </ButtonRectangular>
    );
};
