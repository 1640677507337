import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import {
    bms_detail_alerts_fetching as bms_detail_alerts_fetching_state,
    bms_detail_alerts as bms_detail_alerts_state,
    getBMSDocAlerts,
} from '../../../../../redux/slices/bmsSlice';

import { Loader } from '../../../../common/Loader';
import { TrendsTable } from './TrendsTable';

export const EventLog = ({ bmsDetail }) => {
    const dispatch = useDispatch();

    const bms_detail_alerts_fetching = useSelector(bms_detail_alerts_fetching_state);
    const bms_detail_alerts = useSelector(bms_detail_alerts_state);

    useEffect(() => {
        if (bmsDetail && bmsDetail.hasOwnProperty('id')) {
            dispatch(getBMSDocAlerts({ data: { doc_id: bmsDetail.id } }));

            const interval = setInterval(async () => {
                dispatch(getBMSDocAlerts({ data: { doc_id: bmsDetail.id } }));
            }, 60000);
            return () => clearInterval(interval);
        }
    }, [bmsDetail]);

    return (
        <div className="mb-8" id="alerts">
            <div className="uppercase text-xl text-black font-semibold mb-4">Журнал событий</div>
            {bms_detail_alerts_fetching ? (
                <div className="flex w-full justify-center">
                    <Loader />
                </div>
            ) : (
                <div>
                    {bms_detail_alerts && bms_detail_alerts.hasOwnProperty('data') && bms_detail_alerts.data.length ? (
                        <TrendsTable data={bms_detail_alerts} />
                    ) : (
                        <p>Нет данных</p>
                    )}
                </div>
            )}
        </div>
    );
};
