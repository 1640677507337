import { useMatches } from 'react-router-dom';

import { Breadcrumbs } from '../breadcrumbs/Breadcrumbs';
import { BackButton } from './BackButton';

export const SubHeaderLeft = ({ children }) => {
    const matches = useMatches();
    const crumbs = matches
        .filter(match => Boolean(match.handle?.crumb))
        .map((match, index, arr) => (arr[index + 1] ? match.handle.crumb(true) : match.handle.crumb()));

    return (
        <div className="flex flex-col justify-center min-w-fit">
            <Breadcrumbs crumbs={crumbs} />
            <div className="flex items-center min-h-[2rem]">
                {/* {crumbs.length > 2 && <BackButton link={crumbs[crumbs.length - 2]?.props?.link} />} */}
                {children}
            </div>
        </div>
    );
};
