import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { useCheckRole } from '../../../hooks/useCheckRole';
import {
    getBMSDocList,
    bms_doc_list_fetching as bms_doc_list_fetching_state,
    bms_doc_list as bms_doc_list_state,
    removeBMSDoc,
} from '../../../redux/slices/bmsSlice';

import { Loader } from '../../common/Loader';

export const DocumentList = () => {
    const dispatch = useDispatch();
    const { venueId, systemId } = useParams();

    const bms_doc_list_fetching = useSelector(bms_doc_list_fetching_state);
    const bms_doc_list = useSelector(bms_doc_list_state);

    const isDocumentDelete = useCheckRole('bms', 'bms.document.del');

    useEffect(() => {
        if (!bms_doc_list.length) {
            dispatch(getBMSDocList({ object_id: venueId, direction_id: systemId }));
        }
    }, [dispatch, venueId]);

    const handlerSystemRemoveClick = id => {
        dispatch(removeBMSDoc({ id }));
    };

    if (bms_doc_list_fetching) {
        return (
            <div className="flex justify-center items-center w-52 h-28">
                <Loader />
            </div>
        );
    }
    return (
        <>
            {bms_doc_list.length ? (
                bms_doc_list.map(doc => (
                    <div className="relative" key={`bms${doc.id}`}>
                        <Link
                            to={`/bms/venues/${venueId}/system/${systemId}/documents/${doc.id}`}
                            className="flex flex-col justify-center items-center overflow-hidden w-52 h-28 p-2 border-gray-10 hover:border-blue border-2 rounded-md bg-white hover:bg-blue-10"
                        >
                            {doc.title && <div className="uppercase text-sm font-bold text-center line-clamp-2">{doc.title}</div>}
                            {doc.description && <div className="text-xs mt-0.5 text-center line-clamp-2">{doc.description}</div>}
                        </Link>
                        {isDocumentDelete && (
                            <button className="absolute top-2 right-2 cursor-pointer" onClick={() => handlerSystemRemoveClick(doc.id)}>
                                <svg width="18" height="18" viewBox="0 0 18 18" className="hover:fill-red-500 fill-current">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12.75 6C13.1642 6 13.5 6.33579 13.5 6.75V14.25C13.5 15.4926 12.4926 16.5 11.25 16.5H6.75C5.50736 16.5 4.5 15.4926 4.5 14.25V6.75C4.5 6.33579 4.83579 6 5.25 6H12.75ZM12 7.5H6V14.25C6 14.6642 6.33579 15 6.75 15H11.25C11.6642 15 12 14.6642 12 14.25V7.5ZM6.75 2.25C6.75 1.83579 7.08579 1.5 7.5 1.5H10.5C10.9142 1.5 11.25 1.83579 11.25 2.25V3H14.25C14.6642 3 15 3.33579 15 3.75C15 4.16421 14.6642 4.5 14.25 4.5H3.75C3.33579 4.5 3 4.16421 3 3.75C3 3.33579 3.33579 3 3.75 3H6.75V2.25Z"
                                    />
                                </svg>
                            </button>
                        )}
                    </div>
                ))
            ) : (
                <div className="flex flex-col justify-center items-center overflow-hidden w-64 h-28 p-2">Нет созданных документов</div>
            )}
        </>
    );
};
