import { LibraryGroup } from './LibraryGroup';

export const LibraryType = ({ type, itemLibraryHandler, isActive }) => {
    return (
        <div className={`mb-4 ${isActive ? 'block' : 'hidden'}`}>
            {type.groups.length ? (
                type.groups.map(group => <LibraryGroup group={group} key={group.title} itemLibraryHandler={itemLibraryHandler} />)
            ) : (
                <p>Нет элементов</p>
            )}
        </div>
    );
};
