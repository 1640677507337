import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { filter_for_tasks, object as object_state, reset_filter_for_tasks, set_filter_for_tasks, updateWorkers } from '../../../redux/slices/hospitalitySlice';
import { StatusesFilter } from '../cleanersFilter/StatusesFilter';
import { ClearTypesFilter } from '../cleanersFilter/ClearTypesFilter';

export const SupervisorsFilterSection = () => {
    const dispatch = useDispatch();

    const object = useSelector(object_state);
    const filter = useSelector(filter_for_tasks);

    useEffect(() => {
        if (filter.is_touch) {
            dispatch(updateWorkers());
        }
    }, [filter, dispatch]);

    useEffect(() => {
        if (object) {
            dispatch(reset_filter_for_tasks());
        }
    }, [object, dispatch]);

    const setFilterByType = ({ type, val }) => {
        if (filter.data[type] !== val) {
            dispatch(set_filter_for_tasks({ type, val }));
        }
    };

    return (
        <div className="flex flex-row flex-wrap gap-4">
            <ClearTypesFilter setFilter={setFilterByType} value={filter.data.clearTypes} />
            <StatusesFilter setFilter={setFilterByType} value={filter.data.statuses} />
        </div>
    );
};
