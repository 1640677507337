import { useSelector } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';

import { documentState as documentState_state } from '../../../../redux/slices/bmsSlice';
import { url } from '../../../../api/config';

export const ElementsSection = ({ containerSize }) => {
    const documentState = useSelector(documentState_state);

    const [containerHeight, setContainerHeight] = useState(null);
    const [size, setSize] = useState(null);

    const sizeRef = useCallback(
        node => {
            if (node !== null) {
                setContainerHeight(node.getBoundingClientRect().width / (1920 / 1080));
            }
        },
        [size]
    );

    const resizeListener = e => setSize(e);

    useEffect(() => {
        window.addEventListener('resize', resizeListener);
        return () => window.removeEventListener('resize', resizeListener);
    }, []);

    return (
        <div
            ref={sizeRef}
            className="relative border-2 border-gray-10 overflow-hidden"
            style={{
                maxWidth: containerSize.current.width,
                maxHeight: containerSize.current.height,
                minWidth: '720px',
                minHeight: '404px',
                width: '100%',
                height: containerHeight,
            }}
        >
            {documentState.nodes.map(el => {
                const lib_data = el.lib_data;
                const position = el.position;
                const src = el.hasOwnProperty('state_pic') ? el.state_pic : lib_data.hasOwnProperty('default_pic') && lib_data.default_pic;

                if (src && position.hasOwnProperty('left') && position.hasOwnProperty('top')) {
                    const percent = containerHeight / containerSize.current.height;
                    return (
                        <div
                            className="absolute"
                            key={`node${el.cn_id}`}
                            style={{
                                top: `calc(${position.top} * ${percent}px)`,
                                left: `calc(${position.left} * ${percent}px)`,
                                width: `calc(${position.width} * ${percent})`,
                                height: `calc(${position.height} * ${percent}`,
                                minWidth: `calc(720 * ${position.width} / ${containerSize.current.width})`,
                                minHeight: `calc(404 * ${position.height} / ${containerSize.current.height})`,
                                rotate: `${position.degree}deg`,
                                zIndex: position.zIndex,
                            }}
                        >
                            <img
                                data-tip={`${el.name}, Статус: ${el.state}`}
                                alt={el.name}
                                src={`${url}${src}`}
                                className="w-full h-full object-contain block"
                            />
                        </div>
                    );
                }

                return null;
            })}
        </div>
    );
};
