import { useState } from 'react';

import { LibraryType } from './LibraryType';
import { LibraryTypesSwitcher } from './LibraryTypesSwitcher';

export const LibraryDirection = ({ direction, itemLibraryHandler }) => {
    const [isTypeActive, setIsTypeActive] = useState(direction.types[0].title);

    return (
        <div className="mb-4">
            <p className="mb-3 uppercase text-md text-black font-semibold">{direction.title}</p>

            <LibraryTypesSwitcher types={direction.types} isActive={isTypeActive} setIsActive={setIsTypeActive} />

            {direction.types.length ? (
                direction.types.map(type => (
                    <LibraryType isActive={isTypeActive === type.title} key={type.title} type={type} itemLibraryHandler={itemLibraryHandler} />
                ))
            ) : (
                <p>Нет элементов в системе</p>
            )}
        </div>
    );
};
