import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Field, Form, Formik, useField, useFormikContext } from 'formik';
import { useCookies } from 'react-cookie';
import Select, { components } from 'react-select';
import SlidingPane from 'react-sliding-pane';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import { toast } from 'react-toastify';

import { t } from 'i18next';
import { PicsLoader } from '../admin_scheduler/detail/PicsLoader';

import {
    venues as venues_state,
    filter as filter_state,
    filter_fetch as filter_fetch_state,
    detail_is_open as detail_is_open_state,
    applications_error as applications_error_state,
    application_created as application_created_state,
    usersListFetch,
    floorsListFetch,
    officesListFetch,
    systemsListFetch,
    devicesListFetch,
    applicationsFetch,
    buildingsListFetch,
    executorsListFetch,
    addApplicationFetch,
    set_detail_open,
    set_filter_fetch_floors,
    set_filter_fetch_offices,
    set_filter_fetch_systems,
    set_filter_fetch_devices,
    set_filter_fetch_buildings,
    set_filter_fetch_users_list,
    set_filter_fetch_executors_list,
} from '../../redux/slices/applicationsSlice.js';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { Loader } from '../common/Loader';

export const Detail = ({ application }) => {
    const [files, setFiles] = useState([]);
    const [venue, setVenue] = useState(null);
    const [floor, setFloor] = useState(null);
    const [office, setOffice] = useState(null);
    const [system, setSystem] = useState(null);
    // const [device, setDevice] = useState(null);
    const [building, setBuilding] = useState(null);

    const [department, setDepartment] = useState(null);
    const [additionFilds, setAdditionFilds] = useState(false);

    const detailRef = useRef(true);

    const [cookie] = useCookies();
    const lang = cookie.i18next;

    const filter = useSelector(filter_state);
    const username = useSelector(username_state);
    const venuesState = useSelector(venues_state);
    const isOpen = useSelector(detail_is_open_state);
    const filter_fetch = useSelector(filter_fetch_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const applications_error = useSelector(applications_error_state);
    const application_created = useSelector(application_created_state);

    const dispatch = useDispatch();

    const closeHandler = async () => {
        dispatch(set_detail_open(false));
        dispatch(set_filter_fetch_floors([]));
        dispatch(set_filter_fetch_offices([]));
        dispatch(set_filter_fetch_systems([]));
        dispatch(set_filter_fetch_devices([]));
        dispatch(set_filter_fetch_buildings([]));
        dispatch(set_filter_fetch_users_list([]));
        dispatch(set_filter_fetch_executors_list([]));

        setFiles([]);
        setVenue(null);
        setDepartment(null);
        setAdditionFilds(false);
    };

    const floors_options = filter_fetch.floors;
    const offices_options = filter_fetch.offices;
    const devices_options = filter_fetch.devices;
    const systems_options = filter_fetch.systems;
    const priority_options = filter_fetch.priority;
    const buildings_options = filter_fetch.buildings;
    const executors_options = filter_fetch.executors;
    const departments_options = filter_fetch.departments;

    useEffect(() => {
        if (applications_error) {
            for (const item in applications_error) {
                applications_error[item].map(el =>
                    toast.error(`${el}`, {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    })
                );
            }
        }
    }, [dispatch, applications_error]);

    useEffect(() => {
        if (!additionFilds) {
            setFloor(null);
            setOffice(null);
            setSystem(null);
            setBuilding(null);
        }
    }, [additionFilds]);

    // department and venue set
    useEffect(() => {
        if (application) {
            setVenue(application.venue_id);
            setDepartment(application.department_id);
        }
    }, [application]);

    // venue fetch
    useEffect(() => {
        if (venue) {
            dispatch(usersListFetch({ username, auth_key_calendar, lang, venue }));
        }

        if (!venue) {
            if (additionFilds) {
                setFloor(null);
                setOffice(null);
                setSystem(null);
                setBuilding(null);
                dispatch(set_filter_fetch_floors([]));
                dispatch(set_filter_fetch_offices([]));
                dispatch(set_filter_fetch_systems([]));
                dispatch(set_filter_fetch_devices([]));
                dispatch(set_filter_fetch_buildings([]));
                dispatch(set_filter_fetch_users_list([]));
                dispatch(set_filter_fetch_executors_list([]));
            }
        }
    }, [venue, username, auth_key_calendar, lang, dispatch, additionFilds]);

    // executors fetch
    useEffect(() => {
        if (!(department && venue)) {
            return;
        } else {
            dispatch(executorsListFetch({ username, auth_key_calendar, venue, department, lang }));
        }
    }, [department, venue, username, auth_key_calendar, lang, dispatch]);

    // buildings fetch
    useEffect(() => {
        venue && dispatch(buildingsListFetch({ username, auth_key_calendar, lang, venue }));
        if (additionFilds) {
            setBuilding(null);
            setFloor(null);
            setOffice(null);
            setSystem(null);
            dispatch(set_filter_fetch_floors([]));
            dispatch(set_filter_fetch_offices([]));
            dispatch(set_filter_fetch_systems([]));
            dispatch(set_filter_fetch_devices([]));
            dispatch(set_filter_fetch_buildings([]));
        }
    }, [dispatch, username, auth_key_calendar, lang, venue, additionFilds]);

    // floors fetch
    useEffect(() => {
        if (additionFilds) {
            setFloor(null);
            setOffice(null);
            setSystem(null);
            dispatch(set_filter_fetch_floors([]));
            dispatch(set_filter_fetch_offices([]));
            dispatch(set_filter_fetch_systems([]));
            dispatch(set_filter_fetch_devices([]));
            building && dispatch(floorsListFetch({ username, auth_key_calendar, lang, building }));
        }
    }, [dispatch, username, auth_key_calendar, lang, building, additionFilds]);

    // offices fetch
    useEffect(() => {
        if (additionFilds) {
            setOffice(null);
            setSystem(null);
            dispatch(set_filter_fetch_offices([]));
            dispatch(set_filter_fetch_systems([]));
            dispatch(set_filter_fetch_devices([]));
            floor && dispatch(officesListFetch({ username, auth_key_calendar, lang, floor }));
        }
    }, [dispatch, username, auth_key_calendar, lang, floor, additionFilds]);

    // systems fetch
    useEffect(() => {
        if (additionFilds) {
            setSystem(null);
            dispatch(set_filter_fetch_systems([]));
            dispatch(set_filter_fetch_devices([]));
            office && dispatch(systemsListFetch({ username, auth_key_calendar, lang, office }));
        }
    }, [dispatch, username, auth_key_calendar, lang, office, additionFilds]);

    // devices fetch
    useEffect(() => {
        if (additionFilds) {
            dispatch(set_filter_fetch_devices([]));
            system && dispatch(devicesListFetch({ username, auth_key_calendar, lang, system }));
        }
    }, [dispatch, username, auth_key_calendar, lang, system, additionFilds]);

    // closing the modal window and requesting an updated list of applications
    useEffect(() => {
        if (detailRef.current) {
            detailRef.current = false;
            return;
        } else if (!application_created) {
            return;
        } else if (!application) {
            dispatch(applicationsFetch({ username, auth_key_calendar, filter, lang }));
        }
    }, [application_created, username, auth_key_calendar, lang, dispatch, application, filter]);

    const CustomSelectField = props => {
        const { setFieldValue } = useFormikContext();

        const [field, , helpers] = useField(props);
        const { setValue } = helpers;

        const getSelectState = item => {
            if (field.name === 'name_id' && item?.value === undefined) {
                setFieldValue('building_id', '');
                setFieldValue('floor_id', '');
                setFieldValue('office_id', '');
                setFieldValue('system_id', '');
                setFieldValue('devices_id', '');
            }
            if (field.name === 'name_id') {
                setVenue(item?.value);
                setValue(item?.value ? item.value : ' ');

                if (!item?.value) {
                    setAdditionFilds(false);
                }
            }
            if (field.name === 'department_id') {
                setDepartment(item.value);
                setValue(item.value);
            }
            if (field.name === 'building_id') {
                setBuilding(item.value);
                setValue(item.value);
            }
            if (field.name === 'floor_id') {
                setFloor(item.value);
                setValue(item.value);
            }
            if (field.name === 'office_id') {
                setOffice(item.value);
                setValue(item.value);
            }
            if (field.name === 'system_id') {
                setSystem(item.value);
                setValue(item.value);
            } else {
                setValue(item?.value);
            }
        };
        return (
            <Select
                defaultValue={props.options.find(item => item.value === field.value)}
                onChange={item => getSelectState(item)}
                placeholder={t('ADM_PLANNER_PLACEHOLD_DEPART_PL')}
                {...props}
            />
        );
    };

    const CustomDatePickerField = props => {
        const { setFieldValue } = useFormikContext();
        const [field] = useField(props);

        return (
            <div className="deffered_datepicker">
                <DatePicker
                    {...field}
                    {...props}
                    selected={field.value || null}
                    onChange={val => {
                        setFieldValue(field.name, val);
                    }}
                />
            </div>
        );
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <svg className=" w-[20px] h-[20px]" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M8.33333 14.1667C11.555 14.1667 14.1667 11.555 14.1667 8.33333C14.1667 5.11167 11.555 2.5 8.33333 2.5C5.11167 2.5 2.5 5.11167 2.5 8.33333C2.5 11.555 5.11167 14.1667 8.33333 14.1667Z"
                        stroke="#B3B3BC"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M12.5 12.5L17.5 17.5" stroke="#B3B3BC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </components.DropdownIndicator>
        );
    };

    const formSubmit = async (values, { setSubmitting }) => {
        const end_date = new Date(values.expected_end_date);

        const data = {
            ...values,
            venue_id: JSON.stringify(values.name_id),
            name: values.description,
            files: files,
            id: application?.id,
            expected_end_date:
                values.expected_end_date !== ''
                    ? `${end_date.getFullYear()}-${
                          end_date.getMonth() + 1
                      }-${end_date.getDate()} ${end_date.getHours()}:${end_date.getMinutes()}:${end_date.getSeconds()}`
                    : '',
        };

        await dispatch(addApplicationFetch({ username, auth_key_calendar, data, lang }));
        setFiles([]);
        setSubmitting(false);
        setAdditionFilds(false);

        // !applications_error && (await dispatch(set_detail_open(false)));
        await dispatch(set_filter_fetch_floors([]));
        await dispatch(set_filter_fetch_offices([]));
        await dispatch(set_filter_fetch_systems([]));
        await dispatch(set_filter_fetch_devices([]));
        await dispatch(set_filter_fetch_buildings([]));
        await dispatch(set_filter_fetch_users_list([]));
        await dispatch(set_filter_fetch_executors_list([]));
    };

    const initValues = {
        name_id: '',
        building_id: '',
        floor_id: '',
        office_id: '',
        system_id: '',
        device_id: '',
        department_id: '',
        user_id: '',
        apriority: '',
        description: '',
        expected_end_date: '',
        task_category_id: '',
        status_id: '',
        deferred_date: '',
        creator: '',
        creation_date: '',
    };

    return (
        <>
            <SlidingPane
                isOpen={isOpen}
                hideHeader={true}
                onRequestClose={closeHandler}
                overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100"
                width="26rem"
            >
                <div className="border-b-2 ml-[-1.5rem] mr-[-1.5rem]">
                    <div className="flex items-start justify-between w-full sticky -top-6 pl-6 bg-white z-20 mb-6">
                        <div className="font-bold text-black text-2xl flex flex-col ">
                            <span>{t('APPLICATIONS_NEW')}</span>
                            {/* <span className="font-normal text-sm mt-2">{application ? '' : t('APPLICATIONS_NEW_NOTTIF')}</span> */}
                        </div>
                        <button
                            className="w-9 h-9 p-0 mr-6 rounded-full bg-transparent hover:bg-gray-100 flex items-center justify-center"
                            onClick={closeHandler}
                        >
                            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                                <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                            </svg>
                        </button>
                    </div>
                </div>

                <Formik
                    initialValues={initValues}
                    validate={values => {
                        const errors = {};

                        if (!values.name_id) {
                            errors.name_id = t('ADM_PLANNER_ERROR_DESCR');
                        }
                        if (!values.department_id) {
                            errors.department_id = t('ADM_PLANNER_ERROR_DESCR');
                        }
                        if (!values.description) {
                            errors.description = t('ADM_PLANNER_ERROR_DESCR');
                        }
                        if (values.description.length < 3) {
                            errors.description = t('ADM_PLANNER_ERROR_DESCR_MIN');
                        }

                        return errors;
                    }}
                    onSubmit={formSubmit}
                >
                    {({ values, errors, touched, isSubmitting }) => (
                        <Form className="flex flex-col">
                            {/* venue */}
                            <div className={`${venue === null || venue === undefined ? 'mb-4' : ''} last:mb-0`}>
                                <p className="text-sm mb-2 text-gray-20 flex w-full mt-4">
                                    {t('APPLICATIONS_OBJ')}
                                    {touched.name_id && errors.name_id ? <span className="text-red-500 font-semibold ml-auto">{errors.name_id}</span> : ''}
                                </p>
                                <CustomSelectField
                                    name="name_id"
                                    options={venuesState}
                                    className="select text-sm w-full"
                                    isClearable={true}
                                    isSearchable={true}
                                    components={{ DropdownIndicator }}
                                    placeholder={t('APPLICATIONS_DETAILS_PLH')}
                                    classNamePrefix="filter"
                                    isDisabled={isSubmitting}
                                />
                                {values.name_id && (
                                    <div
                                        className="w-full pr-4 text-end text-lg opacity-80 hover:text-status_text-new cursor-pointer"
                                        onClick={() => setAdditionFilds(!additionFilds)}
                                    >
                                        ...
                                    </div>
                                )}
                            </div>
                            {additionFilds && (
                                <div className=" mb-4 ">
                                    <p className="text-sm mb-2 text-gray-20 flex w-full">{t('CALENDAR_BUILDING')}</p>
                                    <CustomSelectField
                                        name="building_id"
                                        options={buildings_options}
                                        className="select text-sm w-full"
                                        isClearable={false}
                                        isSearchable={false}
                                        classNamePrefix="filter"
                                        isDisabled={isSubmitting}
                                    />
                                </div>
                            )}

                            {building && (
                                <div className=" mb-4 ">
                                    <p className="text-sm mb-2 text-gray-20 flex w-full">{t('CALENDAR_FLOOR')}</p>
                                    <CustomSelectField
                                        name="floor_id"
                                        options={floors_options}
                                        className="select text-sm w-full"
                                        isClearable={false}
                                        isSearchable={false}
                                        classNamePrefix="filter"
                                        isDisabled={isSubmitting}
                                    />
                                </div>
                            )}

                            {floor && (
                                <div className=" mb-4 ">
                                    <p className="text-sm mb-2 text-gray-20 flex w-full">{t('CALENDAR_ROOM')}</p>
                                    <CustomSelectField
                                        name="office_id"
                                        options={offices_options}
                                        className="select text-sm w-full"
                                        isClearable={false}
                                        isSearchable={false}
                                        classNamePrefix="filter"
                                        isDisabled={isSubmitting}
                                    />
                                </div>
                            )}

                            {office && (
                                <div className=" mb-4 ">
                                    <p className="text-sm mb-2 text-gray-20 flex w-full">{t('CALENDAR_SISTEM')}</p>
                                    <CustomSelectField
                                        name="system_id"
                                        options={systems_options}
                                        className="select text-sm w-full"
                                        isClearable={false}
                                        isSearchable={false}
                                        classNamePrefix="filter"
                                        isDisabled={isSubmitting}
                                    />
                                </div>
                            )}

                            {system && (
                                <div className=" mb-4 ">
                                    <p className="text-sm mb-2 text-gray-20 flex w-full">{t('CALENDAR_EQUIPMENT')}</p>
                                    <CustomSelectField
                                        name="device_id"
                                        options={devices_options}
                                        className="select text-sm w-full"
                                        isClearable={false}
                                        isSearchable={false}
                                        classNamePrefix="filter"
                                        isDisabled={isSubmitting}
                                    />
                                </div>
                            )}

                            {/* department */}
                            <div className="mb-4 last:mb-0">
                                <p className="text-sm mb-2 text-gray-20 flex w-full">
                                    {t('APPLICATIONS_FORM_DEPARTMENT')}
                                    {touched.department_id && errors.department_id ? (
                                        <span className="text-red-500 font-semibold ml-auto">{errors.department_id}</span>
                                    ) : (
                                        ''
                                    )}
                                </p>
                                <CustomSelectField
                                    name="department_id"
                                    options={departments_options}
                                    className="select text-sm w-full"
                                    isClearable={false}
                                    isSearchable={false}
                                    classNamePrefix="filter"
                                    isDisabled={isSubmitting}
                                />
                            </div>

                            {/* executor */}
                            <div className="mb-4 last:mb-0">
                                <p className="text-sm mb-2 text-gray-20 flex w-full">
                                    {t('APPLICATIONS_DETAILS_EXEC')}
                                    {touched.user_id && errors.user_id ? <span className="text-red-500 font-semibold ml-auto">{errors.user_id}</span> : ''}
                                </p>
                                <CustomSelectField
                                    name="user_id"
                                    options={executors_options}
                                    className="select text-sm w-full"
                                    isClearable={false}
                                    isSearchable={true}
                                    classNamePrefix="filter"
                                    isDisabled={isSubmitting}
                                />
                            </div>

                            {/* priority */}
                            {Number(values.department_id) === 6 ? (
                                <div className="mb-4 last:mb-0">
                                    <p className="text-sm mb-2 text-gray-20 flex w-full">
                                        {t('APPLICATIONS_FORM_PRIORITY')}
                                        {touched.apriority_id && errors.apriority_id ? (
                                            <span className="text-red-500 font-semibold ml-auto">{errors.apriority_id}</span>
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                    <CustomSelectField
                                        name="apriority"
                                        options={priority_options}
                                        className="select text-sm w-full"
                                        isClearable={false}
                                        isSearchable={false}
                                        classNamePrefix="filter"
                                        isDisabled={isSubmitting}
                                    />
                                </div>
                            ) : (
                                ''
                            )}

                            {/* expected_end_date */}
                            <div className="w-full ">
                                <p className="text-sm mb-2 text-gray-20 flex w-full">
                                    {t('APPLICATIONS_DETAILS_END_DATE')}
                                    {touched.date_end && errors.date_end ? <div className="text-red-500 font-semibold ml-auto">{errors.date_end}</div> : ''}
                                </p>
                                <CustomDatePickerField
                                    disabledKeyboardNavigation
                                    locale={lang === 'ru' ? ru : en}
                                    showTimeSelect
                                    timeFormat="p"
                                    timeIntervals={15}
                                    autoComplete="off"
                                    //
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    scrollableYearDropdown
                                    //
                                    timeCaption={t('APPLICATIONS_TIME')}
                                    name="expected_end_date"
                                    dateFormat="dd.MM.yyyy  hh:mm"
                                    placeholderText={t('ADM_PLANNER_PLACEHOLD_SELECT_DATE_PL')}
                                    minDate={new Date()}
                                    fixedHeight
                                    disabled={isSubmitting || !!application?.id}
                                />
                            </div>

                            {/* description */}
                            <div className="mt-4 last:mb-0">
                                <p className="text-sm mb-2 text-gray-20 flex w-full">
                                    {t('APPLICATIONS_DESCRIPTION')}
                                    {touched.description && errors.description ? (
                                        <span className="text-red-500 font-semibold ml-auto">{errors.description}</span>
                                    ) : (
                                        ''
                                    )}
                                </p>
                                <Field
                                    name="description"
                                    component="textarea"
                                    value={values.description}
                                    placeholder={t('APPLICATIONS_DETAILS_DESC')}
                                    className="box-border w-full h-32 text-sm resize-none rounded-1 border-gray-10 p-3"
                                    disabled={isSubmitting}
                                />
                            </div>

                            {/* picloader */}
                            <div className="mt-4 last:mb-0 w-full ">
                                <PicsLoader disabled={isSubmitting} files={files} setFiles={setFiles} />
                            </div>

                            <div className="flex items-center gap-3 w-full pt-4 mt-8 border-t border-t-gray-10">
                                <button
                                    type="submit"
                                    className=" w-28  bg-blue hover:bg-blue-500 transition-colors duration-200 text-white rounded-lg font-medium text-sm px-4 h-10"
                                    disabled={isSubmitting}
                                >
                                    <span className="text-center relative">
                                        {isSubmitting ? (
                                            <span className="absolute left-[-10px] bottom-[-10px] ">
                                                <Loader height={20} width={20} color="#ffffff" />
                                            </span>
                                        ) : (
                                            t('APPLICATIONS_CREATE')
                                        )}
                                    </span>
                                </button>

                                <button
                                    type="button"
                                    className="bg-white hover:bg-gray-100 transition-colors duration-200 text-gray-20 rounded-lg font-medium text-sm px-6 h-10"
                                    disabled={isSubmitting}
                                    onClick={closeHandler}
                                >
                                    {t('ADM_PLANNER_BTN_CANCEL')}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </SlidingPane>
        </>
    );
};
