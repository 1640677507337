import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import CustomScroll from 'react-custom-scroll';

import {
    auto_mode as auto_mode_state,
    getTasks,
    object as object_state,
    tasks as tasks_state,
    tasks_fetching as tasks_fetching_state,
} from '../../../redux/slices/hospitalitySlice';
import { Draggable } from '../../common/dnd/Draggable';
import { Droppable } from '../../common/dnd/Droppable';
import { Loader } from '../../common/Loader';
import { Task } from '../task/Task';

export const TasksSection = () => {
    const dispatch = useDispatch();

    const object = useSelector(object_state);
    const tasks_fetching = useSelector(tasks_fetching_state);
    const tasks = useSelector(tasks_state);
    const auto_mode = useSelector(auto_mode_state);

    useEffect(() => {
        if (object) {
            dispatch(getTasks());
        }
    }, [dispatch, object]);

    useEffect(() => {
        if (tasks.length) {
            ReactTooltip.rebuild();
        }
    }, [tasks]);

    return (
        <div className="w-52 bg-white shrink-0">
            <CustomScroll allowOuterScroll={true}>
                <Droppable id="default">
                    <div className="overflow-hidden">
                        {tasks_fetching ? (
                            <div className="w-full p-6 flex justify-center items-center">
                                <Loader height={30} width={30} />
                            </div>
                        ) : (
                            <div className="flex flex-col gap-4 bg-white px-3 py-3 box-border">
                                {tasks.filter(task => !task.user_id && task.is_filtered).length ? (
                                    tasks
                                        .filter(task => !task.user_id && task.is_filtered)
                                        .map(task => (
                                            <Draggable
                                                id={task.task_id}
                                                key={task.task_id}
                                                disabled={auto_mode || task.status_id === 3 || task.status_id === 8}
                                            >
                                                <Task type={task.clear_type_id} status={task.status_id} description={task.description}>
                                                    {task.room_str}
                                                </Task>
                                            </Draggable>
                                        ))
                                ) : (
                                    <div className="font-medium text-center text-gray mt-3">Нет задач</div>
                                )}
                            </div>
                        )}
                    </div>
                </Droppable>
            </CustomScroll>
        </div>
    );
};
