import { goToElement } from '../../../../api/functions';

export const LogsButton = ({ title, flag }) => {
    return (
        <button
            onClick={() => goToElement(flag)}
            type="button"
            className="bg-blue hover:bg-blue-500 transition-colors duration-200 text-white rounded-sm font-medium text-sm h-10 min-w-44 box-border px-4 flex justify-center items-center"
        >
            {title}
        </button>
    );
};
