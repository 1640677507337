import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
    create_bms_fetching as create_bms_fetching_state,
    set_document,
    update_bms_doc_list,
    updateBMSDoc,
    updateNodeData,
} from '../../../../../redux/slices/bmsSlice';
import { toast } from 'react-toastify';
import { toastOptions } from '../../../../../api/functions';
import { ButtonRectangular } from '../../../../common/btn/ButtonRectangular';

export const SubmitButton = ({ nodes, docId, infoFormRef }) => {
    const dispatch = useDispatch();
    const { venueId, systemId } = useParams();
    const navigate = useNavigate();

    const create_bms_fetching = useSelector(create_bms_fetching_state);

    const submitHandler = async () => {
        // Update info
        const infoFormError = await infoFormRef.current.validateForm();
        if (Object.keys(infoFormError).length) {
            for (const key in infoFormError) {
                toast.error(infoFormError[key], toastOptions);
            }

            return null;
        } else {
            const data = {
                id: docId,
                ...infoFormRef.current.values,
            };

            const result = await dispatch(updateBMSDoc({ data }));

            if (result.error) {
                toast.error(result.msg, toastOptions);

                return null;
            } else {
                dispatch(set_document(data));
                dispatch(update_bms_doc_list(data));
            }
        }

        // Update nodes
        if (!nodes.length) {
            toast.error('Не выбрано ни одно оборудование', toastOptions);

            return null;
        } else {
            const resArr = [];
            nodes.forEach(node => {
                resArr.push({
                    doc_id: docId,
                    cn_id: node.cn_id,
                    ...node,
                    data: JSON.stringify({ ...node.data, position: node.position }),
                    position: JSON.stringify(node.position),
                });
            });

            const result = await dispatch(updateNodeData({ nodesArr: resArr }));

            if (result.error) {
                toast.error('Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.', toastOptions);

                return null;
            }
        }

        toast.info('BMS система успешно обновлена', toastOptions);

        navigate(`/bms/venues/${venueId}/system/${systemId}/documents/${docId}`);
    };

    return (
        <ButtonRectangular onClick={submitHandler} loading={create_bms_fetching} className="bg-blue hover:bg-blue-500 border-blue">
            Сохранить
        </ButtonRectangular>
    );
};
