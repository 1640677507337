import { root } from '../config';

export const bmsAPI = Object.freeze({
    // Получение информации о пользователе
    getUserInfo: {
        fetch: (username, auth_key_calendar) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            return fetch(`${root}?r=api/bms/my-info`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список документов
    getBMSDocList: {
        fetch: (username, auth_key_calendar, lang, object_id, direction_id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('object_id', object_id);
            formData.append('direction_id', direction_id);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/bms/doc-list`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список нод, размещенных в рамках документа
    getBMSDocNodes: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/bms/get-doc-nodes`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить исторические данные (тренды)
    getBMSHistory: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/bms/get-history`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить системные сообщения об ошибках
    getBMSAlerts: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/bms/get-alerts`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список объектов системы
    getBMSVenues: {
        fetch: (username, auth_key_calendar) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            return fetch(`${root}?r=api/bms/get-venues`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список систем
    getBMSDirections: {
        fetch: (username, auth_key_calendar) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            return fetch(`${root}?r=api/bms/get-directions`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список нод, которые могут быть использованы при размещении в документе
    getBMSNodes: {
        fetch: (username, auth_key_calendar, docId) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('docId', docId);

            return fetch(`${root}?r=api/bms/get-nodes`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список элементов библиотеки для визуального построения панели инструментов
    getBMSLib: {
        fetch: (username, auth_key_calendar) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            return fetch(`${root}?r=api/bms/get-lib`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Создать документ в рамках рабочего пространства
    createBMSDoc: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/bms/doc-create`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Редактировать документ в рамках рабочего пространства
    updateBMSDoc: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/bms/doc-update`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Добавить ноду (оборудование) к документу
    addBMSDocNode: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/bms/doc-add-node`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Обновить данные ноды
    updateBMSDocNodeData: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/bms/doc-update-node`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Удалить ноду из документа
    removeBMSDocNode: {
        fetch: (username, auth_key_calendar, cn_id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('cn_id', cn_id);

            return fetch(`${root}?r=api/bms/doc-remove-node`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список созданных пользователем объектов
    getBMSUserVenues: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/bms/get-objects`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Добавить объект
    addBMSVenue: {
        fetch: (username, auth_key_calendar, data, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('servers', JSON.stringify(data.servers));
            formData.append('title', data.title);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/bms/create-object`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Редактировать объект
    editBMSVenue: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/bms/update-object`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Удалить объект
    removeBMSVenue: {
        fetch: (username, auth_key_calendar, id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('id', id);

            return fetch(`${root}?r=api/bms/delete-object`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список серверов
    getBMSServers: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('lang', lang);
            formData.append('auth_key_calendar', auth_key_calendar);

            return fetch(`${root}?r=api/bms/get-servers`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Добавить сервер
    addBMSServer: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('title', data.title);
            formData.append('data', JSON.stringify(data.data));
            formData.append('is_enabled', (data.is_enabled = data.is_enabled ? 1 : 0));

            return fetch(`${root}?r=api/bms/create-server`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Удалить сервер
    removeBMSServer: {
        fetch: (username, auth_key_calendar, id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('id', id);

            return fetch(`${root}?r=api/bms/delete-server`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Редактировать сервер
    editBMSServer: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('title', data.title);
            formData.append('data', JSON.stringify(data.data));
            formData.append('id', data.id);
            formData.append('is_enabled', data.is_enabled ? 1 : 0);

            return fetch(`${root}?r=api/bms/update-server`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить системы объекта
    getBMSSystemOfVenue: {
        fetch: (username, auth_key_calendar, venueId, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('id', venueId);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/bms/get-object-directions`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Добавить систему в объект
    addBMSSystemInVenue: {
        fetch: (username, auth_key_calendar, object_id, direction_id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('object_id', object_id);
            formData.append('direction_id', direction_id);

            return fetch(`${root}?r=api/bms/add-direction-to-object`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Удалить систему из объекта
    removeBMSSystemInVenue: {
        fetch: (username, auth_key_calendar, object_id, direction_id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('object_id', object_id);
            formData.append('direction_id', direction_id);

            return fetch(`${root}?r=api/bms/remove-direction-from-object`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Удалить документ из системы
    removeBMSDoc: {
        fetch: (username, auth_key_calendar, id) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('id', id);

            return fetch(`${root}?r=api/bms/doc-delete`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Пинг состояния серверов
    getBMSServersStats: {
        fetch: (username, auth_key_calendar) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            return fetch(`${root}?r=api/bms/get-servers-states`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Передача выбранного значения контроллера
    setBMSControlValue: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/bms/set-value`, {
                method: 'POST',
                body: formData,
            });
        },
    },
});
