import { useRef, useEffect } from 'react';
import { Carousel as NativeCarousel } from '@fancyapps/ui/dist/carousel.esm.js';

import '@fancyapps/ui/dist/carousel.css';

function ReactCarousel(props) {
    const wrapper = useRef(null);

    useEffect(() => {
        const items = props.items || [];
        const opts = props.options || {};
        const content = props.content || [];

        opts.slides = [...content].map((file, idx) => {
            return {
                html: `<div class=carousel_file key=file${idx} >
                    <a
                            href=${file}
                            target="_blank"
                            rel="noreferrer"
                           
                            
                        >
                           <div class=file_box>
                            <svg width="40" height="40" fill="#757575" viewBox="0 0 512 512">
                                <path d="M459.36,100.64l-96-96C360.341,1.645,356.253-0.024,352,0H96c-26.51,0-48,21.49-48,48v416c0,26.51,21.49,48,48,48h320     c26.51,0,48-21.49,48-48V112C464.025,107.747,462.355,103.66,459.36,100.64z M432,464c0,8.837-7.163,16-16,16H96     c-8.837,0-16-7.163-16-16V48c0-8.837,7.163-16,16-16h240v64c0,17.673,14.327,32,32,32h64V464z" />
                                <rect x="368" y="384" width="32" height="32" />
                                <rect x="112" y="224" width="288" height="32" />
                                <rect x="112" y="304" width="288" height="32" />
                                <rect x="112" y="384" width="224" height="32" />
                            </svg>
                            <div class=fileName>${file.split('.').reverse()[0]}</div>
                           </div>
                        </a> 
                </div>`,
            };
        });

        const instance = new NativeCarousel(wrapper.current, opts);

        return () => {
            instance.destroy();
        };
    }, []);

    return <div style={{ width: '100%' }} className={`carousel carousel__file  ${props.class || ''}`} ref={wrapper}></div>;
}

export default ReactCarousel;
