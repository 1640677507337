import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../api/functions';
import { workers as workers_state, moveCleaners } from '../../../redux/slices/hospitalitySlice';
import { CleanersList } from '../cleanersList/CleanersList';
import { SupervisorsSection } from '../supervisorsSection/SupervisorsSection';
import { Worker } from '../worker/Worker';

export const MainSectionCleaners = () => {
    const dispatch = useDispatch();

    const workers = useSelector(workers_state);

    const [activeWorker, setActiveWorker] = useState(null);

    const handleDragEnd = async event => {
        if (event.over) {
            const status = await dispatch(
                moveCleaners({
                    data: {
                        user_id: event.active.id,
                        supervisor_id: event.over.id === 'default' ? null : event.over.id,
                    },
                })
            );
            if (!status) {
                toast.error('Ошибка при сохранении. Пожалуйста, попробуйте еще раз', toastOptions);
            }
        }
        setActiveWorker(null);
    };

    const handleDragStart = event => {
        setActiveWorker(workers.find(worker => worker.id === event.active.id));
    };

    return (
        <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
            <div className="h-full w-full border-l border-l-gray-10 flex flex-row overflow-hidden">
                <CleanersList />
                <SupervisorsSection />
            </div>

            <DragOverlay dropAnimation={null}>{activeWorker ? <Worker>{activeWorker.name}</Worker> : null}</DragOverlay>
        </DndContext>
    );
};
