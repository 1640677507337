import { LibraryItem } from './LibraryItem';

export const LibraryGroup = ({ group, itemLibraryHandler }) => {
    return (
        <div className="mb-6">
            <p className="mb-3 uppercase text-xs text-black font-semibold">{group.title}</p>
            <div className="flex flex-row flex-wrap gap-4">
                {group.items.length ? (
                    group.items.map(item => <LibraryItem key={item.id} item={item} onClick={itemLibraryHandler} />)
                ) : (
                    <p>Нет элементов в группе</p>
                )}
            </div>
        </div>
    );
};
