import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listenForOutsideClick } from '../../../api/functions';
import {
    getWorkers,
    object as object_state,
    workers as workers_state,
    workers_fetching as workers_fetching_state,
} from '../../../redux/slices/hospitalitySlice';
import { Loader } from '../../common/Loader';
import { Cleaner } from './Cleaner';

export const CleanersSection = () => {
    const dispatch = useDispatch();

    const object = useSelector(object_state);
    const workers = useSelector(workers_state);
    const workers_fetching = useSelector(workers_fetching_state);

    const [isOpenCleanerMenu, setIsOpenCleanerMenu] = useState(null);
    const [listeningCleanerMenu, setListeningCleanerMenu] = useState(false);

    const activeCleanerMenuRef = useRef(null);

    useEffect(listenForOutsideClick(listeningCleanerMenu, setListeningCleanerMenu, activeCleanerMenuRef, () => setIsOpenCleanerMenu(null)));

    useEffect(() => {
        if (object) {
            dispatch(getWorkers());
        }
    }, [object, dispatch]);

    return (
        <div className="w-full box-border p-6 overflow-y-auto">
            {workers_fetching ? (
                <div className="w-full flex justify-center items-center">
                    <Loader height={30} width={30} />
                </div>
            ) : (
                <div className="flex flex-row flex-wrap gap-6">
                    {workers.filter(worker => worker.state && worker.is_filtered).length ? (
                        workers
                            .filter(worker => worker.state && worker.is_filtered)
                            .map(worker => (
                                <Cleaner
                                    key={worker.id}
                                    id={worker.id}
                                    name={worker.name}
                                    isOpenMenu={isOpenCleanerMenu}
                                    setIsOpenMenu={setIsOpenCleanerMenu}
                                    activeCleanerMenuRef={activeCleanerMenuRef}
                                />
                            ))
                    ) : (
                        <p className="font-medium text-gray">Нет назначенных клинеров</p>
                    )}
                </div>
            )}
        </div>
    );
};
