import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import {
    bms_doc_list as bms_doc_list_state,
    bms_detail_nodes_fetching as bms_detail_nodes_fetching_state,
    getBMSDocNodes,
    getBMSDocNodesTimeout,
    documentState as documentState_state,
    getBMSDocList,
    set_document,
} from '../../../../redux/slices/bmsSlice';

import { Loader } from '../../../common/Loader';
import { EventLog } from './Tables/EventLog';
import { TrendsLog } from './Tables/TrendsLog';
import { LogsButton } from './LogsButton';
import { VenueName } from '../../systems/VenueName';
import { SystemName } from '../../systems/SystemName';
import { ControlsSection } from './ControlsSection';
import { ElementsSection } from './ElementsSection';
import { EditButton } from './EditButton';
import { useCheckRole } from '../../../../hooks/useCheckRole';

export const Detail = () => {
    const dispatch = useDispatch();
    const { documentId, venueId, systemId } = useParams();

    const bms_doc_list = useSelector(bms_doc_list_state);
    const bms_detail_nodes_fetching = useSelector(bms_detail_nodes_fetching_state);
    const documentState = useSelector(documentState_state);

    const [bmsDetail, setBmsDetail] = useState({});

    const containerSize = useRef({
        width: 1920,
        height: 1080,
    });

    const isDocumentNodesView = useCheckRole('bms', 'bms.document_nodes.view');
    const isDocumentControlsView = useCheckRole('bms', 'bms.control_panel.view');
    const isDocumentEdit = useCheckRole('bms', 'bms.document.edit');
    const isJournalView = useCheckRole('bms', 'bms.journal.view');
    const isTrendsView = useCheckRole('bms', 'bms.trends.view');

    useEffect(() => {
        (async () => {
            if (bms_doc_list.length === 0) {
                await dispatch(getBMSDocList({ object_id: venueId, direction_id: systemId }));
            }
        })();
    }, []);

    useEffect(() => {
        const bms = bms_doc_list.find(el => el.id === +documentId);
        if (bms) {
            setBmsDetail(bms);
            dispatch(set_document(bms));
        }
    }, [bms_doc_list]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [documentState.nodes]);

    useEffect(() => {
        if (bmsDetail && bmsDetail.hasOwnProperty('id')) {
            dispatch(getBMSDocNodes({ data: { doc_id: bmsDetail.id } }));
            const interval = setInterval(async () => {
                dispatch(getBMSDocNodesTimeout({ data: { doc_id: bmsDetail.id } }));
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [bmsDetail]);

    if (bmsDetail && bmsDetail.hasOwnProperty('id')) {
        return (
            <>
                <div>
                    <div className="uppercase text-xl text-black font-semibold mb-4">
                        {bmsDetail.title}
                        {isDocumentEdit && <EditButton id={bmsDetail.id} />}
                    </div>
                    {bmsDetail.description && <div className="text-md text-gray mb-4">{bmsDetail.description}</div>}
                    <VenueName />
                    <SystemName />

                    <div className="flex flex-row gap-4 my-8">
                        {isJournalView && <LogsButton title="Журнал событий" flag="alerts" />}
                        {isTrendsView && <LogsButton title="Тренды" flag="trends" />}
                    </div>

                    <div className="mb-8">
                        {bms_detail_nodes_fetching ? (
                            <div className="flex w-full justify-center">
                                <Loader />
                            </div>
                        ) : (
                            <>
                                {documentState.nodes?.length || documentState.controls?.length.length ? (
                                    <div className="w-full overflow-x-auto overflow-y-hidden">
                                        {isDocumentControlsView && <ControlsSection containerSize={containerSize} docId={bmsDetail.id} />}
                                        {isDocumentNodesView && <ElementsSection containerSize={containerSize} />}
                                    </div>
                                ) : (
                                    <p>Нет оборудования</p>
                                )}
                            </>
                        )}
                    </div>

                    {isJournalView && <EventLog bmsDetail={bmsDetail} />}
                    {isTrendsView && <TrendsLog bmsDetail={bmsDetail} />}
                </div>
                <ReactTooltip textColor="#FCFCFC" backgroundColor="#707183E5" effect="solid" className="!rounded-md !py-2 !px-2.5 max-w-prose" />
            </>
        );
    }
};
