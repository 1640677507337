import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SimpleSelect } from '../../../common/widgets/SimpleSelect';

import { setBMSControlValues, documentState as documentState_state } from '../../../../redux/slices/bmsSlice';
import { url } from '../../../../api/config';
import { useCheckRole } from '../../../../hooks/useCheckRole';

export const ControlsSection = ({ containerSize, docId }) => {
    const dispatch = useDispatch();

    const documentState = useSelector(documentState_state);

    const [controlsSelectsValues, setControlsSelectsValues] = useState([]);

    const isControlPanelControl = useCheckRole('bms', 'bms.control_panel.control');

    useEffect(() => {
        if (documentState.controls) {
            const valuesArr = documentState.controls.map(control => {
                const correctValue = control.data.values.find(value => value.value === control.value);

                return { cn_id: control.cn_id, values: correctValue ? correctValue : control.data.values[0] };
            });
            setControlsSelectsValues(valuesArr);
        }
    }, [documentState.controls]);

    const handlerBtnClick = control => {
        const data = {
            flag: 'btn',
            cn_id: control.cn_id,
            value: control.data.values[0].value,
            doc_id: docId,
        };
        dispatch(setBMSControlValues({ data }));
    };
    const handlerSetControlValue = (control, val) => {
        const data = {
            flag: 'list',
            cn_id: control.cn_id,
            value: JSON.stringify(val),
            doc_id: docId,
        };
        setControlsSelectsValues(() => controlsSelectsValues.map(el => (el.cn_id === control.cn_id ? { ...el, values: val } : el)));

        dispatch(setBMSControlValues({ data }));
    };

    return (
        <div
            className="border mb-1 p-2.5 flex gap-4 flex-wrap place-content-start bg-red-200"
            style={{
                maxWidth: containerSize.current.width,
                maxHeight: containerSize.current.height / 2,
                minWidth: '720px',
                minHeight: '100px',
                width: '100%',
            }}
        >
            {documentState.controls?.length
                ? documentState.controls.map(control => {
                      const data = control.data;
                      const lib_data = control.lib_data;
                      return (
                          <div key={control.cn_id} className="flex items-center gap-2">
                              <span className="text-xs">{control.name}</span>

                              {lib_data.subtype === 'list' && (
                                  <SimpleSelect
                                      value={controlsSelectsValues.length !== 0 ? controlsSelectsValues.find(el => el.cn_id === control.cn_id)?.values : ''}
                                      options={data.values}
                                      callback={val => handlerSetControlValue(control, val)}
                                      isClearable={false}
                                      className="w-36 text-xs controlSelect"
                                      placeholder="Выберете значение"
                                      isDisabled={!isControlPanelControl}
                                  />
                              )}
                              {lib_data.subtype === 'button' && (
                                  <button
                                      className="cursor-pointer hover:scale-110"
                                      type="button"
                                      disabled={!isControlPanelControl}
                                      onClick={() => handlerBtnClick(control)}
                                  >
                                      <img src={`${url}${lib_data.default_pic}`} alt={control.title} className="w-6 h-6 " />
                                  </button>
                              )}
                          </div>
                      );
                  })
                : ''}
        </div>
    );
};
