import { useParams } from 'react-router-dom';

import { SubHeader } from '../../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../../components/common/subheader/SubHeaderLeft';
import { Title } from '../../components/common/subheader/Title';
import { ContentContainer } from '../../components/common/ContentContainer';
import { BackButton } from '../../components/common/subheader/BackButton';
import { DocumentEdit } from '../../components/bms/document/edit/DocumentEdit';
import { useCheckRole } from '../../hooks/useCheckRole';
import { NoAccessPage } from '../../components/common/noAccess/NoAccessPage';

export const BMSEditDocPage = () => {
    const { venueId, systemId, documentId } = useParams();

    const isDocumentEdit = useCheckRole('bms', 'bms.document.edit');

    if (!isDocumentEdit) {
        return <NoAccessPage />;
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <BackButton link={`/bms/venues/${venueId}/system/${systemId}/documents/${documentId}`} />
                    <Title>Редактирование документа</Title>
                </SubHeaderLeft>
            </SubHeader>
            <ContentContainer>
                <DocumentEdit />
            </ContentContainer>
        </>
    );
};
