import { useState } from 'react';
import { t } from 'i18next';

import { book } from '../../navigation/book';
import { useCheckRole } from '../../hooks/useCheckRole';

import { SubHeader } from '../../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../../components/common/subheader/SubHeaderLeft';
import { SubHeaderRight } from '../../components/common/subheader/SubHeaderRight';
import { ContentContainer } from '../../components/common/ContentContainer';
import { Title } from '../../components/common/subheader/Title';
import { TableServers } from '../../components/bms/servers/TableServers';
import { CreateServerButton } from '../../components/bms/servers/CreateServerButton';
import { CreateServerPopup } from '../../components/bms/servers/createPopup/CreateServerPopup';
import { EditServerPopup } from '../../components/bms/servers/editPopup/EditServerPopup';
import { NoAccessPage } from '../../components/common/noAccess/NoAccessPage';
import { BackButton } from '../../components/common/subheader/BackButton';

export const BMSServersPage = () => {
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [editPopupIsOpen, setEditPopupIsOpen] = useState(false);
    const [serverId, setServerId] = useState(null);

    const isServersView = useCheckRole('bms', 'bms.servers.view');
    const isServersAdd = useCheckRole('bms', 'bms.servers.add');
    const isServersEdit = useCheckRole('bms', 'bms.servers.edit');

    if (!isServersView) {
        return <NoAccessPage />;
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <BackButton link="/bms" />
                    <Title>{t(book.bms_server.key)}</Title>
                </SubHeaderLeft>

                <SubHeaderRight>{isServersAdd && <CreateServerButton setPopupIsOpen={setPopupIsOpen} />}</SubHeaderRight>
            </SubHeader>

            <ContentContainer>
                <TableServers setServerId={setServerId} setEditPopupIsOpen={setEditPopupIsOpen} />
            </ContentContainer>

            {popupIsOpen && isServersAdd && <CreateServerPopup isOpen={popupIsOpen} setIsOpen={setPopupIsOpen} />}

            {editPopupIsOpen && isServersEdit && (
                <EditServerPopup isOpenEdit={editPopupIsOpen} setIsOpenEdit={setEditPopupIsOpen} serverId={serverId} setServerId={setServerId} />
            )}
        </>
    );
};
