const getEndingDays = end => {
    const dateEnd = end.split(' ')[0].split('.');
    const dateEndTime = end.split(' ')[1].split(':');
    const date1 = new Date();
    const date2 = new Date(dateEnd[2], dateEnd[1] - 1, dateEnd[0], dateEndTime[0], dateEndTime[1]);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = (diffInTime / oneDay).toFixed(2);

    return diffInDays;
};

export default getEndingDays;
