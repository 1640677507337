import { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { createPortal } from 'react-dom';

export const PopupFixed = ({ children, isOpen, setIsOpen, buttonRef, className }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (buttonRef.current && ref.current && isOpen) {
            ref.current.style.top = `${buttonRef.current.getBoundingClientRect().top + buttonRef.current.getBoundingClientRect().height}px`;

            ref.current.style.left = `${
                buttonRef.current.getBoundingClientRect().left - ref.current.offsetWidth + buttonRef.current.getBoundingClientRect().width > 0
                    ? buttonRef.current.getBoundingClientRect().left - ref.current.offsetWidth + buttonRef.current.getBoundingClientRect().width
                    : 14
            }px`;
        }
    }, [ref, buttonRef, isOpen]);

    const classes = twMerge(
        `fixed bg-white mt-4 z-50 w-[500px] max-w-full box-border rounded-xl flex flex-col p-4 shadow-2xl transition-all duration-500 ease-in-out border border-gray-10 ${
            className ? className : ''
        }`
    );

    const closeHandler = () => {
        setIsOpen(false);
    };

    if (!isOpen) {
        return null;
    }

    return createPortal(
        <>
            <div className="fixed inset-0 bg-slate-900 bg-opacity-30 z-40 transition-opacity duration-500 opacity-100" onClick={closeHandler}></div>
            <div className={classes} ref={ref}>
                <button
                    className="absolute right-1 top-1 w-7 h-7 p-0 rounded-full bg-transparent hover:bg-gray-100 flex items-center justify-center"
                    onClick={closeHandler}
                >
                    <svg className="w-5 h-5 fill-current" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"></path>
                    </svg>
                </button>
                {children}
            </div>
        </>,
        document.querySelector('#modal-root')
    );
};
