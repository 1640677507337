import { root } from '../config';
const timeInMs = Date.now();

export const calendarAPI = Object.freeze({
    objectList: {
        fetch: (username, auth_key_calendar, filter, lang) => {
            const formData = new FormData();

            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('venue_id', filter.venue.value);
            formData.append('building_id', filter.building.value);
            formData.append('lang', lang);
            if (filter.floor?.value) {
                formData.append('floor_id', filter.floor.value);
            }
            if (filter.office?.value) {
                formData.append('office_id', filter.office.value);
            }
            if (filter.direction?.value) {
                formData.append('direction_id', filter.direction.value);
            }
            if (filter.user?.value) {
                formData.append('user_id', filter.user.value);
            }
            if (filter.year) {
                formData.append('year', filter.year);
            }
            if (filter.month) {
                formData.append('month', filter.month);
            }
            if (filter.task_category_id) {
                formData.append('task_category_id', filter.task_category_id);
            }
            if (filter.calendar_type) {
                formData.append('calendar_type_id', filter.calendar_type);
            }
            if (filter.search) {
                formData.append('search', filter.search);
            }

            return fetch(
                `${root}?r=api/calendar/user-calendar-list&app_id=0&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    venueList: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            return fetch(
                `${root}?r=api/calendar/user-calendar-venue-list&app_id=0&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    buildingList: {
        fetch: (username, auth_key_calendar, venue_id, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('venue_id', venue_id);
            formData.append('lang', lang);

            return fetch(
                `${root}?r=api/calendar/user-calendar-building-list&app_id=0&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    floorList: {
        fetch: (username, auth_key_calendar, building_id, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('building_id', building_id);
            formData.append('lang', lang);

            return fetch(
                `${root}?r=api/calendar/user-calendar-floor-list&app_id=0&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    officeList: {
        fetch: (username, auth_key_calendar, building_id, floor_id, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('building_id', building_id);
            formData.append('lang', lang);

            if (floor_id) {
                formData.append('floor_id', floor_id);
            }

            return fetch(
                `${root}?r=api/calendar/user-calendar-office-list&app_id=0&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    directionList: {
        fetch: (username, auth_key_calendar, building_id, floor_id, office_id, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('building_id', building_id);
            formData.append('lang', lang);

            if (floor_id) {
                formData.append('floor_id', floor_id);
            }
            if (office_id) {
                formData.append('office_id', office_id);
            }

            return fetch(
                `${root}?r=api/calendar/user-calendar-direction-list&app_id=0&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    userList: {
        fetch: (username, auth_key_calendar, venue_id, building_id, floor_id, office_id, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('venue_id', venue_id);
            formData.append('building_id', building_id);
            formData.append('lang', lang);

            if (floor_id) {
                formData.append('floor_id', floor_id);
            }
            if (office_id) {
                formData.append('office_id', office_id);
            }

            return fetch(
                `${root}?r=api/calendar/user-calendar-user-list&app_id=0&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    task: {
        fetch: (username, auth_key_calendar, params, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('detail_id', params.detail_id);
            formData.append('lang', lang);

            if (params.date_work) {
                formData.append('date_work', params.date_work);
            }
            if (params.start_week) {
                formData.append('start_week', params.start_week);
            }
            if (params.filter_year) {
                formData.append('filter_year', params.filter_year);
            }

            return fetch(
                `${root}?r=api/calendar/user-calendar-work-detail&app_id=0&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    editTask: {
        fetch: (username, auth_key_calendar, params, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in params) {
                formData.append(item, params[item]);
            }

            return fetch(
                `${root}?r=api/calendar/user-calendar-change-work&app_id=0&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },

    printExcel: {
        fetch: (username, auth_key_calendar, filter, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('venue_id', filter.venue.value);
            formData.append('building_id', filter.building.value);
            formData.append('lang', lang);

            if (filter.floor?.value) {
                formData.append('floor_id', filter.floor.value);
            }
            if (filter.office?.value) {
                formData.append('office_id', filter.office.value);
            }
            if (filter.direction?.value) {
                formData.append('direction_id', filter.direction.value);
            }
            if (filter.user?.value) {
                formData.append('user_id', filter.user.value);
            }
            if (filter.year) {
                formData.append('year', filter.year);
            }
            if (filter.month) {
                formData.append('month', filter.month);
            }
            if (filter.task_category_id) {
                formData.append('task_category_id', filter.task_category_id);
            }
            if (filter.calendar_type) {
                formData.append('calendar_type_id', filter.calendar_type);
            }
            if (filter.search) {
                formData.append('search', filter.search);
            }
            if (filter.without_empty) {
                formData.append('without_empty', filter.without_empty);
            }

            return fetch(
                `${root}?r=api/calendar/user-calendar-get-excel&app_id=0&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
        },
    },
});
