import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Popup } from '../../../common/popup/Popup';
import { CreateServerPopupButton } from '../createPopup/CreateServerPopupButton';
import { OPCFields } from '../OPCFields';
import { VPNFields } from '../VPNFields';
import { addBMSServer } from '../../../../redux/slices/bmsSlice';

const initValue = {
    title: '',
    is_enabled: '',
    data: {
        port: '',
        server: '',
        password: '',
        username: '',
        vpn_port: '',
        vpn_server: '',
        vpn_password: '',
        vpn_username: '',
    },
};

export const CreateServerPopup = ({ isOpen, setIsOpen }) => {
    const dispatch = useDispatch();

    const [server, setServer] = useState(initValue);

    useEffect(() => {
        if (!isOpen) {
            setServer(initValue);
        }
    }, [isOpen]);

    const handleCreateServer = () => {
        dispatch(addBMSServer({ data: server }));
        setIsOpen(false);
        setServer(initValue);
    };
    return (
        <Popup isOpen={isOpen} setIsOpen={setIsOpen} className="w-[700px] h-[430px]">
            <div>
                <div className="flex flex-col items-center mb-4">
                    <label className="block mb-2">Название сервера</label>
                    <input
                        onChange={e => setServer({ ...server, title: e.target.value })}
                        value={server.title}
                        placeholder="введите название сервера"
                        type="text"
                        className="w-96 h-10 border-gray-70 hover:border-gray-30 text-sm px-2 mr-2"
                    />
                </div>
                <div className="flex">
                    <div className="w-1/2 flex flex-col items-center">
                        <OPCFields server={server} setServer={setServer} />
                    </div>
                    <div className="w-1/2 flex flex-col items-center">
                        <VPNFields server={server} setServer={setServer} />
                    </div>
                </div>
            </div>
            <CreateServerPopupButton
                onClick={handleCreateServer}
                disabled={server.title.length === 0 || server.data.port.length === 0 || server.data.server.length === 0}
            />
        </Popup>
    );
};
