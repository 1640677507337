import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from 'react-router-dom';

import {
    getHospitalityObjects,
    objects_fetching as objects_fetching_state,
    objects as objects_state,
    workers as workers_state,
    filter_for_tasks,
} from '../../redux/slices/hospitalitySlice';
import { Loader } from '../../components/common/Loader';
import { Header } from '../../components/hospitality/headerCleaners/Header';
import { SubHeader } from '../../components/hospitality/subheaderCleaners/SubHeader';
import { MainSectionCleaners } from '../../components/hospitality/mainSectionCleaners/MainSectionCleaners';
import { SupervisorsPane } from '../../components/hospitality/supervisorsPane/SupervisorsPane';
import { TaskPane } from '../../components/hospitality/taskPane/TaskPane';

export const CleanersPage = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const objects_fetching = useSelector(objects_fetching_state);
    const objects = useSelector(objects_state);
    const workers = useSelector(workers_state);
    const filters = useSelector(filter_for_tasks);

    const [supervisorPaneIsOpen, setSupervisorPaneIsOpen] = useState(false);
    const [taskPaneIsOpen, setTaskPaneIsOpen] = useState(false);

    useEffect(() => {
        if (objects.length === 0) {
            dispatch(getHospitalityObjects());
        }
    }, [dispatch]);

    useEffect(() => {
        if (!workers.length && !filters.is_touch) {
            return navigate('/hospitality');
        }
    }, [workers]);

    return (
        <>
            <Header setTaskPaneIsOpen={setTaskPaneIsOpen} />
            <SubHeader setSupervisorPaneIsOpen={setSupervisorPaneIsOpen} />
            {objects_fetching ? (
                <div className="w-full h-full flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <MainSectionCleaners />
            )}
            <SupervisorsPane isOpen={supervisorPaneIsOpen} setIsOpen={setSupervisorPaneIsOpen} />
            <TaskPane isOpen={taskPaneIsOpen} setIsOpen={setTaskPaneIsOpen} />
            <ReactTooltip html={true} type="dark" place="top" effect="solid" delayShow={500} className="max-w-xs" />
        </>
    );
};
