import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSupervisorsAtWork, workers as workers_state, supervisors as supervisors_state, actResetSupervisors } from '../../../redux/slices/hospitalitySlice';
import { Droppable } from '../../common/dnd/Droppable';
import { SupervisorMenuPopup } from './SupervisorMenuPopup';
import { SupervisorTasksList } from './SupervisorTasksList';
import { SupervisorMenuButton } from './SupervisorMenuButton';

export const Supervisor = ({ id, name, isOpenMenu, setIsOpenMenu, activeSupervisorMenuRef }) => {
    const dispatch = useDispatch();

    const workers = useSelector(workers_state);
    const supervisors = useSelector(supervisors_state);

    const supervisorCleaners = useMemo(() => workers.filter(worker => worker.supervisor_id === id), [workers, id]);
    const [isOpenTasks, setIsOpenTasks] = useState(false);

    const deleteSupervisorHandler = id => {
        const user_ids = supervisors.filter(supervisor => supervisor.state && supervisor.id !== id).map(supervisor => supervisor.id);

        dispatch(setSupervisorsAtWork({ user_ids: JSON.stringify(user_ids), supervisor_id: id }));
        setIsOpenMenu(null);
    };

    const deleteTasksHandler = id => {
        dispatch(actResetSupervisors({ supervisor_id: id }));

        setIsOpenMenu(null);
    };

    return (
        <div className={`w-56 bg-white rounded relative box-border ${isOpenTasks ? 'shadow-3xl h-fit' : 'h-20'} ${isOpenMenu === id ? 'z-20' : 'z-10'}`}>
            <Droppable id={id}>
                <div className="w-full pl-3 flex flex-col">
                    <div className="flex flex-row items-center w-full relative border-b border-b-gray-10 h-10 pr-3">
                        <div className="text-sm font-medium text-gray-20 line-clamp-1">{name}</div>

                        <SupervisorMenuButton
                            onClick={() => setIsOpenMenu(isOpenMenu !== id ? id : null)}
                            buttonRef={isOpenMenu === id ? activeSupervisorMenuRef : null}
                        />

                        <SupervisorMenuPopup isOpen={isOpenMenu === id}>
                            <button className="text-left px-3 py-2 hover:text-blue" onClick={() => deleteSupervisorHandler(id)}>
                                Удалить пользователя
                            </button>
                            <button className="text-left px-3 py-2 hover:text-blue" onClick={() => deleteTasksHandler(id)}>
                                Очистить клинёров
                            </button>
                        </SupervisorMenuPopup>
                    </div>
                    <button className="w-full h-10 flex flex-row items-center text-blue" onClick={() => setIsOpenTasks(!isOpenTasks)}>
                        <div className="text-sm">Клинёров: {supervisorCleaners.length}</div>
                        <div className="ml-auto mr-3">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="fill-transparent stroke-current">
                                <path d="M6 9L12 15L18 9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </button>
                </div>

                <SupervisorTasksList isOpen={isOpenTasks} setIsOpen={setIsOpenTasks} cleaners={supervisorCleaners} />
            </Droppable>
        </div>
    );
};
