import { Checkbox } from '../../common/widgets/Checkbox';
export const OPCFields = ({ server, setServer }) => {
    return (
        <>
            <p>OPC сервер</p>
            <input
                onChange={e => setServer({ ...server, data: { ...server.data, server: e.target.value } })}
                value={server.data.server}
                placeholder="ip адрес"
                className="w-56 h-8 border-gray-70 hover:border-gray-30 text-sm px-2 mb-2"
                type="text"
            />
            <input
                onChange={e => setServer({ ...server, data: { ...server.data, port: e.target.value } })}
                value={server.data.port}
                placeholder="порт"
                className="w-56 h-8 border-gray-70 hover:border-gray-30 text-sm px-2 mb-2"
                type="text"
            />
            <input
                onChange={e => setServer({ ...server, data: { ...server.data, username: e.target.value } })}
                value={server.data.username}
                placeholder="логин"
                className="w-56 h-8 border-gray-70 hover:border-gray-30 text-sm px-2 mb-2"
                type="text"
            />
            <input
                onChange={e => setServer({ ...server, data: { ...server.data, password: e.target.value } })}
                value={server.data.password}
                placeholder="пароль"
                className="w-56 h-8 border-gray-70 hover:border-gray-30 text-sm px-2 mb-2"
                type="text"
            />
            {server && (
                <Checkbox
                    name="is_enabled"
                    title="Активность"
                    value={server?.is_enabled}
                    checked={server?.is_enabled}
                    onClick={e => setServer({ ...server, is_enabled: e.target.checked })}
                />
            )}
        </>
    );
};
