import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getTasksStatuses,
    tasks_statuses as tasks_statuses_state,
    tasks_statuses_fetching as tasks_statuses_fetching_state,
} from '../../../redux/slices/hospitalitySlice';
import { MultiSelect } from '../../common/widgets/MultiSelect';

export const StatusesFilter = ({ setFilter, value }) => {
    const dispatch = useDispatch();

    const tasks_statuses = useSelector(tasks_statuses_state);
    const tasks_statuses_fetching = useSelector(tasks_statuses_fetching_state);

    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        if (!tasks_statuses.length) {
            dispatch(getTasksStatuses());
        }
    }, [dispatch]);

    useEffect(() => {
        if (tasks_statuses && tasks_statuses.length) {
            setStatuses(
                tasks_statuses.map(status => ({
                    value: status.id,
                    label: status.name,
                }))
            );
        }
    }, [tasks_statuses]);

    return (
        <MultiSelect
            options={statuses}
            placeholder="Статус заявки"
            isLoading={tasks_statuses_fetching}
            isDisabled={tasks_statuses_fetching}
            callback={val => setFilter({ type: 'statuses', val })}
            value={value}
        />
    );
};
