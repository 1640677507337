import { useSelector } from 'react-redux';

import { auto_mode as auto_mode_state } from '../../../redux/slices/hospitalitySlice';
import { ButtonRectangular } from '../../common/btn/ButtonRectangular';

export const CleanersPaneButton = ({ setCleanerPaneIsOpen }) => {
    const auto_mode = useSelector(auto_mode_state);

    return (
        <ButtonRectangular onClick={() => setCleanerPaneIsOpen(true)} disabled={auto_mode}>
            <svg width="20" height="20" viewBox="0 0 20 20" className="fill-current">
                <path d="M9.16675 14.1667H10.8334V10.8334H14.1667V9.16675H10.8334V5.83341H9.16675V9.16675H5.83341V10.8334H9.16675V14.1667ZM10.0001 18.3334C8.8473 18.3334 7.76397 18.1147 6.75008 17.6772C5.73619 17.2397 4.85425 16.6459 4.10425 15.8959C3.35425 15.1459 2.7605 14.264 2.323 13.2501C1.8855 12.2362 1.66675 11.1529 1.66675 10.0001C1.66675 8.8473 1.8855 7.76397 2.323 6.75008C2.7605 5.73619 3.35425 4.85425 4.10425 4.10425C4.85425 3.35425 5.73619 2.7605 6.75008 2.323C7.76397 1.8855 8.8473 1.66675 10.0001 1.66675C11.1529 1.66675 12.2362 1.8855 13.2501 2.323C14.264 2.7605 15.1459 3.35425 15.8959 4.10425C16.6459 4.85425 17.2397 5.73619 17.6772 6.75008C18.1147 7.76397 18.3334 8.8473 18.3334 10.0001C18.3334 11.1529 18.1147 12.2362 17.6772 13.2501C17.2397 14.264 16.6459 15.1459 15.8959 15.8959C15.1459 16.6459 14.264 17.2397 13.2501 17.6772C12.2362 18.1147 11.1529 18.3334 10.0001 18.3334ZM10.0001 16.6667C11.8612 16.6667 13.4376 16.0209 14.7292 14.7292C16.0209 13.4376 16.6667 11.8612 16.6667 10.0001C16.6667 8.13897 16.0209 6.56258 14.7292 5.27091C13.4376 3.97925 11.8612 3.33341 10.0001 3.33341C8.13897 3.33341 6.56258 3.97925 5.27091 5.27091C3.97925 6.56258 3.33341 8.13897 3.33341 10.0001C3.33341 11.8612 3.97925 13.4376 5.27091 14.7292C6.56258 16.0209 8.13897 16.6667 10.0001 16.6667Z" />
            </svg>
            Добавить сотрудников
        </ButtonRectangular>
    );
};
