import { PopupFixed } from '../../common/popup/PopupFixed';
import { Task } from '../task/Task';

export const InformationPopup = ({ isOpen, setIsOpen, buttonRef }) => {
    return (
        <PopupFixed isOpen={isOpen} setIsOpen={setIsOpen} buttonRef={buttonRef}>
            <div className="mb-4 text-black font-medium">Виды уборок</div>
            <div className="flex flex-col gap-4 text-black text-sm mb-8">
                <div className="flex flex-row items-center gap-6">
                    <Task type={1} status={2}>
                        А1283
                    </Task>
                    <div>по выезду (краткосрок)</div>
                </div>
                <div className="flex flex-row items-center gap-6">
                    <Task type={2} status={3}>
                        А1283
                    </Task>
                    <div>по выезду (долгосрок)</div>
                </div>
                <div className="flex flex-row items-center gap-6">
                    <Task type={3} status={8}>
                        А1283
                    </Task>
                    <div>обеспыливание</div>
                </div>
                <div className="flex flex-row items-center gap-6">
                    <Task type={4} status={2}>
                        А1283
                    </Task>
                    <div>поддерживающая</div>
                </div>
                <div className="flex flex-row items-center gap-6">
                    <Task type={4} status={3} repeated={true}>
                        А1283
                    </Task>
                    <div>повторно распределенная задача</div>
                </div>
                <div className="flex flex-row items-center gap-6">
                    <Task type={null} status={2}>
                        А1283
                    </Task>
                    <div>типовая задача</div>
                </div>
            </div>

            <div className="mb-4 text-black font-medium">Статусы заявок</div>
            <div className="flex flex-col gap-4 text-black">
                <div className="flex flex-row items-center gap-6">
                    <div className="w-2.5 h-2.5 rounded-full bg-status-new"></div>
                    <div>Новая</div>
                </div>
                <div className="flex flex-row items-center gap-6">
                    <div className="w-2.5 h-2.5 rounded-full bg-status-at-work"></div>
                    <div>В работе</div>
                </div>
                <div className="flex flex-row items-center gap-6">
                    <div className="w-2.5 h-2.5 rounded-full bg-status-completed"></div>
                    <div>Выполнена</div>
                </div>
            </div>
        </PopupFixed>
    );
};
