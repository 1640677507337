import { root } from '../config';

export const hospitalityAPI = Object.freeze({
    //возвращает список объектов
    getHospitalityObjects: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/hotels/get-objects`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //возвращает список статусов задач
    getTasksStatuses: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/hotels/get-task-statuses`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //возвращает список типов уборок
    getClearTypes: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/hotels/get-clear-types`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //возвращает список сотрудников объекта
    getWorkers: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                if (data[item]) {
                    formData.append(item, data[item]);
                }
            }

            return fetch(`${root}?r=api/hotels/get-today-workers`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //назначает пользователей работниками на сегодня на объекте
    setWorkersAtWork: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/set-today-worker`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //назначает супервайзеров работниками на сегодня на объекте
    setSupervisorsAtWork: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/set-today-supervisor`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //возвращает список задач
    getTasks: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                if (data[item]) {
                    formData.append(item, data[item]);
                }
            }

            return fetch(`${root}?r=api/hotels/get-tasks`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //применяет перенос распределение задач на сегодня
    actApplyAll: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-apply-all`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //применяет перенос распределение клинёров на сегодня
    actApplySupervisorsAll: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-apply-all-supervisors`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //сброс распределения задач на сегодня
    actResetAll: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-reset-all`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //сброс распределения задач на сегодня для пользователя
    actResetForUser: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-reset-for-user`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //сброс распределения клинёров на сегодня для супервайзера
    actResetForSupervisor: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-reset-all-supervisors`, {
                method: 'POST',
                body: formData,
            });
        },
    },
    //автоматическое распределение задач на сегодня
    actDoAuto: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-do-auto`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // автоматическое распределение клинёров на сегодня
    actDoAutoWorkers: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-do-auto-supervisors`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //перемещение задачи внутри шахматки
    moveTask: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/move-task`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //перемещение клинёра внутри шахматки
    moveCleaners: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }
            return fetch(`${root}?r=api/hotels/move-workers`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //возвращает список настроек для объекта
    getSettings: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                if (data[item]) {
                    formData.append(item, data[item]);
                }
            }

            return fetch(`${root}?r=api/hotels/get-settings`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    //обновляет список настроек для объекта
    setSettings: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                if (data[item]) {
                    formData.append(item, data[item]);
                }
            }

            return fetch(`${root}?r=api/hotels/set-settings`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    getSupervisors: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/get-today-supervisors`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    getRooms: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/get-rooms`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    getTypicalTasksList: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/get-typical-task-types`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    createTask: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/create-task`, {
                method: 'POST',
                body: formData,
            });
        },
    },
});
