import { useSelector } from 'react-redux';

import { objects_fetching as objects_fetching_state, object as object_state, objects as objects_state } from '../../../redux/slices/hospitalitySlice';

import { SubHeader } from '../../common/subheader/SubHeader';
import { SubHeaderLeft } from '../../common/subheader/SubHeaderLeft';
import { SubHeaderRight } from '../../common/subheader/SubHeaderRight';
import { Title } from '../../common/subheader/Title';
import { Loader } from '../../common/Loader';
import { ApplyTasksButton } from './ApplyTasksButton';
import { ResetAllTasksButton } from './ResetAllTasksButton';
import { CreateTaskPaneButton } from './CreateTaskPaneButton';

export const Header = ({ setTaskPaneIsOpen }) => {
    const objects_fetching = useSelector(objects_fetching_state);
    const object = useSelector(object_state);

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    {objects_fetching ? (
                        <Loader height={20} width={20} />
                    ) : (
                        <div className="flex flex-row gap-1 items-center">
                            <Title>{(object && object.title) || 'Объект не выбран'}</Title>
                        </div>
                    )}
                </SubHeaderLeft>
                <SubHeaderRight>
                    <ResetAllTasksButton />
                    <ApplyTasksButton />
                    <CreateTaskPaneButton setTaskPaneIsOpen={setTaskPaneIsOpen} />
                </SubHeaderRight>
            </SubHeader>
        </>
    );
};
