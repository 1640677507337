import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { t } from 'i18next';
import { useCookies } from 'react-cookie';

import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import {
    filter as filter_state,
    set_filter,
    filter_fetch as filter_fetch_state,
    usersQualificationsFetch,
    departmantsFetch,
    venuesFetch,
    set_filter_qualifications,
} from '../../redux/slices/qualificationSlice';

export const Filter = () => {
    const skipFirstRender = useRef(true);
    const [searchLine, setSearchLine] = useState(null);

    const dispatch = useDispatch();

    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const filter_fetch = useSelector(filter_fetch_state);
    const filter = useSelector(filter_state);

    const [cookie] = useCookies();
    const lang = cookie.i18next;

    //search line
    useEffect(() => {
        if (searchLine === null) {
            return;
        }
        const timeOutId = setTimeout(() => {
            dispatch(set_filter({ type: 'filter_user_name', value: searchLine }));
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [dispatch, searchLine]);

    useEffect(() => {
        if (filter_fetch.venues.length === 0 && filter_fetch.departments.length === 0) {
            dispatch(venuesFetch({ username, auth_key_calendar }));
            dispatch(departmantsFetch(lang));
        }
    }, [dispatch, lang, username, auth_key_calendar, filter_fetch]);

    useEffect(() => {
        if (skipFirstRender.current) {
            skipFirstRender.current = false;
            return;
        }
        dispatch(departmantsFetch(lang));
    }, [dispatch, lang]);

    useEffect(() => {
        if (Object.keys(filter).length === 1) {
            return;
        }
        if (filter.venues.length === 0) {
            return;
        }
        dispatch(usersQualificationsFetch({ username, auth_key_calendar, filter }));
    }, [dispatch, username, auth_key_calendar, filter]);

    const venues_options = filter_fetch.venues;
    const departments_options = filter_fetch.departments;
    const qualification_options = filter_fetch.qualifications;
    const qualifications_status_options = [
        { value: 0, label: t('QUALIFICATION_OVERDUE') },
        { value: 1, label: t('QUALIFICATION_0_TO_1') },
        { value: 2, label: t('QUALIFICATION_1_TO_2') },
        { value: 3, label: t('QUALIFICATION_MORE_2') },
    ];

    const Option = props => {
        return (
            <div>
                <components.Option {...props}>
                    <div className="flex items-center">
                        <input type="checkbox" checked={props.isSelected} onChange={() => null} />
                        <label className="ml-2">{props.label}</label>
                    </div>
                </components.Option>
            </div>
        );
    };

    return (
        <div className="qualifiactions_filter flex flex-wrap gap-2 w-full bg-white rounded-lg border border-gray-10 p-2 mb-6">
            {/* employees search */}
            <div className="h-10 w-52 relative">
                <input
                    type="text"
                    defaultValue={filter.filter_user_name}
                    placeholder={t('EMPLOYEES_SEARCH')}
                    onChange={e => setSearchLine(e.target.value)}
                    className="box-border w-full h-full text-sm rounded-1 bg-gray-100 border-gray-10 py-0 pl-2 pr-9"
                />

                <svg className="w-[20px] h-[20px] absolute right-[10px] top-[9px]" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.33333 14.1667C11.555 14.1667 14.1667 11.555 14.1667 8.33333C14.1667 5.11167 11.555 2.5 8.33333 2.5C5.11167 2.5 2.5 5.11167 2.5 8.33333C2.5 11.555 5.11167 14.1667 8.33333 14.1667Z"
                        stroke="#B3B3BC"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M12.5 12.5L17.5 17.5" stroke="#B3B3BC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>

            {/* object search */}
            <Select
                onChange={value => dispatch(set_filter({ type: 'venues', value }))}
                options={venues_options}
                defaultValue={filter.venues}
                isSearchable={true}
                placeholder={t('CALENDAR_OBJECT')}
                classNamePrefix="filter"
                className="select text-sm w-40"
                components={{
                    Option,
                }}
                hideSelectedOptions={false}
            />

            {/* department search */}
            <Select
                onChange={value => dispatch(set_filter({ type: 'departments', value }))}
                options={departments_options}
                isMulti
                defaultValue={filter.departments}
                isClearable={true}
                isSearchable={false}
                placeholder={t('ADM_PLANNER_TASK_DEPARTMENT')}
                classNamePrefix="filter"
                className="select text-sm w-48 depart-select"
                components={{
                    Option,
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
            />

            {/* qualification status search */}
            <Select
                onChange={value => dispatch(set_filter({ type: 'status', value }))}
                options={qualifications_status_options}
                isMulti
                defaultValue={filter.status}
                isClearable={true}
                isSearchable={false}
                placeholder={t('QUALIFICATION_STATUS')}
                classNamePrefix="filter"
                className="select text-sm w-60 status-select"
                components={{
                    Option,
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
            />

            {/* qualification name search */}
            <Select
                onChange={value => dispatch(set_filter_qualifications(value))}
                options={qualification_options}
                defaultValue={filter.qualifications}
                isMulti
                isClearable={true}
                isSearchable={false}
                placeholder={t('QUALIFICATION_NAME')}
                classNamePrefix="filter"
                className="select text-sm w-72 qual-name-select uppercase"
                components={{
                    Option,
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
            />
        </div>
    );
};
