export const BoxDragPreview = ({ item, difference }) => {
    return (
        <div
            className="absolute cursor-move border border-dashed border-amber-400"
            style={{
                left: item.left + difference.x + 3,
                top: item.top + difference.y + 3,
                rotate: `${item.degree}deg`,
                width: item.width,
                height: item.height,
            }}
        >
            {item.children}
        </div>
    );
};
