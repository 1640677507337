import { useRef, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { t } from 'i18next';

import { Sidebar } from './sidebar/Sidebar';
import { Header } from './Header';
import { ScrollToTop } from './ScrollToTop';

export const MainLayout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [path, setPath] = useState(null);
    const sidebarTrigger = useRef(null);
    const [cookie] = useCookies();

    const lang = cookie.i18next;
    useEffect(() => {
        document.title = t('TITLE');
    }, [lang]);

    const getPath = dataPath => {
        setPath(dataPath);
    };

    return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} sidebarTrigger={sidebarTrigger} dataPath={path} />

            <div className="relative w-full flex flex-col overflow-y-scroll overflow-x-hidden" id="wrapper">
                <Header setSidebarOpen={setSidebarOpen} sidebarTrigger={sidebarTrigger} />
                <Outlet />
                <ScrollToTop />
            </div>

            <ToastContainer />
        </div>
    );
};
